import React from 'react'
import {Grid} from '@mui/material'
import {PTOPendingApplication} from './components/PTOPendingApplication'
import {TimecardChangePendingApplication} from './components/TimecardChangePendingApplication'
import {usePendingApplicationLayout} from './hooks/usePendingApplicationLayout'
import {BaseLayout} from '../index'


const PendingApplicationLayout: React.FC = () => {
	const {
		handleTimecardChangeDrop,
		handleTimecardChangeSubmit,
		timecardChanges,
		handlePTORequestDrop,
		handlePTORequestSubmit,
		ptoRequests,
		handleExport
	} = usePendingApplicationLayout()

  return (
    <BaseLayout>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<TimecardChangePendingApplication
						state={timecardChanges}
						handleDrop={handleTimecardChangeDrop}
						handleSubmit={handleTimecardChangeSubmit}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<PTOPendingApplication
						state={ptoRequests}
						handleDrop={handlePTORequestDrop}
						handleSubmit={handlePTORequestSubmit}
						handleExport={handleExport}
					/>
				</Grid>
			</Grid>
		</BaseLayout>
  );
}

export default PendingApplicationLayout