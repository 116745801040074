import {CompanyGroup, CorporateTitleT, DepartmentT} from './company'
import {NestedError} from './errors'
import {CreatePTO, PTORequestDataT} from './pto'

export type MinimalUserDetail = {
  id: number
  full_name: string
}

export type UserDetail = MinimalUserDetail & {
	company: number
	corporate_title: number | null
	corporate_title_name: string
	department_name: string
	departments: number | null
	email: string
	employee_no: string
	first_name: string
	group: number | null
	hire_dt: string
	is_admin: boolean
	last_name: string
	transfer_days: number
}

export type UserTablePaginationType = {
	count: number
	results: UserDetail[]
}

export type UserSettingsState = {
	departments: DepartmentT[]
	corporateTitles: CorporateTitleT[]
	groups: CompanyGroup[]
}

export type UserModalOpenType = {
	open: boolean
	user?: UserDetail
	errors?: NestedError<UserDetail>
}

export type UserPTOCalendarT = {
	start: string
	end: string
}

export type UserPTOCalendarModalOpenType = {
	open: boolean
	ptoCalendars: UserPTOCalendarT[]
}

export type NewPTOCalendarModalOpenType = {
	open: boolean
	data: CreatePTO | null
	error: NestedError<CreatePTO> | null
}


export type DetailedUserPTOCalendarT = UserPTOCalendarT & {
	prev_total: number
	current_total: number
	used_2_hours: number
	used_days_hours: number
	pto_requests: PTORequestDataT[]
	remaining_used_2_hours: number
	total_usable_pto: number
	used_2_hours_display: number
	used_days_hours_display: number
	real_remaining_pto_hours: number
	remaining_pto_days: number
	remaining_pto_hours: number
}

export type UserPTOCalendarEditModalType = {
	open: boolean
	data: DetailedUserPTOCalendarT | null
}

export const userCSVHeaders =[
	{label:'氏名', key:'full_name'},
	{label:'メールアドレス', key:'email'},
	{label:'従業員番号', key:'employee_no'},
	{label:'役職', key:'corporate_title_name'},
	{label:'部署', key:'department_name'},
]

export type UserSearchType = {
	search: string;
	department: string | number;
	corporateTitle: string | number;
}

export const defaultUserSearch:UserSearchType = {
	search: "",
	department: "",
	corporateTitle: ""
}