import React from 'react'
import {UserPTOCalendarT} from '../../../store/users'
import {ModalLayout} from '../../../components'
import {dateStringToJpString} from '../../../utils/date'
import {Button, Stack} from '@mui/material'

interface UserPTOCalendarProps {
  ptoCalendars: UserPTOCalendarT[]
  handlePTOModalClose: () => void
  handlePTOCalendarDownload: (start: string) => void
  handlePTOCalendarEdit: (start: string) => void
  handleNewPTOCalendarModalOpen: () => void
}

export const UserPTOCalendar: React.FC<UserPTOCalendarProps> = (props) => {
  const {ptoCalendars, handlePTOModalClose, handlePTOCalendarDownload, handlePTOCalendarEdit, handleNewPTOCalendarModalOpen} = props
  return (
    <ModalLayout title="有給休暇管理">
      {ptoCalendars.map((ptoCalendar, index) => (
        <div key={index}>
          <div><b>対象日:</b> {dateStringToJpString(ptoCalendar.start)} - {dateStringToJpString(ptoCalendar.end)}</div>
          <Stack
            direction={{xs: 'row', ms: 'column'}} spacing={3}
          >
            <Button size="small" type="button" variant="outlined"
                    onClick={() => handlePTOCalendarDownload(ptoCalendar.start)}>Excelダウンロード</Button>
            <Button size="small" type="button" variant="contained" style={{color: "white"}}
                    onClick={() => handlePTOCalendarEdit(ptoCalendar.start)}>編集</Button>
          </Stack>
        </div>
      ))}
      <Button
        type="button"
        variant="contained"
        onClick={handleNewPTOCalendarModalOpen}
      >
        追加
      </Button>
      <Stack
        direction={{xs: 'row', ms: 'column'}} spacing={3}
      >
        <Button type="button" variant="contained" color="inherit" sx={{flex: 1}}
                onClick={handlePTOModalClose}>戻る</Button>
      </Stack>
    </ModalLayout>
  )
}
