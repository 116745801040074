import {useContext} from 'react'
import {AuthContext} from '../contexts/authContext'
import axios from '../api/axios'
import realAxios from 'axios'
import {DELETE_AUTH_STATE, SET_AUTH_STATE, SET_AUTH_URL} from '../store/constants'

export const useAuth = () => {
  if (!AuthContext) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  const {auth, dispatch} = useContext(AuthContext)

  const login = (url: string) => {
    window.location.assign(url)
  }

  const logout = () => {
    axios.post("/auth/logout/", {}, {
      withCredentials: true,
      headers: {
        authorization: `Bearer ${auth?.access}`
      },
    }).then((res)=>{
      dispatch({type: DELETE_AUTH_STATE, payload: res.data?.url})
    })
  }

  const refresh = async () => {
    try {
      const res = await axios.post('/auth/token/refresh/', {},
        {
          withCredentials: true,
          headers: {'Content-Type': 'application/json'},
        })
      dispatch({type: SET_AUTH_STATE, payload: res.data})
      return res.data.access
    } catch (error) {
      if (realAxios.isAxiosError(error)) {
        dispatch({type: DELETE_AUTH_STATE, payload: error.response?.data?.url || ''})
      }
      return false
    }
  }

  return {auth, login, logout, refresh}
};
