import {AlertMessageContext} from '../contexts/alertMessageContext'
import {useCallback, useContext} from 'react'
import {AlertMessageState} from '../store/alertMessage'
import {SET_ALERT_MESSAGE} from '../store/constants'

export const useAlertMessage = () => {
  if(!AlertMessageContext) {
    throw new Error('useAlertMessage must be used within a AlertMessageProvider')
  }
  const {dispatch, state} = useContext(AlertMessageContext)

  const setAlertMessage = useCallback((data: AlertMessageState) => {
    dispatch({type: SET_ALERT_MESSAGE, payload: data})
  }, [dispatch])

  return {setAlertMessage, alertMessage: state}
}