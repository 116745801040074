import {MonthlyDataT} from '../../store/monthlyData'
import React from 'react'
import {useAuth} from '../../hooks/useAuth'
import {useParams} from 'react-router-dom'
import {
  Box, Button,
  FormControl,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow,
  Typography,
} from '@mui/material'
import CommentIcon from '@mui/icons-material/Comment'
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone'
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {styled} from '@mui/material/styles'
import {OpenDataNewT, OpenDataT} from './useMonthlyWorkTime'
import {csvButtonStyle} from '../../store/store'
import {TimecardMemoT} from '../../store/timecard'

const StyledTableRow = styled(TableRow)(() => ({
  '& td, & th': {
    borderRight: "1px solid rgba(224, 224, 224, 1)",
	  fontSize: 16,
	  textAlign: "center",
  },
	'& td:last-child, & th:last-child': {
    border: "none"
  },
	'& th': {
		fontWeight: "bold",
	}
}));

interface DaysTableProps{
	data: MonthlyDataT;
	onClick: (data: OpenDataT, memo: TimecardMemoT) => void;
	state: {is_admin: boolean, user_id: string | null, sub_admin: boolean };
	handleCompleteClick: () => void;
	csvDownload: () => void;
}

export const DaysTable: React.FC<DaysTableProps> = (props) => {
	const { auth } = useAuth();
	const { data, onClick, state, handleCompleteClick, csvDownload } = props;
	const {month} = useParams()


	return(
		<>
			{
				auth.user.is_admin &&
				<Box mb={2} sx={{display: "flex", justifyContent: "space-between", alignItems: {xs:"center", sm: "center"}}}>
					<Typography component="div"><Typography fontSize={20} sx={{pl:2, pt:2}} variant="h4">[{month}月分] {data.full_name}</Typography></Typography>
					<Typography component="div" mr={2}>
						<button style={csvButtonStyle} onClick={csvDownload}>勤怠データの出力</button>
					</Typography>
				</Box>
			}
		<TableContainer component={Paper} sx={{width: "100%"}}>
			<Table>
				<TableHead>
					<StyledTableRow>
						<TableCell></TableCell>
						<TableCell>出勤</TableCell>
						<TableCell>退勤</TableCell>
						{ (state.is_admin || state.sub_admin) && <TableCell sx={{display: {xs: "none", sm:"table-cell"}}}>実績時間</TableCell> }
						{ (state.is_admin || state.sub_admin) && <TableCell sx={{display: {xs: "none", sm:"table-cell"}}}>時間外/遅刻早退</TableCell> }
						<TableCell sx={{display: {xs: "none", sm:"table-cell"}}}>有給</TableCell>
						<TableCell />
					</StyledTableRow>
				</TableHead>
				<TableBody>
					{
						data?.days?.map((obj, index:number) => {
							let timeCard = data?.workdays.find((val) => {
								const d = val.date.split("-");
								return parseInt(d[0]) === obj.year && parseInt(d[1]) === obj.month && parseInt(d[2]) === obj.day
							})
							let pto = data?.pto.filter((val) => val.status === 'completed' && val.date_list.findIndex((v) => v.year === obj.year && v.day === obj.day && v.month === obj.month) > -1);
							let newData:OpenDataNewT | null = null;
							if ( !timeCard ){
								newData = {
									date: `${obj.year}-${obj.month < 10 ? "0" : ""}${obj.month}-${obj.day < 10 ? "0" : ""}${obj.day}`,
									start: '',
									end: '',
									reasons: {start: '', end: ''},
								}
							}
							let memo = data.memos.find((val) => {
								const d = val.date.split("-");
								return parseInt(d[0]) === obj.year && parseInt(d[1]) === obj.month && parseInt(d[2]) === obj.day
							})
							if (!memo) {
								memo = {
									date: `${obj.year}-${obj.month < 10 ? "0" : ""}${obj.month}-${obj.day < 10 ? "0" : ""}${obj.day}`,
									memo: ''
								}
							}
							return (
							<StyledTableRow key={index} onClick={() => {onClick( timeCard ? {...timeCard, pto:pto} as OpenDataT : {...newData, pto:pto} as OpenDataT, memo as TimecardMemoT)}}>
								<TableCell sx={obj.holiday ? {color: "red"} : {}}>{obj.month}<Typography component="small" sx={{fontSize: 10}}>月</Typography>{obj.day}<Typography component="small" sx={{fontSize: 10}}>{obj.weekday}</Typography></TableCell>
								{
									timeCard && (timeCard.status === "new" || data.pending_list.includes(timeCard.id)) ?
										<TableCell colSpan={auth.user.sub_admin && auth.user.is_admin ? 4 : 2}>申請中</TableCell>　:
									<>
										<TableCell>{timeCard && timeCard.start}</TableCell>
										<TableCell>{timeCard && timeCard.end}</TableCell>
										{ (state.is_admin || state.sub_admin) && <TableCell sx={{display: {xs: "none", sm: "table-cell"}}}>{timeCard && timeCard.total}</TableCell> }
										{ (state.is_admin || state.sub_admin) && <TableCell sx={{display: {xs: "none", sm: "table-cell"}}} style={{color: timeCard && timeCard.diff[0] === '-' ? "#7ccef4" : "red"}}>{timeCard && timeCard.diff}</TableCell> }
									</>
								}
								<TableCell sx={{display: {xs: "none", sm: "table-cell"}}}>{pto?.map((val, j) => (
									<div key={j}>{val.vacation_type_display}</div>
								))}</TableCell>
								<TableCell sx={{p:0}}>
									<Stack>
										{memo && memo.memo && <CommentIcon />}
										{timeCard && timeCard.device === "mobile" && state.user_id && state.is_admin && <PhoneIphoneIcon />}
										{timeCard && timeCard.device === "pc" && state.user_id && state.is_admin && <LaptopChromebookIcon />}
									</Stack>
								</TableCell>
							</StyledTableRow>
						)})
					}
				</TableBody>
			</Table>
		</TableContainer>
			{
				state.user_id === null && data.status === "recruitment" && data.pending_list.length === 0 &&
				<Box sx={{
					textAlign: {xs: "center", md: "left"},
					display: "flex",
					mt:2,
					bgcolor: "secondary.light",
					justifyContent: {xs:"center", md:"space-between"},
					flexWrap: {xs:"wrap", md:"nowrap"},
					flexFlow: {xs:"column", md:"column"},
					alignItems: "center",
					p: 2,
					borderRadius: 1,
					mb: {xs: 2, md: 2}
					}}>
					<Typography sx={{
								mb:2
							}}>
						上記の内容で申請しますか？
					</Typography>
					<FormControl component="form" method="post">
					</FormControl>
					<Button
						type="button"
						size="large"
						variant="contained"
						color="primary"
						sx={{
							color: "primary.light",
						}}
						onClick={handleCompleteClick}
						endIcon={<KeyboardArrowRightIcon />}>
							<Typography color="primary.light">
								確定する
							</Typography>
				</Button>
				</Box>
			}
		</>
	);
};