import React from "react";
import {MonthlyWorkTime} from "../components";
import {BaseLayout} from '../layouts'

const DailyAttendanceView: React.FC = () => {
	return (
		<BaseLayout>
			<MonthlyWorkTime />
		</BaseLayout>
	);
};

export default DailyAttendanceView;