import React from 'react'
import {TimecardChangeApplicationT, TimecardChangeReason} from '../store/timecard'

export const useTimecard = <T extends TimecardChangeApplicationT | TimecardChangeReason>() => {
  const handleTimecardChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<T>>
  ) => {
		const {name, value} = event.target
		const dataName = event.target.getAttribute("data-name")
		if (dataName){
			setState((prevState)=>({
				...prevState, [dataName]: {...prevState[dataName as keyof TimecardChangeReason], [name]:value}
			}));
		}else {
			setState((prevState)=>({...prevState, [name]: value}));
		}
	}

  return {
    handleTimecardChange
  }
}