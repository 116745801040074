import React from 'react'
import {Box, Divider, Paper, Typography} from '@mui/material'

interface PendingBoxProps {
	icon: React.ReactNode,
	children: React.ReactNode,
	title: string,
	count: number
}

export const PendingBox: React.FC<PendingBoxProps> = ({icon, children, title, count}) => {
	return(
		<Paper elevation={2} sx={{p:2, height: 500, overflow: "scroll"}}>
			<Box textAlign="center" mb={2}>
				{icon}
				<Typography variant="h5">
					{title}

				</Typography>
			</Box>
			<Typography variant="h5">未承認申請<small>（{count}件）</small></Typography>
			<Divider sx={{borderWidth: 1}}/>
			{children}
		</Paper>
	);
};