import React from "react";
import {Button, Stack} from "@mui/material";
import {ReadSentRestApplication} from './ReadSentRestApplication'
import {useSentRestApplication} from './useSentRestApplication'
import {EditSentRestApplication} from './EditSentRestApplication'
import {ModalLayout} from '../../index'

interface SentRestApplicationT {
	id: number;
	handleClose: () => void;
}


export const SentRestApplication: React.FC<SentRestApplicationT> = ({handleClose, id}) => {
	const {
		state,
		errors,
		handleSubmit,
		handleDrop,
		handleChange
	} = useSentRestApplication(id, handleClose);

	return (
		<ModalLayout title="休暇申請履歴">
			{
				state.status !== "send_back"
					?
					<ReadSentRestApplication state={state} />
					:
					<EditSentRestApplication handleChange={handleChange} errors={errors} state={state}/>
			}
			<Stack
					direction={{xs:"row", ms:"column"}} spacing={3}
				>
					<Button type="button" variant="contained" color="inherit" sx={{flex:1}} onClick={handleClose}>戻る</Button>
					{
						state.status !== 'completed'
						&&
						<Button type="button" onClick={handleDrop} variant="outlined" color="error" sx={{flex:1}}>申請取下げ</Button>
					}
					{
						state.status === 'send_back'
						&&
						<Button type="button" onClick={handleSubmit} variant="outlined" sx={{flex:1}}>再申請</Button>
					}
				</Stack>
		</ModalLayout>
	);
};