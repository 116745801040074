import {SET_ACTOR_NOTIFICATIONS, SET_ERROR_NOTIFICATIONS, SET_RECIPIENT_NOTIFICATIONS} from './constants'

type NotificationFields = {
  id: number
  type: string
  created_dt?: string
  date: string
  sender: string
  action_type: string
  id_data: string
  status_display: string
  status: string
}



type NotificationMonthlyData = {
  status_display: string
  notification_text: string
  sender: string
  month: number
  year: number
  user: number
  action_type: string
  type: string
  status: string
}

export type Notification = {
  actorNotifications: RequestNotification[]
  recipientNotifications: RequestNotification[]
  errorNotifications: ErrorNotification[]
}

export type RequestNotification = {
  action_object: NotificationFields | NotificationMonthlyData
  timestamp: string
}

export type ErrorNotification = {
  verb: string
  description: string
}

export type NotificationActionType =
  | { type: typeof SET_ACTOR_NOTIFICATIONS; payload: RequestNotification[] }
  | { type: typeof SET_RECIPIENT_NOTIFICATIONS; payload: RequestNotification[] }
  | { type: typeof SET_ERROR_NOTIFICATIONS; payload: ErrorNotification[] }

export const notificationReducer = (state: Notification, action: NotificationActionType): Notification => {
  switch (action.type) {
    case SET_ACTOR_NOTIFICATIONS:
      return { ...state, actorNotifications: action.payload }
    case SET_RECIPIENT_NOTIFICATIONS:
      return { ...state, recipientNotifications: action.payload }
    case SET_ERROR_NOTIFICATIONS:
      return { ...state, errorNotifications: action.payload }
    default:
      return state
  }
}

export function isNotificationMonthlyData(obj: any): obj is NotificationMonthlyData {
  return 'action_type' in obj && obj.action_type === 'monthly_data';
}
