import React from "react";
import { Stack, Button, FormControl} from "@mui/material";
import {useAuth} from "../../hooks/useAuth";
import {ModalTable, ModalLayout} from '../index'


interface ProfileProps {
	handleClose: () => void;
}

const Profile: React.FC<ProfileProps> = ({handleClose}) => {
	const { auth, logout } = useAuth();
	return (
		<ModalLayout title="アカウント情報">
			<FormControl fullWidth component="form" method="post" id="profile-form">
					<ModalTable>
						<tr>
							<th>従業員番号</th>
							<td>{auth.user.employee_no}</td>
						</tr>
						<tr>
							<th>氏名</th>
							<td>
								{auth.user.full_name}
							</td>
						</tr>
						<tr>
							<th>入社日</th>
							<td>{auth.user.hire_dt}</td>
						</tr>
					</ModalTable>
				</FormControl>
				<Stack
					direction={{xs:"column"}} spacing={3}
				>
					<Button type="button" variant="contained" color="inherit" sx={{flex:1}} onClick={logout}>ログアウト</Button>
					<Button type="button" variant="contained" color="secondary" sx={{flex:1}} onClick={handleClose}>戻る</Button>
				</Stack>
		</ModalLayout>
	);
};

export default Profile;