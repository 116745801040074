import React, {useEffect, useState} from 'react'
import {defaultPTORequestDetail, PTORequestDetail} from '../../../store/pto'
import useAuthAxios from '../../../hooks/useAuthAxios'

export const useReceivedRestApplication = (id: number, handleClose: () => void) => {
  const [state, setState] = useState<PTORequestDetail>(defaultPTORequestDetail);
	const [errors, setErrors] = useState<any>({});
	const axios = useAuthAxios();

	useEffect(()=>{
		axios.get(`/pto/${id}/`)
			.then((res) => { setState(res.data)})
		  .catch((err) => console.log(err))
	},[id, axios]);

	const handleSubmit = () => {
		axios.patch(`/pto/${id}/`, {status: "completed"})
			.then(() => {
				handleClose();
			})
			.catch((res)=>{
				setErrors(res.response.data);
			})
	}
	const handleDrop  = () => {
		axios.patch(`/pto/${id}/`, {status: "send_back", comment: state.comment})
			.then(() => {
				handleClose();
			})
			.catch((res)=>{
				setErrors(res.response.data);
			})
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const {name, value} = e.target;
		setState((prevState) => ({...prevState, [name]: value}));
	}

  return {
    state,
    errors,
    handleSubmit,
    handleDrop,
    handleChange
  }
}