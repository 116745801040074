import {DELETE_AUTH_STATE, SET_AUTH_STATE, SET_AUTH_URL} from './constants'

interface DateDictT{
  year: number;
  month: number;
}

export type AuthUser = {
  id: number;
  company: number;
  full_name: string;
  department: string;
  corporate_title: string;
  department_id?: number | null;
  date_dict: DateDictT;
  employee_no: number;
  hire_dt: string;
  remaining_pto_hours: number;
  max_request_remaining: number;
  is_admin: boolean;
  sub_admin: boolean;
}

export type AuthState = {
  user: AuthUser;
  access: string | false;
  url?: string;
}

export const defaultAuthState: AuthState = {
  user: {
    id: 0,
    company: 0,
    full_name: "",
    department: "",
    corporate_title: "",
    date_dict: { year:0, month:0 },
    employee_no: null!,
    hire_dt: "",
    remaining_pto_hours: 0,
    max_request_remaining: 0,
    is_admin: false,
    sub_admin: false
  },
  access: ""
}

export type AuthActionType =
  | { type: typeof SET_AUTH_STATE; payload: AuthState }
  | { type: typeof SET_AUTH_URL; payload: string }
  | { type: typeof DELETE_AUTH_STATE; payload: string}

export const authReducer = (state: AuthState, action: AuthActionType): AuthState => {
  switch (action.type) {
    case SET_AUTH_STATE:
      return action.payload
    case SET_AUTH_URL:
      return { ...state, url: action.payload }
    case DELETE_AUTH_STATE:
      return { ...state, access: false, url: action.payload}
    default:
      return state
  }
}
