import {DECREMENT_LOADING, INCREMENT_LOADING} from './constants'

export type LoadingActionType = { type: typeof INCREMENT_LOADING } | { type: typeof DECREMENT_LOADING }

export const loadingReducer = (state: number, action: LoadingActionType): number => {
  switch (action.type) {
    case INCREMENT_LOADING:
      return state + 1
    case DECREMENT_LOADING:
      return state - 1
    default:
      return state
  }
}

