import {Box} from '@mui/material'
import React from 'react'
import {AttendanceManagementTable} from './AttendanceManagementTable'

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
	dataID: number;
}


export const TabPanel:React.FC<TabPanelProps> = (props) => {
  const { dataID, children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`attendance-group-tab-panel-${index}`}
      aria-labelledby={`attendance-group-tab-${index}`}
      {...other}
    >
      {
				value === index && (
        <Box sx={{ px: {xs:0.5, sm: 2}, py:2 }}>
	        <AttendanceManagementTable id={dataID} />
        </Box>
      )}
    </div>
  );
}