import React from "react";
import {TextField, Typography} from "@mui/material";
import {CrudT, CRUTDataType, departmentLabels, DepartmentT} from '../../../store/company'
import {CompanySettingsTable} from './Table/CompanySettingsTable'
import {ModalBase, ModalLayout, ModalTable} from '../../../components'
import {DeleteForm} from './DeleteForm'
import {EditForm} from './EditForm'
interface DepartmentProps{
	states: DepartmentT[];
	handleSubmit: () => void;
	handleEdit: (id: number) => void;
	handleDelete: (id: number) => void;
	state?: DepartmentT;
	crud: CrudT<CRUTDataType>;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	errors?: {[key:string]: any};
	handleOpen: (data: DepartmentT, type: "edit" | "delete") => void;
	handleClose: () => void;
	handleCRUDChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Department: React.FC<DepartmentProps> = (props) => {
	const {
		states,
		handleSubmit,
		state,
		handleChange,
		errors,
		crud,
		handleEdit,
		handleDelete,
		handleOpen,
		handleClose,
		handleCRUDChange
	} = props;

	return(
		<CompanySettingsTable
			labels={departmentLabels}
			data={states}
			handleSubmit={handleSubmit}
			handleEdit={(data) => {handleOpen(data, "edit")}}
			handleDelete={(data) => {handleOpen(data, "delete")}}
		>
			<Typography>部署を追加：</Typography>
			<TextField
				size="small"
				placeholder="部署名を入力"
				name="name"
				value={state?.name}
				onChange={handleChange}
				error={errors?.name && true}
				helperText={errors?.name ?
          errors.name?.map((val: string) => val).join(', ') : ""}
				sx={{
						width: {xs: "100%", sm:"auto"}
					}}
			/>
			<ModalBase open={crud.open && crud.crudType === 'departments'} handleClose={handleClose}>
				<ModalLayout title={ crud.type === "delete" ? "削除" : "編集"}>
					{
						crud.type === "delete"
							?
							<DeleteForm id={crud?.data?.id || 0} handleDelete={handleDelete} handleClose={handleClose}>
								<ModalTable>
									<caption>部署の削除</caption>
									<tr>
										<th>部署名</th>
										<td>
											{crud.data?.name}
										</td>
									</tr>
								</ModalTable>
							</DeleteForm>
							:
							<EditForm handleEdit={handleEdit} handleClose={handleClose} id={crud?.data?.id || 0}>
								<ModalTable>
									<caption>部署の編集</caption>
									<tbody>
										<tr>
											<th>部署名</th>
											<td>
												<TextField
													size="small"
													name="name"
													value={crud.data?.name}
													onChange={handleCRUDChange}
													error={crud?.error?.name && true}
													helperText={
														crud?.error?.name.map((val:string) => (val)).join(', ')
													}
												/>
											</td>
										</tr>
									</tbody>
								</ModalTable>
							</EditForm>
					}
				</ModalLayout>
			</ModalBase>
		</CompanySettingsTable>
	);
};