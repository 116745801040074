import React from 'react'
import {MenuItem, TextField} from '@mui/material'
import {PTORequestDetail} from '../../../store/pto'
import {ModalTable} from '../../index'
import {optionVacationDisabled} from '../../../utils/pto'
import {useEditSentRestApplication} from './useEditSentRestApplication'

interface EditSentRestApplicationProps {
	handleChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  state: PTORequestDetail;
	errors: any;
}

export const EditSentRestApplication: React.FC<EditSentRestApplicationProps> = ({state, errors, handleChange}) => {
  const {
    vacations,
    users,
		user
  } = useEditSentRestApplication();

	return (
		<ModalTable>
			<caption>申請が差し戻されました。再提出してください。</caption>
			<tr>
				<th>コメント</th>
				<td>
					{state.comment}
				</td>
			</tr>
			<tr>
				<th>申請項目</th>
				<td>
					<TextField
						select
						size="small"
						name="vacation_type"
						value={state.vacation_type}
						onChange={handleChange}
						error={errors.vacation_type && true}
						helperText={errors?.vacation_type?.map((val:string) => val).join(", ")}
						sx={{
							width: {xs: "100%", sm:"auto"},
							'& .MuiSelect-select .notranslate::after': "ーーーーーーー"
		            ? {
		                content: `"ーーーーーーー"`,
		                opacity: 0.42,
		              }
		            : {},
						}}
					>
						<MenuItem value="" sx={{opacity:0.42}}>ーーーーーーー</MenuItem>
						{
							vacations.map((obj) => (
								<MenuItem key={obj.id} value={obj.id} disabled={optionVacationDisabled(obj, user)}>{obj.name}</MenuItem>
							))
						}
					</TextField>
				</td>
			</tr>
			<tr>
				<th>休暇希望日</th>
				<td>
					<TextField
							type="date"
							size="small"
							name="start"
							value={state.start}
							onChange={handleChange}
							error={errors.start && true}
							helperText={errors?.start?.map((val:string) => val).join(", ")}
						/>
					<TextField
							type="date"
							size="small"
							name="end"
							value={state.end}
							onChange={handleChange}
							error={errors.end && true}
							helperText={errors?.end?.map((val:string) => val).join(", ")}
						/>
				</td>
			</tr>
			<tr>
				<th>承認者</th>
				<td>
					<TextField
						select
						name="approver"
						size="small"
						value={state.approver}
						onChange={handleChange}
						error={errors.approver && true}
						helperText={errors?.approver?.map((val:string) => val).join(", ")}
						sx={{
							width: {xs: "100%", sm:"auto"},
							'& .MuiSelect-select .notranslate::after': "ーーーーーーー"
		            ? {
		                content: `"ーーーーーーー"`,
		                opacity: 0.42,
		              }
		            : {},
						}}
					>
						<MenuItem value="" sx={{opacity:0.42}}>ーーーーーーー</MenuItem>
						{
							users.map((obj) => (
								<MenuItem key={obj.id} value={obj.user}>{obj.full_name}</MenuItem>
							))
						}
					</TextField>
				</td>
			</tr>
			<tr>
				<th>申請備考</th>
				<td>
					<TextField
						fullWidth
						size="small"
            multiline
            rows="3"
						name="note"
						value={state.note}
						onChange={handleChange}
						error={errors.note && true}
						helperText={errors?.note?.map((val:string) => val).join(", ")}
					/>
				</td>
			</tr>
		</ModalTable>
	);
};