import React from 'react'
import {Box, Typography} from '@mui/material'

interface RenderLabelProps {
	children: React.ReactNode;
	label: string;
}

export const RenderLabel: React.FC<RenderLabelProps> = ({label, children}) => {
	return (
		<Box mb={2} mr={6.5}>
			<Typography gutterBottom variant="subtitle2" component="label" sx={{fontSize: {sm:"8px", md:"12px"}}}>{label}</Typography>
			<Typography variant="h4" component="div" sx={{fontSize: {xs: "1.1rem", sm:"1.2rem", md:"1.8rem", lg:"2.125rem"}}}>{children}</Typography>
		</Box>
	)
}