import React from "react";
import {TextField, Typography} from "@mui/material";
import {ModalBase, ModalLayout, ModalTable} from '../../../components'
import {CompanySettingsCollapseTable} from './Table/CompanySettingsCollapseTable'
import {DeleteForm} from './DeleteForm'
import {EditForm} from './EditForm'
import {CrudT, CRUTDataType, HolidayDataT, holidayLabels, HolidayT} from '../../../store/company'

interface HolidayProps{
	states: HolidayDataT[];
	handleSubmit: () => void;
	handleEdit: (id: number) => void;
	handleDelete: (id: number) => void;
	state?: HolidayT;
	crud: CrudT<CRUTDataType>;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	errors?: {[key:string]: any};
	handleOpen: (data: HolidayT, type: "edit" | "delete") => void;
	handleClose: () => void;
	handleCRUDChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Holiday: React.FC<HolidayProps> = (props) => {
	const {
		states,
		handleSubmit,
		state,
		handleChange,
		errors,
		crud,
		handleEdit,
		handleDelete,
		handleOpen,
		handleClose,
		handleCRUDChange
	} = props;
	return(
		<CompanySettingsCollapseTable
			<HolidayT, HolidayDataT>
			labels={holidayLabels}
			data={states}
			handleSubmit={handleSubmit}
			handleEdit={(data) => {handleOpen(data, "edit")}}
			handleDelete={(data) => {handleOpen(data, "delete")}}
		>
			<Typography>休校日を追加：</Typography>
			<TextField
				size="small"
				type="date"
				name="start"
				value={state?.start || ''}
				onChange={handleChange}
				error={errors?.start && true}
				helperText={errors?.start ?
          errors.start?.map((val: string) => val).join(', ') : ""}
				sx={{
						width: {xs: "100%", sm:"auto"}
					}}
			/>
			<TextField
				size="small"
				type="date"
				name="end"
				value={state?.end || ''}
				onChange={handleChange}
				error={errors?.end && true}
				helperText={errors?.end ?
          errors.end?.map((val: string) => val).join(', ') : ""}
				sx={{
						width: {xs: "100%", sm:"auto"}
					}}
			/>
			<ModalBase open={crud.open && crud.crudType === 'holidays'} handleClose={handleClose}>
				<ModalLayout title={ crud.type === "delete" ? "削除" : "編集"}>
					{
						crud.type === "delete"
							?
							<DeleteForm id={crud?.data?.id || 0} handleDelete={handleDelete} handleClose={handleClose}>
								<ModalTable>
									<caption>休暇日の削除</caption>
									<tr>
										<th>休暇日開始日</th>
										<td>
											{crud.data?.start}
										</td>
									</tr>
									<tr>
										<th>休暇日終了日</th>
										<td>
											{crud.data?.end}
										</td>
									</tr>
								</ModalTable>
							</DeleteForm>
							:
							<EditForm handleEdit={handleEdit} handleClose={handleClose} id={crud?.data?.id || 0}>
								<ModalTable>
									<caption>休校日の編集</caption>
										<tr>
											<th>休校日開始日</th>
											<td>
												<TextField
													size="small"
													name="start"
													type="date"
													value={crud.data?.start || ''}
													onChange={handleCRUDChange}
													error={crud?.error?.start && true}
													helperText={
														crud?.error?.start?.map((val:string) => (val)).join(', ')
													}
												/>
											</td>
										</tr>
										<tr>
											<th>休校日終了日</th>
											<td>
												<TextField
													size="small"
													name="end"
													type="date"
													value={crud.data?.end || ''}
													onChange={handleCRUDChange}
													error={crud?.error?.end && true}
													helperText={
														crud?.error?.end?.map((val:string) => (val)).join(', ')
													}
												/>
											</td>
										</tr>
								</ModalTable>
							</EditForm>
					}
				</ModalLayout>
			</ModalBase>
		</CompanySettingsCollapseTable>
	);
};

export default Holiday;