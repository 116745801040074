import React, {useState} from "react";
import axios from "../api/axios";
import {LoadingSpinner} from "../components";
import {authReducer, defaultAuthState} from '../store/auth'
import {SET_AUTH_STATE, SET_AUTH_URL} from '../store/constants'
import {AuthContext} from './authContext'


const AuthProvider:React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [auth, dispatch] = React.useReducer(authReducer, defaultAuthState)
  const [loading, setLoading] = useState<boolean>(true);

  React.useEffect(() => {
    axios.post("/auth/token/refresh/",{}, {
      withCredentials: true
    })
      .then((res) => {
        dispatch({type: SET_AUTH_STATE, payload: res.data})
      })
      .catch((error)=> {
        dispatch({type: SET_AUTH_URL, payload: error.response?.data?.url || ""})
      })
      .finally(()=>{setLoading(false)})
  }, [])



  return (
    <AuthContext.Provider value={{dispatch, auth}}>
      {
        loading
          ?
            <LoadingSpinner />
          :
          children
      }
    </AuthContext.Provider>
  );
};

export default AuthProvider;