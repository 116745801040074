import React from 'react'
import {Link} from 'react-router-dom'
import {
  Box,
  IconButton,
  InputAdornment, Pagination, PaginationItem,
  Paper,
  Stack,
  Table, TableBody, TableCell, tableCellClasses,
  TableContainer, TableHead, TableRow,
  TextField,
  Typography,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {Search} from '@mui/icons-material'
import {CSVLink} from 'react-csv'
import {styled} from '@mui/material/styles'
import {
  attendanceManagementTableHeader,
} from '../../../store/attndanceManagement'
import {useAttendanceManagementTable} from '../hooks/useAttendanceManagementTable'
import {csvButtonStyle} from '../../../store/store'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.dark,
	  whiteSpace: "nowrap"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
	  whiteSpace: "nowrap"
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface AttendanceManagementTableProps {
  id: number;
}

export const AttendanceManagementTable:React.FC<AttendanceManagementTableProps> = ({id}) => {
	const {
		users,
		search,
		handleCSVSelect,
		csvLinkRef,
		fetchData,
		navigate,
		page,
		location
	} = useAttendanceManagementTable(id)

	return (
		<Box width="100%">
			<Typography
				component="div"
				sx={{
					display: "flex",
					alignItems: "center",
					fontSize: 15,
					fontWeight: 500
				}}
			>
				<SearchIcon sx={{fontSize: 25}}/>
				絞り込み
			</Typography>
			<Box sx={{display: "flex", justifyContent: "space-between", alignItems: {xs:"end", sm: "normal"}}}>
				{/* TODO:*/}
				<Stack direction={{xs: "column", xsm: "row",}} spacing={2}>
					<TextField
							name="search"
							size="small"
							onChange={(event)=> {
								search.current = event.target.value
							}}
							InputProps={{
								endAdornment:(
			            <InputAdornment position="end">
			              <IconButton
			                type="button"
			                aria-label="toggle password visibility"
			                edge="end"
			                onClick={fetchData}
			              >
			                <Search />
			              </IconButton>
			            </InputAdornment>
			          )
							}}
						/>
				</Stack>
				{users.results.length > 0 ?
					<select style={csvButtonStyle} onChange={handleCSVSelect}>
						<option value="">CSV</option>
						<option value="1">
							勤怠データの出力
						</option>
						<option value="2">
							勤怠データのフォルダ
						</option>
					</select>
					: ""}
			</Box>
			<TableContainer component={Paper} sx={{width: "100%", my: 1}}>
				<Table sx={{width:"100%", overflow: "scroll"}}>
					<TableHead>
						<StyledTableRow>
							{
								attendanceManagementTableHeader.map((header, index) => (
									<StyledTableCell key={index}>{header.label}</StyledTableCell>
								))
							}
						</StyledTableRow>
					</TableHead>
					<TableBody>
						{
							users.results?.map((obj, index) =>(
								<StyledTableRow onClick={() =>{navigate(`${obj.id}`)}} key={index}>
									<StyledTableCell>{obj.full_name}</StyledTableCell>
									<StyledTableCell>{obj.employee_no}</StyledTableCell>
									<StyledTableCell>{obj.corporate_title_name}</StyledTableCell>
									<StyledTableCell>{obj.department_name}</StyledTableCell>
									<StyledTableCell>{obj.total_workdays}日</StyledTableCell>
									<StyledTableCell>{obj.total_workhours}</StyledTableCell>
									<StyledTableCell>{obj.without_overworking}</StyledTableCell>
									<StyledTableCell>{obj.total_overworks}</StyledTableCell>
									<StyledTableCell>{obj.holiday_workhours}</StyledTableCell>
									<StyledTableCell>{obj.absents}日</StyledTableCell>
									<StyledTableCell>{obj.total_late_hours}</StyledTableCell>
								</StyledTableRow>
							))
						}
					</TableBody>
				</Table>
			</TableContainer>
			<Box width="100%" display="flex" justifyContent="center">
				<Pagination
					count={Math.ceil(users.count / 100)}
					page={page}
					variant="outlined"
					shape="rounded"
					size="small"
					renderItem={(item) => (
						<PaginationItem
							component={Link}
							to={`${location.pathname}?page=${item.page}${location.hash}`}
							{...item}
						/>
					)}
				/>
			</Box>
			<CSVLink ref={csvLinkRef} style={{display: "hidden"}} data={users.results} headers={attendanceManagementTableHeader} filename='AttendanceData.csv' type="button"/>
		</Box>
	);
};