import {CompanySettingsTable} from './Table/CompanySettingsTable'
import {corporateTitleLabels, CorporateTitleT, CrudT, CRUTDataType} from '../../../store/company'
import {TextField, Typography} from '@mui/material'
import {ModalBase, ModalLayout, ModalTable} from '../../../components'
import React from 'react'
import {DeleteForm} from './DeleteForm'
import {EditForm} from './EditForm'

interface CorporateTitleProps{
	states: CorporateTitleT[];
	handleSubmit: () => void;
	handleEdit: (id: number) => void;
	handleDelete: (id: number) => void;
	state?: CorporateTitleT;
	crud: CrudT<CRUTDataType>;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	errors?: {[key:string]: any};
	handleOpen: (data: CorporateTitleT, type: "edit" | "delete") => void;
	handleClose: () => void;
	handleCRUDChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CorporateTitle: React.FC<CorporateTitleProps> = (props) => {
	const {
		states,
		handleSubmit,
		state,
		handleChange,
		errors,
		crud,
		handleEdit,
		handleDelete,
		handleOpen,
		handleClose,
		handleCRUDChange
	} = props;

	return(
		<CompanySettingsTable
			labels={corporateTitleLabels}
			data={states}
			handleSubmit={handleSubmit}
			handleEdit={(data) => {handleOpen(data, "edit")}}
			handleDelete={(data) => {handleOpen(data, "delete")}}
		>
			<Typography>役職を追加：</Typography>
			<TextField
				size="small"
				placeholder="役職名を入力"
				name="title"
				value={state?.title}
				onChange={handleChange}
				error={errors?.title && true}
				helperText={errors?.title ?
          errors.title?.map((val: string) => val).join(', ') : ""}
				sx={{
						width: {xs: "100%", sm:"auto"}
					}}
			/>
			<TextField
				size="small"
				placeholder="階級"
				name="ranking"
				type="number"
				value={state?.ranking}
				onChange={handleChange}
				error={errors?.ranking && true}
				helperText={errors?.ranking ?
          errors.ranking?.map((val: string) => val).join(', ') : ""}
				sx={{
						width: {xs: "100%", sm:"auto"}
					}}
			/>
			<ModalBase open={crud.crudType === 'corporateTitles' && crud.open} handleClose={handleClose}>
				<ModalLayout title={ crud.type === "delete" ? "削除" : "編集"}>
					{ crud.type === "delete"
						?
						<DeleteForm id={crud?.data?.id || 0} handleDelete={handleDelete} handleClose={handleClose}>
							<ModalTable>
								<caption>役職の削除</caption>
								<tbody>
									<tr>
										<th>役職名</th>
										<td>
											{crud.data?.title}
										</td>
									</tr>
									<tr>
										<th>階級</th>
										<td>
											{crud.data?.ranking}
										</td>
									</tr>
								</tbody>
							</ModalTable>
						</DeleteForm>
						:
						<EditForm handleEdit={handleEdit} handleClose={handleClose} id={crud?.data?.id || 0}>
							<ModalTable>
								<caption>役職の編集</caption>
								<tbody>
									<tr>
										<th>役職名</th>
										<td>
											<TextField
												size="small"
												name="title"
												value={crud.data?.title}
												onChange={handleCRUDChange}
												error={crud?.error?.title && true}
												helperText={
													crud?.error?.title.map((val:string) => (val)).join(', ')
												}
											/>
										</td>
									</tr>
									<tr>
										<th>階級</th>
										<td>
											<TextField
												size="small"
												name="ranking"
												value={crud.data?.ranking}
												onChange={handleCRUDChange}
												error={crud?.error?.ranking && true}
												helperText={
													crud?.error?.ranking.map((val:string) => (val)).join(', ')
												}
											/>
										</td>
									</tr>
								</tbody>
							</ModalTable>
						</EditForm>
					}
				</ModalLayout>
			</ModalBase>
		</CompanySettingsTable>
	);
};

export default CorporateTitle;