import React from "react";
import {Box, Button, List, ListItem, Stack, Typography} from "@mui/material";
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CancelIcon from '@mui/icons-material/Cancel';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import {PendingBox} from './PendingBox'
import {PTORequest} from '../../../store/pto'
import {csvButtonStyle} from '../../../store/store'

interface PTOPendingApplicationProps {
	state: PTORequest[]
	handleSubmit: (id: number) => void
	handleDrop: (id: number) => void
	handleExport: () => void
}

export const PTOPendingApplication: React.FC<PTOPendingApplicationProps> = ({state, handleExport, handleSubmit, handleDrop}) => {
	return(
		<>
		<PendingBox
			icon={<EventAvailableIcon sx={{fontSize: 60, color: "secondary.contrastText"}} />}
			title="休暇申請一覧"
			count={state.length}
		>
			<List>
				{
					state.map((value, index)=>(
						<ListItem key={index} divider disableGutters sx={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
							<Box>
								<Box mb={0.5}>
									<Box component="span">申請日:{value.created_dt}</Box>
									<Box component="span">申請者:{value.requester_display}</Box>
								</Box>
								<Box p={0.5}>
									<Typography component="div" fontSize={12} color="primary" fontWeight="normal">
										{value.vacation_type_display} | {value.start_display} ~ {value.end_display}
									</Typography>
								</Box>
								<Typography component="span">【申請理由】</Typography>
								<Typography paragraph>{value.note}</Typography>
							</Box>
							<Stack spacing={2}>
								<Button
									variant="contained"
									sx={{color: "primary.light"}}
									endIcon={<AssignmentTurnedInIcon />}
									onClick={() => handleSubmit(value.id)}
								>
									承認
								</Button>
								<Button
									variant="outlined"
									endIcon={<CancelIcon />}
									onClick={() => handleDrop(value.id)}
								>
									差戻
								</Button>
							</Stack>
						</ListItem>
					))
				}
			</List>
		</PendingBox>

		<Box mt={2} sx={{display: "flex", justifyContent: "flex-end", alignItems: {xs:"end", sm: "normal"}}}>
			<button type='button' style={csvButtonStyle} onClick={handleExport}>承認済休暇申請データの出力</button>
		</Box>
	</>

	);
};
