import React from "react";
import {Button, Stack} from "@mui/material";
import {useTimecardChangeApplication} from './useTimecardChangeApplication'
import {ReadTimeCardChangeSentApplication} from './ReadTimeCardChangeSentApplication'
import {EditTimecardChangeSentApplication} from './EditTimecardChangeSentApplication'
import {ModalLayout} from '../index'

interface TimeCardChangeSentApplicationProps {
	id: number;
	handleClose: () => void;
}

const TimeCardChangeSentApplication: React.FC<TimeCardChangeSentApplicationProps> = ({handleClose, id}) => {
	const {
		state,
		errors,
		handleChange,
		handleSubmit,
		handleDrop,
	} = useTimecardChangeApplication(id, handleClose)

	if (!state){
		return <></>
	}

	return (
		<ModalLayout title="打刻時間変更申請">
			{
				state.status !== "send_back"
					?
					<ReadTimeCardChangeSentApplication state={state} />
					:
					<EditTimecardChangeSentApplication handleChange={handleChange} errors={errors} state={state}/>
			}
			<Stack
					direction={{xs:"row", ms:"column"}} spacing={3}
				>
					<Button type="button" variant="contained" color="inherit" sx={{flex:1}} onClick={handleClose}>戻る</Button>
					{
						state.status !== 'completed'
						&&
						<Button type="button" onClick={handleDrop} variant="outlined" color="error" sx={{flex:1}}>申請取下げ</Button>
					}
					{
						state.status === 'send_back'
						&&
						<Button type="button" onClick={handleSubmit} variant="outlined" sx={{flex:1}}>再申請</Button>
					}
				</Stack>
		</ModalLayout>
	)
}

export default TimeCardChangeSentApplication