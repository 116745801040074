import React from 'react'
import {TimecardChangeApplicationT} from '../../store/timecard'
import {ModalTable} from '../index'
import {TextField} from '@mui/material'

interface SentApplicationProps {
  handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  state: TimecardChangeApplicationT;
  errors?: any
}

export const ReadTimeCardChangeSentApplication: React.FC<SentApplicationProps> = ({state, handleChange, errors}) => {
  return (
    <ModalTable>
      <caption>申請日: {state.created_dt}</caption>
      <tr>
        <th>ステータス</th>
        <td>
          {state.status_display}
        </td>
      </tr>
      <tr>
        <th>日付</th>
        <td>
          {state.date || '-'}
        </td>
      </tr>
      <tr>
        <th>修正前打刻(出勤)</th>
        <td>
          {state.old_start_display || '-'}
        </td>
      </tr>
      <tr>
        <th>修正後打刻(出勤)</th>
        <td>{state.new_start || '-'}</td>
      </tr>
      <tr>
        <th>修正理由(出勤)</th>
        <td>{state.reasons?.start}</td>
      </tr>
      <tr>
        <th>修正前打刻(退勤)</th>
        <td>
          {state.old_end_display || '-'}
        </td>
      </tr>
      <tr>
        <th>修正後打刻(退勤)</th>
        <td>{state.new_end || '-'}</td>
      </tr>
      <tr>
        <th>修正理由(退勤)</th>
        <td>{state.reasons?.end}</td>
      </tr>
      {
        handleChange ? <tr>
            <th>コメント</th>
            <td>
              <TextField
                size="small"
                value={state.reasons.comment}
                name="comment"
                onChange={handleChange}
                error={errors.reasons?.comment && true}
                disabled={state.status !== 'pending'}
                helperText={errors.reasons?.comment &&
                  errors.reasons?.comment.map((val: string) => val).join(', ')}
                inputProps={{
                  'data-name': 'reasons',
                }}
              />
            </td>
          </tr>
          : <></>
      }
    </ModalTable>
  )
}