import React from "react";
import {Box, Divider, Paper, Stack, Typography} from "@mui/material";
import {TimecardStatus} from '../../../store/timecard'
import {DisplayTodayDate} from './DisplayTodayDate'
import {TimeCardAdditionalButtons} from './TimeCardAdditionalButtons'
import {TimeCardActionButtons} from './TimeCardActionButtons'




interface TimeCardProps {
	status: TimecardStatus | null;
  handleStart: () => void;
  handleEnd: () => void;
}

const TimeCard: React.FC<TimeCardProps> = (props) => {
	const {status, handleStart, handleEnd} = props;

	return (
		<Paper variant="outlined">
			<Stack
				direction="row"
				divider={<Divider orientation="vertical" flexItem/>}
				spacing={1}
				width="100%"
				bgcolor="primary.light"
			>
				<Box width="40%" p={1}>
					<Typography width="100%" textAlign="left" gutterBottom color="secondary.dark">
						現在の時刻
					</Typography>
					<DisplayTodayDate />
				</Box>
				<Box width="60%" p={1}>
					<Box sx={{display:"flex", justifyContent:"space-between", mb:1}}>
						<Typography color="secondary.dark">
						打刻
						</Typography>
						<TimeCardAdditionalButtons status={status} />
					</Box>
					<TimeCardActionButtons status={status} handleStart={handleStart} handleEnd={handleEnd}/>
				</Box>
			</Stack>
		</Paper>
	);
};

export default TimeCard;