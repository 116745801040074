import React, {useEffect} from 'react'
import {VacationApproverT, VacationType} from '../../../store/company'
import useAuthAxios from '../../../hooks/useAuthAxios'
import {useAuth} from '../../../hooks/useAuth'

export const useEditSentRestApplication = () => {
  const [vacations, setVacations] = React.useState<VacationType[]>([]);
	const [users, setUsers] = React.useState<VacationApproverT[]>([]);
	const {auth} = useAuth();
	const axios = useAuthAxios();

	useEffect(()=>{
		axios.get(`/companies/vacation-approver/?department=${auth.user.department_id}`)
			.then((res)=>{
				setUsers(res.data);
			})
		  .catch((err) => console.log(err))
		axios.get(`/companies/vacation-type/`)
			.then((res)=>{
				setVacations(res.data);
			})
		  .catch((err) => console.log(err))
	},[axios, auth.user.department_id])

  return {
    vacations,
    users,
		user: auth.user
  }
}