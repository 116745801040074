export const SET_ACTOR_NOTIFICATIONS = 'SET_ACTOR_NOTIFICATIONS'
export const SET_RECIPIENT_NOTIFICATIONS = 'SET_RECIPIENT_NOTIFICATIONS'
export const SET_ERROR_NOTIFICATIONS = 'SET_ERROR_NOTIFICATIONS'

export const SET_AUTH_STATE = 'SET_AUTH_STATE'
export const DELETE_AUTH_STATE = 'DELETE_AUTH_STATE'
export const SET_AUTH_URL = 'SET_AUTH_URL'
export const SET_ALERT_MESSAGE = 'SET_ALERT_MESSAGE'
export const CLEAR_ALERT_MESSAGE = 'CLEAR_ALERT_MESSAGE'
export const INCREMENT_LOADING = 'INCREMENT_LOADING'
export const DECREMENT_LOADING = 'DECREMENT_LOADING'