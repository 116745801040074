import {MinimalUserDetail} from './users'
import {LabelT} from '../layouts/companySettings/components/Table/CompanySettingsTable'

export type VacationType = {
  company: number
  for_absent: boolean
  hourly: boolean
  id: number
  is_transfer: boolean
  max_requests: number
  name: string
  order: number
  payable: boolean
  special: boolean
  value: number
}

export type VacationApproverT = {
  id: number
	full_name: string
	user: number
}

export type VacationApproverDetail = {
	id?: number;
	company: number | "";
	full_name: string;
	user: number | "";
	users: string[];
	users_display?: MinimalUserDetail[];
}

export type CompanyGroup = {
  id?: number
  company: number
  name: string
}

export type DepartmentT = {
	id?: number
	company: number | ""
	name: string
}

export type NoCompensatoryHolidayT = {
	id?: number
	company: number | ""
	user: number | ""
	full_name?: string
}

export type HolidayT = {
	id?: number
	company?: number
	start: string
	end: string | null
	start_display: string
	end_display: string | null
}

export type HolidayDataT = {
	year: string
	total_holidays: number
	data: HolidayT[]
}

export type CorporateTitleT = {
	id?: number;
	company: number | "";
	title: string;
	ranking: number | "";
}

export type CrudT<T> = {
	type: "delete" | "edit";
	data: T | null
	open: boolean;
	error?: {[key: string]: any};
	crudType?: keyof CompanySettingsState
}

export type CompanySettingsStates = {
	departments: DepartmentT[]
	corporateTitles: CorporateTitleT[]
	groups: CompanyGroup[]
	vacationApprovers: VacationApproverDetail[]
	users: MinimalUserDetail[]
	noCompensatoryHolidays: NoCompensatoryHolidayT[]
	holidays: HolidayDataT[]
}

export type CompanySettingsState = {
	departments?: DepartmentT
	corporateTitles?: CorporateTitleT
	groups?: CompanyGroup
	vacationApprovers?: VacationApproverDetail
	noCompensatoryHolidays?: NoCompensatoryHolidayT
	holidays?: HolidayT
}

export type CRUTDataType = CorporateTitleT & DepartmentT & CompanyGroup & VacationApproverDetail & NoCompensatoryHolidayT & HolidayT
export const crudInitial: CrudT<CRUTDataType> = {
	type:"edit", open: false, data: null
}

type AlertMessageT = {
	[K in keyof Omit<CompanySettingsStates, 'users'>]: {
		[K in "edit" | "delete" | "add"]: {
			success: string
			error: string
		}
	}
}

export const alertMessage:AlertMessageT = {
	"corporateTitles": {
		"edit": {
			"success": "役職を編集しました",
			"error": "役職編集できませんでした"
		},
		"delete": {
			"success": "役職を削除しました",
			"error": "役職削除できませんでした"
		},
		"add": {
			"success": "役職を追加しました",
			"error": "役職追加できませんでした"
		}
	},
	"departments": {
		"edit": {
			"success": "部署を編集しました",
			"error": "部署編集出来ませんでした"
		},
		"delete": {
			"success": "部署を削除しました",
			"error": "部署削除できませんでした"
		},
		"add": {
			"success": "部署を追加しました",
			"error": "部署追加できませんでした"
		}
	},
	"groups": {
		"edit": {
			"success": "グループを編集しました",
			"error": "グループ編集できませんでした"
		},
		"delete": {
			"success": "グループを削除しました",
			"error": "グループ削除できませんでした"
		},
		"add": {
			"success": "グループを追加しました",
			"error": "グループ追加できませんでした"
		}
	},
	"vacationApprovers": {
		"edit": {
			"success": "承認者を編集しました",
			"error": "承認者編集できませんでした"
		},
		"delete": {
			"success": "承認者を削除しました",
			"error": "承認者削除できませんでした"
		},
		"add": {
			"success": "承認者を追加しました",
			"error": "承認者追加できませんでした"
		}
	},
	"noCompensatoryHolidays": {
		"edit": {
			"success": "振替休日付与を編集しました",
			"error": "振替休日付与編集できませんでした"
		},
		"delete": {
			"success": "振替休日付与を削除しました",
			"error": "振替休日付与削除できませんでした"
		},
		"add": {
			"success": "振替休日付与を追加しました",
			"error": "振替休日付与追加できませんでした"
		}
	},
	"holidays": {
		"edit": {
			"success": "休校日を編集しました",
			"error": "休校日編集できませんでした"
		},
		"delete": {
			"success": "休校日を削除しました",
			"error": "休校日削除できませんでした"
		},
		"add": {
			"success": "休校日を追加しました",
			"error": "休校日追加できませんでした"
		}
	}
}

export const companyColumnData = [
	"役職管理",
	"部署管理",
	"グループ管理",
	"休暇承認設定",
	"振替休日付与設定",
	"休校日設定",
]

export const corporateTitleLabels:LabelT<CorporateTitleT>[] = [
	{name: "階級", keyName:"ranking"},
	{name: "役職", keyName:"title"}
]

export const departmentLabels:LabelT<DepartmentT>[] = [
	{name: "部署名", keyName:"name"},
]

export const groupLabels:LabelT<CompanyGroup>[] = [
	{name: "グループ一覧", keyName:"name"},
]

export const vacationApproverLabels:LabelT<VacationApproverDetail>[] = [
	{name: "承認者", keyName:"full_name"},
	{name: "個人", keyName:["users_display", "full_name"]},
]

export const noCompensatoryHolidayLabels:LabelT<NoCompensatoryHolidayT>[] = [
	{name: "職員", keyName:"full_name"},
]

export const holidayLabels = {
	main: [
		{name: "年", keyName: "year"},
	],
	collapse: [
		{name: "開始日", keyName: "start_display"},
		{name: "終了日", keyName: "end_display"},
	],
	collapseTitle: "休暇日"
}


export const getUrl = (stateName: keyof CompanySettingsState, method: "post" | "patch" | "delete", id?: number) => {
	let url = ''
	switch (stateName) {
		case 'departments':
			url = method === "post" ? "/companies/department/" : `/companies/department/${id}/`
			break
		case 'corporateTitles':
			url = method === "post" ? "/companies/corporate-title/" : `/companies/corporate-title/${id}/`
			break
		case 'groups':
			url = method === "post" ? "/companies/group/" : `/companies/group/${id}/`
			break
		case 'vacationApprovers':
			url = method === "post" ? "/companies/vacation-approver/" : `/companies/vacation-approver/${id}/`
			break
		case 'noCompensatoryHolidays':
			url = method === "post" ? "/companies/no-compensatory-holidays/" : `/companies/no-compensatory-holidays/${id}/`
			break
		case 'holidays':
			url = method === "post" ? "/companies/holiday/" : `/companies/holiday/${id}/`
			break
	}
	return url
}