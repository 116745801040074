import React from 'react'
import {Box, Divider, List, ListItem, ListItemText, Paper, Stack, Typography} from '@mui/material'

interface SettingsBoxProps {
	icon: React.ReactNode;
	label: string;
	descriptions: string[];
	handleClick?: () => void;
}

export const SettingsBox: React.FC<SettingsBoxProps> = ({icon, label, descriptions, handleClick}) => {
	return(
		<Paper elevation={2} sx={{p:2, height: "100%", cursor: "pointer"}} onClick={handleClick}>
			<Stack divider={<Divider orientation="horizontal" flexItem />}>
				<Box display="flex" justifyContent="center" alignItems="center">
					<Typography variant="h5" sx={{verticalAlign: 'middle', display: 'inline-flex', flexDirection: "column", alignItems: "center"}}>
						{icon} {label}
					</Typography>
				</Box>
				<Box width="100%" sx={{verticalAlign: 'middle', display: 'inline-flex', flexDirection: "column", alignItems: "center"}}>
					<List
						sx={{ display: "flex", flexWrap: "wrap", width: {xs:"100%", md:"90%"}, justifyContent: "start" }}
					>
						{descriptions.map((value, index) => (
							<ListItem key={index} sx={{ width:"50%"}}>
								<ListItemText primary={`● ${value}`} />
							</ListItem>
						))}
					</List>
				</Box>
			</Stack>
		</Paper>
	);
};