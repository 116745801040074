import React, {useEffect, useState} from 'react'
import useAuthAxios from '../../hooks/useAuthAxios'
import {TimecardChangeApplicationT} from '../../store/timecard'
import {useTimecard} from '../../hooks/useTimecard'


export const useTimecardChangeReceiveApplication = (
  id: number,
	handleClose: () => void
) => {
  const [state, setState] = useState<TimecardChangeApplicationT>(null!);
	const [errors, setErrors] = useState<any>({});
  const {handleTimecardChange} = useTimecard<TimecardChangeApplicationT>()
	const axios = useAuthAxios();

	useEffect(()=>{
		axios.get(`/timecard-change/${id}/receive/`)
			.then((res) => { setState(res.data)})
		  .catch((err) => console.log(err))
	},[id, axios]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleTimecardChange(event, setState);
	}

	const handleSubmit = () => {
		axios.patch(`/timecard-change/${id}/admin_update/`, {status: "completed"})
			.then(() => {
				handleClose();
			})
			.catch((res)=>{
				setErrors(res.response.data);
			})
	}

	const handleDrop  = () => {
		axios.patch(`/timecard-change/${id}/admin_update/`, {status: "send_back", ...state.reasons})
			.then(() => {
				handleClose();
			})
			.catch((err) => console.log(err))
	};

  return {
    state,
    errors,
    handleChange,
    handleSubmit,
    handleDrop
  }
}