import {useNavigate} from 'react-router-dom'
import {Grid} from '@mui/material'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import BusinessIcon from '@mui/icons-material/Business'
import React from 'react'
import {SettingsBox} from './components/SettingsBox'
import {companyDescription, userDescription} from '../../store/settings'
import {BaseLayout} from '../index'

const SettingsLayout: React.FC = () => {
  const navigate = useNavigate()
  const handleClick = (path: string) => {
    navigate(path)
  }
  return (
    <BaseLayout>
      <Grid container spacing={6} sx={{height: 300, justifyContent: 'center'}}>
        <Grid item xs={12} sm={6} md={6}>
          <SettingsBox
            label="ユーザー管理"
            icon={<ManageAccountsIcon sx={{fontSize: 70}}/>}
            descriptions={userDescription}
            handleClick={() => handleClick('users')}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <SettingsBox
            label="組織管理"
            icon={<BusinessIcon sx={{fontSize: 70}}/>}
            descriptions={companyDescription}
            handleClick={() => handleClick('company')}
          />
        </Grid>
      </Grid>
    </BaseLayout>
  )
}

export default SettingsLayout