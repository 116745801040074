import React, {useEffect, useState} from 'react'
import useAuthAxios from '../../../hooks/useAuthAxios'
import {defaultTimecardMemo, TimecardMemoT} from '../../../store/timecard'
import {useAlertMessage} from '../../../hooks/useAlertMessage'

export const useTimecardMemo = (
  handleClose: () => void
) => {
  const axios = useAuthAxios();
	const { setAlertMessage } = useAlertMessage();
	const [state, setState] = useState<TimecardMemoT>(defaultTimecardMemo);
	const [errors, setErrors] = useState<{[key:string]: any}>({})

	useEffect(()=>{
		axios.get(`/timecard/memo/`)
			.then((res)=>setState(res.data))
		  .catch((err) => console.log(err))
	},[axios])

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		if (state.id) {
			axios.patch(`/timecard/memo/${state.id}/`, {memo: state.memo})
			.then((res)=> {
				setState(res.data);
				setAlertMessage({open: true, message: "勤怠メモを変更しました", status: "success"});
				handleClose()
			})
			.catch((error) => {
				setErrors(error.response.data);
				setAlertMessage({open: true, message: "変更できませんでした", status: "error"})
			})
		} else {
			axios.post(`/timecard/memo/`, {memo: state.memo, date: state.date})
			.then((res)=> {
				setState(res.data);
				setAlertMessage({open: true, message: "勤怠メモを追加しました", status: "success"});
				handleClose()
			})
			.catch((error) => {
				setErrors(error.response.data);
				setAlertMessage({open: true, message: "追加できませんでした", status: "error"})
			})
		}
	}

  const handleChangeMemo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target
    setState((prevState) => ({...prevState, [name]: value}))
  }

  return {
    state,
    errors,
    handleSubmit,
    handleChangeMemo
  }
}