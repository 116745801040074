import {createTheme} from "@mui/material";

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xsm: true;
  }
}

const theme = createTheme({
	palette: {
    primary: {
      light: '#ffffff',
      main: '#196DC2',
      dark: '#7ccef4',
      contrastText: '#191919',
    },
    secondary: {
      light: '#e5f5ff',
      main: '#faf8f7',
      dark: '#cccccc',
      contrastText: '#828282',
    },
    third: {
      light: '#ffffff',
      main: '#828282',
      dark: '#cccccc',
      contrastText: '#828282',
    },
  },
  components: {
        MuiToolbar: {
            styleOverrides: {
                dense: {
                    minHeight: 40
                }
            }
        }
    },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      xsm: 360
    },
  },
})

export default theme;