import {VacationType} from '../store/company'
import {AuthUser} from '../store/auth'

export const optionVacationDisabled = (val: VacationType, user: AuthUser) => {
		if (val.payable && !val.special){
			if(val.max_requests > 0 && user.max_request_remaining === 0){
				return true
			}
			if(val.hourly && val.value <= user.remaining_pto_hours){
				return false
			}
			return !(!val.hourly && val.value * 8 <= user.remaining_pto_hours);
		}
		return false
	}