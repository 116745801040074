import React from "react";
import {Divider, Paper, Stack, Box, Typography, Button} from "@mui/material";
import EventBusyIcon from '@mui/icons-material/EventBusy';
import {PTOInfo, PTORequestApplication} from '../../../store/pto'
import {VacationApproverT, VacationType} from '../../../store/company'
import {useAuth} from '../../../hooks/useAuth'
import {PTOApplicationForm} from './PTOApplicationForm'
import {ModalBase, ModalLayout, ModalTable} from '../../../components'
import {dateStringToJpString} from '../../../utils/date'


interface PTOApplicationProps{
	handleChange: (event:React.ChangeEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>) => void;
	handleSubmit: () => void;
	state: PTORequestApplication;
	errors: {[key:string]: any};
	vacations: VacationType[];
	users: VacationApproverT[];
	pto: PTOInfo;
	handleValidation: () => void;
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PTOApplication: React.FC<PTOApplicationProps> = (props) => {
	const {handleChange, errors, handleSubmit, state, vacations, users, pto, handleValidation, setOpen, open} = props;
	const {auth} = useAuth();
	return(
		<Paper elevation={2} sx={{p:2}}>
			<Stack direction={{xs: "column", sm: "row"}} divider={<Divider orientation="vertical" flexItem />} sx={{alignItems: "center"}} justifyContent="space-between" spacing={1}>
				<Box display="flex" flexDirection="column" gap={2} justifyContent="center" alignItems="center" width="40%">
					<Typography variant="h5" sx={{verticalAlign: 'middle', display: 'inline-flex', flexDirection: "column", alignItems: "center"}}>
					    <EventBusyIcon sx={{fontSize: 70}}/> 休暇申請
					</Typography>
					<Box>
						<Typography><b>前年度繰越日数:</b> <b style={{color:"#196DC2"}}>{pto.prev_total}日</b></Typography>
						<Typography><b>今年度付与日数:</b> <b style={{color:"#196DC2"}}>{pto.current_total}日</b></Typography>
						<Typography><b>2時間給取得残数:</b> <b style={{color:"#196DC2"}}>{pto.remaining_max_requests}回</b></Typography>
					</Box>
				</Box>
				<Box>
					<PTOApplicationForm user={auth.user} errors={errors} vacations={vacations} handleSubmit={handleValidation} handleChange={handleChange} users={users} state={state} />
				</Box>
			</Stack>
			<ModalBase open={open} handleClose={()=>{setOpen(false)}}>
				<ModalLayout title="休暇申請の内容">
					<ModalTable>
						<caption>以下の内容で申請します。</caption>
						<tbody>
							<tr>
								<th>休暇希望日</th>
								<td>{dateStringToJpString(state.start)} ~ {dateStringToJpString(state.end)}</td>
							</tr>
							<tr>
								<th>申請項目</th>
								<td>{state.vacation_type_display}</td>
							</tr>
							<tr>
								<th>承認者</th>
								<td>{state.approver_display}</td>
							</tr>
							<tr>
								<th>申請備考</th>
								<td>{state.note}</td>
							</tr>
						</tbody>
					</ModalTable>
					<Stack
						direction={{xs:"column", sm:"row"}} spacing={3}
					>
						<Button type="button" variant="contained" color="inherit" sx={{flex:1}} onClick={()=> setOpen(false)}>キャンセル</Button>
							<Button type="button" variant="contained" form="memo-form" sx={{flex:1}} onClick={handleSubmit}>申請する</Button>
					</Stack>
				</ModalLayout>
			</ModalBase>
		</Paper>
	);
};

export default PTOApplication;