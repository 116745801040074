import {CLEAR_ALERT_MESSAGE, SET_ALERT_MESSAGE} from './constants'

export type AlertMessageState = {
	open: boolean;
	status: "error" | "warning" | "info" | "success";
	message: string;
}

export type AlertMessageActionType =
  | { type: typeof SET_ALERT_MESSAGE; payload: AlertMessageState }
  | { type: typeof CLEAR_ALERT_MESSAGE}

export const defaultAlertMessageState: AlertMessageState = {
  open: false,
	status: "info",
	message: ""
}

export const alertMessageReducer = (state: AlertMessageState, action: AlertMessageActionType): AlertMessageState => {
  switch (action.type) {
    case SET_ALERT_MESSAGE:
      return { ...state, ...action.payload }
    case CLEAR_ALERT_MESSAGE:
      return defaultAlertMessageState
    default:
      return state
  }
}