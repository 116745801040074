import React from "react";
import {Button, FormControl, Stack, TextField} from "@mui/material";
import {useTimecardMemo} from '../hooks/useTimecardMemo'
import {ModalLayout} from '../../../components'

interface TimeCardMemoProps{
	handleClose: () => void;
}
const TimeCardMemo: React.FC<TimeCardMemoProps> = ({handleClose}) => {
	const {handleSubmit, state, handleChangeMemo, errors} = useTimecardMemo(handleClose)
	return (
		<ModalLayout title="勤怠メモ" >
			<FormControl component="form" method="post" fullWidth id="memo-form" onSubmit={handleSubmit}>
				<TextField
					fullWidth
					name="memo"
          size="small"
          multiline
          rows="10"
					required
					id="memo-input"
					label={state.date_read}
					value={state.memo || ""}
					onChange={handleChangeMemo}
					error={errors.memo && true}
          helperText={errors.memo ?
            errors.memo.map((val: string) => val).join(', ') : " "}
				/>
			</FormControl>
			<Stack
				direction={{xs:"row", ms:"column"}} spacing={3}
			>
				<Button type="button" variant="contained" color="inherit" sx={{flex:1}} onClick={handleClose}>キャンセル</Button>
				<Button type="submit" variant="contained" form="memo-form" sx={{flex:1}}>変更</Button>
			</Stack>
		</ModalLayout>
	);
};

export default TimeCardMemo;