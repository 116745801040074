import React from "react";
import {UserDetail, UserSettingsState} from '../../../store/users'
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem, Stack,
  TextField,
} from '@mui/material'
import {ModalLayout, ModalTable} from '../../../components'
import {NestedError} from '../../../store/errors'

interface UserEditProps {
  user?: UserDetail
  handleChange: (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => void
  errors?:  NestedError<UserDetail>
  handleSave: (e:React.FormEvent<HTMLFormElement>) => void
  handleCancel: () => void
  states: UserSettingsState
	handlePTOCalendarModalOpen: () => void
}

export const UserEdit: React.FC<UserEditProps> = (props) => {
  const {
    user,
    handleChange,
    errors,
    handleSave,
    handleCancel,
		states,
		handlePTOCalendarModalOpen
  } = props

  return (
    <ModalLayout title="変更">
			<FormControl fullWidth component="form" method="post" id="profile-form" onSubmit={handleSave}>
				<ModalTable>
					<caption>以下の内容で変更します。</caption>
					<thead>
						<tr>
							<th>従業員番号</th>
							<td>
								<TextField
									size="small"
									value={user?.employee_no || ''}
									name="employee_no"
									error={errors?.employee_no && true}
				          helperText={errors?.employee_no ?
				            errors?.employee_no.map((val: string) => val).join(', ') : ""}
									onChange={handleChange}
								/>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<th>氏名</th>
							<td>
								<TextField
									size="small"
									required
									value={user?.last_name || ''}
									name="last_name"
									sx={{mr: 1}}
									error={errors?.last_name && true}
				          helperText={errors?.last_name ?
				            errors?.last_name?.map((val: string) => val).join(', ') : ""}
									onChange={handleChange}
								/>
								<TextField
									size="small"
									required
									value={user?.first_name || ''}
									name="first_name"
									error={errors?.first_name && true}
				          helperText={errors?.first_name ?
				            errors?.first_name?.map((val: string) => val).join(', ') : ""}
									onChange={handleChange}
								/>
								<FormControlLabel control={
									<Checkbox name="is_admin"
									          checked={user?.is_admin}
									          onChange={handleChange}
									/>
									} label="管理者" />
							</td>
						</tr>
						<tr>
							<th>入社日</th>
							<td>
								<TextField
									size="small"
									value={user?.hire_dt || ''}
									type="date"
									name="hire_dt"
									error={errors?.hire_dt && true}
				          helperText={errors?.hire_dt ?
				            errors?.hire_dt?.map((val: string) => val).join(', ') : ""}
									onChange={handleChange}
								/>
							</td>
						</tr>
						<tr>
							<th>振替休日</th>
							<td>
								<TextField
									size="small"
									value={user?.transfer_days || ''}
									type="number"
									name="transfer_days"
									error={errors?.transfer_days && true}
				          helperText={errors?.transfer_days ?
				            errors?.transfer_days?.map((val: string) => val).join(', ') : ""}
									onChange={handleChange}
								/>
							</td>
						</tr>
						<tr>
							<th>メールアドレス</th>
							<td>
								<TextField
									fullWidth
									size="small"
									value={user?.email || ''}
									name="email"
									error={errors?.email && true}
				          helperText={errors?.email ?
				            errors?.email?.map((val: string) => val).join(', ') : ""}
									onChange={handleChange}
									/>
							</td>
						</tr>
						<tr>
							<th>役職 / 部署</th>
							<td>
								<TextField
									size="small"
									value={user?.corporate_title || ''}
									name="corporate_title"
									error={errors?.corporate_title && true}
				          helperText={errors?.corporate_title ?
				            errors?.corporate_title?.map((val: string) => val).join(', ') : ""}
									onChange={handleChange}
									sx={{
										mr:1,
										'& .MuiSelect-select .notranslate::after': "役職を選択"
				            ? {
				                content: `"役職を選択"`,
				                opacity: 0.42,
				              }
				            : {},
									}}
									select
								>
									<MenuItem sx={{opacity: 0.42}} value="">役職を選択</MenuItem>
									{
										states.corporateTitles.map((val, index) => (
											<MenuItem value={val.id} key={index}>{val.title}</MenuItem>
										))
									}
								</TextField>
								<TextField
									size="small"
									value={user?.departments || ''}
									name="departments"
									error={errors?.departments && true}
				          helperText={errors?.departments ?
				            errors?.departments.map((val: string) => val).join(', ') : ""}
									onChange={handleChange}
									sx={{
										mr:1,
										'& .MuiSelect-select .notranslate::after': "役職を選択"
				            ? {
				                content: `"部署を選択"`,
				                opacity: 0.42,
				              }
				            : {},
									}}
									select
								>
									<MenuItem sx={{opacity: 0.42}} value="">部署を選択</MenuItem>
									{
										states.departments.map((val, index) => (
											<MenuItem value={val.id} key={index}>{val.name}</MenuItem>
										))
									}
								</TextField>
								<TextField
									size="small"
									value={user?.group || ''}
									name="group"
									error={errors?.group && true}
				          helperText={errors?.group ?
				            errors?.group.map((val: string) => val).join(', ') : ""}
									onChange={handleChange}
									sx={{
										'& .MuiSelect-select .notranslate::after': "グループを選択"
				            ? {
				                content: `"グループを選択"`,
				                opacity: 0.42,
				              }
				            : {},
									}}
									select
								>
									<MenuItem sx={{opacity: 0.42}} value="">グループを選択</MenuItem>
									{
										states.groups.map((val, index) => (
											<MenuItem value={val.id} key={index}>{val.name}</MenuItem>
										))
									}
								</TextField>
							</td>
						</tr>
					<tr>
						<th>有給休暇</th>
						<td>
							<Button
								type="button"
								variant="contained"
								color="inherit"
								onClick={handlePTOCalendarModalOpen}
								>
								有給休暇管理
							</Button>
						</td>
					</tr>
					</tbody>
				</ModalTable>
				</FormControl>
				<Stack
					direction={{xs:"row", ms:"column"}} spacing={3}
				>
					<Button type="button" variant="contained" color="inherit" sx={{flex:1}} onClick={handleCancel}>キャンセル</Button>
					<Button type="submit" variant="contained" form="profile-form" sx={{flex:1}}>変更</Button>
				</Stack>
		</ModalLayout>
  )
}