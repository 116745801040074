import React, {useEffect} from 'react'
import useAuthAxios from '../../../hooks/useAuthAxios'
import {useAuth} from '../../../hooks/useAuth'
import {useAlertMessage} from '../../../hooks/useAlertMessage'
import {defaultPTORequestApplication, PTOInfo, PTORequestApplication} from '../../../store/pto'
import {VacationApproverT, VacationType} from '../../../store/company'

export const usePTOApplication = () => {
  const [state, setState] = React.useState<PTORequestApplication>(defaultPTORequestApplication);
	const [vacations, setVacations] = React.useState<VacationType[]>([]);
	const [users, setUsers] = React.useState<VacationApproverT[]>([]);
	const [errors, setErrors] = React.useState<{[key:string]:any}>({});
	const [open, setOpen] = React.useState<boolean>(false);
	const [pto, setPto] = React.useState<PTOInfo>({current_total: 0, prev_total: 0, remaining_max_requests: 0})
	const axios = useAuthAxios();
	const { auth } = useAuth();
	const {setAlertMessage} = useAlertMessage();

	React.useEffect(()=>{
		axios.get(`/companies/vacation-approver/approver_users/`)
			.then((res)=>{
				setUsers(res.data);
			})
			.catch((err) => console.log(err))
		axios.get(`/companies/vacation-type/`)
			.then((res)=>{
				setVacations(res.data);
			})
			.catch((err) => console.log(err))
		axios.get('/pto/get/')
			.then((res) => {
				setPto(res.data);
			})
			.catch((err) => console.log(err))
	},[axios])
	const handleChange = (event: React.ChangeEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>) => {
		const {name, value} = event.target;
		setState((prevState) => ({...prevState, [name]: value}));
	}

	useEffect(() => {
		if (!state.end || state.start > state.end) {
			setState((prev) => ({...prev, end: state.start}))
		}
	}, [state.start, state.end])

	const handleSubmit = () => {
		let cp_data = state;
		if (cp_data["end"] === ""){
			cp_data["end"] = cp_data["start"];
		}
		axios.post('pto/', cp_data)
			.then(() => {
				setAlertMessage({open:true, message:"申請が完了しました", status:"success"})
				setState(defaultPTORequestApplication);
				setErrors({})
				setOpen(false)
			})
			.catch((res) => {
				setErrors(res.response.data);
				setAlertMessage({open:true, message:"申請ができませんでした", status:"error"})
			})
	}

	const handleValidation = () => {
		let cp_data = state;
		cp_data["requester"] = auth.user.id
		if (cp_data["end"] === ""){
			cp_data["end"] = cp_data["start"];
		}
		axios.post('pto/validate_data/', cp_data)
			.then((res) => {
				setState(res.data);
				setErrors({})
				setOpen(true)
			})
			.catch((res) => {
				setErrors(res.response.data);
				setAlertMessage({open:true, message:"申請ができませんでした", status:"error"})
			})
	}

  return {
    state,
    errors,
    handleChange,
    handleSubmit,
    handleValidation,
    vacations,
    users,
    open,
    setOpen,
    pto
  }
}