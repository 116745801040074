import HomeIcon from '@mui/icons-material/Home';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import EventBusyIcon from "@mui/icons-material/EventBusy";
import SettingsIcon from '@mui/icons-material/Settings';
import BadgeIcon from '@mui/icons-material/Badge';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

export const pages = [
	{
		label: "ホーム",
		icon: <HomeIcon />,
		pc: true,
		mobile: true,
		forbidden: [],
		location: '/'
	},
	{
		label: "日次勤怠",
		icon: <PunchClockIcon />,
		pc: true,
		mobile: true,
		forbidden: [],
		location: '/daily-attendance',
		params: [
			'year',
			'month'
		]
	},
	{
		label: "休暇申請",
		icon: <EventBusyIcon />,
		pc: true,
		mobile: true,
		forbidden: [],
		location: '/pto'
	},
	{
		label: "申請一覧",
		icon: <ReceiptLongIcon />,
		pc: true,
		mobile: false,
		forbidden: ['is_admin', 'sub_admin'],
		location: '/applications-list'
	},
	{
		label: "勤怠管理",
		icon: <BadgeIcon />,
		pc: true,
		mobile: false,
		forbidden: ['is_admin'],
		location: '/attendance-management',
		params: [
			'year',
			'month'
		]
	},
	{
		label: "設定",
		icon: <SettingsIcon />,
		pc: true,
		mobile: false,
		forbidden: ['is_admin'],
		location: '/settings'
	},
]