import React from 'react'
import {Box, Modal} from '@mui/material'

const boxStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
	width: {xs: 250, sm: 700},
  p: 4,
	maxHeight: "80%",
	overflow: "scroll"
};

const fullBoxStyle = {
	...boxStyle,
	top: 0,
	left: 0,
	transform: "none",
	width: "100%",
	maxHeight: "100%",
	height: "100%",
	boxSizing: "border-box"
}

interface ModalBaseProps {
	open: boolean;
	handleClose: () => void;
	children: React.ReactNode;
	fullWidth?: boolean;
}

const ModalBase:React.FC<ModalBaseProps> = ({open, handleClose, children, fullWidth}) =>{
	return(
		<Modal
				open={open}
				onClose={handleClose}
			>
				<Box sx={fullWidth ? fullBoxStyle : boxStyle}>
					{children}
				</Box>
			</Modal>
	);
};

export default ModalBase;