import React from "react";
import {Container} from "@mui/material";

interface BaseLayoutProps{
	children: React.ReactNode;
}
const BaseLayout: React.FC<BaseLayoutProps> = ({children}) => {
	return (
		<Container sx={{width: {xs: "100%", sm: "90%", md: "70%"}, p:{xs:0}, margin: "0 auto", mt: 5}}>
			{children}
		</Container>
	)
}

export default BaseLayout;