import React from 'react'
import {
  Box,
  Button,
  Paper,
  Stack, Table, TableBody,
  TableContainer, TableHead,
} from '@mui/material'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import DeleteIcon from '@mui/icons-material/Delete'
import {CompanySettingsStyledTableCell, CompanySettingsStyledTableRow} from './TableStyles'



export type CompanySettingsTableDataT = {[key:string]: any} & { id?: number }

type ArrayKeyT<T> = {
    [P in keyof T]: T[P] extends Array<infer U> | undefined ? [P, keyof U] : never;
}[keyof T];

export type LabelT<T> = {name: string, keyName: keyof T | ArrayKeyT<T>}

export interface CompanySettingsTableProps<T extends CompanySettingsTableDataT, K extends LabelT<T>[]> {
	children: React.ReactNode
	labels: K
	data: T[]
	handleSubmit: () => void
	handleEdit: (data: T) => void
	handleDelete: (data: T) => void
}

export const CompanySettingsTable = <T extends CompanySettingsTableDataT, K extends LabelT<T>[]>(props: CompanySettingsTableProps<T, K>):React.ReactElement => {
	const {children, handleSubmit, labels, data, handleDelete, handleEdit} = props;

	return(
		<Box>
			<Stack
				spacing={1}
				direction={{xs: "column", sm:"row"}}
				sx={{
					justifyContent: {sm: "end"},
					alignItems: "center",
					mb: 1
				}}
			>
				{children}
				<Button
					size="small"
					type="button"
					variant="contained"
					onClick={handleSubmit}
					sx={{
						width: {xs: "100%", sm:"auto"}
					}}
				>
					追加
				</Button>
			</Stack>
			<TableContainer component={Paper} sx={{width: "100%", my: 1, overflow: "scroll", maxHeight: 300, minHeight: 300}}>
				<Table stickyHeader>
					<TableHead>
						<CompanySettingsStyledTableRow>
							{
								labels.map((value, index) => (
									<CompanySettingsStyledTableCell key={index}>{value.name}</CompanySettingsStyledTableCell>
								))
							}
							<CompanySettingsStyledTableCell></CompanySettingsStyledTableCell>
						</CompanySettingsStyledTableRow>
					</TableHead>
					<TableBody>
						{
							data.map((val) => {
								return (
									<CompanySettingsStyledTableRow key={val.id}>
										{
											labels.map((value, index) => {
												if (typeof value.keyName === "string"){
													return (
														<CompanySettingsStyledTableCell key={index}>{val[value.keyName]}</CompanySettingsStyledTableCell>
													)
												} else if (Array.isArray(value.keyName) && value.keyName.length >= 2){
													const keyNames = value.keyName as ArrayKeyT<T>;
													const key = keyNames[0];
													const key2 = keyNames[1];
													return (
														<CompanySettingsStyledTableCell key={index}>{val[key] && Array.isArray(val[key]) && (val[key] as Array<T[ArrayKeyT<T>[0]]>).map((item, index) => (
															<React.Fragment key={index}>{item[key2]} <br/></React.Fragment>
														))}
														</CompanySettingsStyledTableCell>
													)
												}
												return <React.Fragment key={index} />
											})
										}
										<CompanySettingsStyledTableCell
											sx={{
												position: "sticky",
												right: 0,
												padding: 0,
												background: "inherit",
												boxShadow: "inset 1px 0 0 #828282",
												textAlign: "right",
												width: "10%"
											}}
										>
											<Button
												type="button"
												variant="text"
												color="third"
												onClick={()=>handleEdit(val)}
												startIcon={
													<HistoryEduIcon />
												}
											>
												編集
											</Button>
											<Button
												type="button"
												variant="text"
												color="error"
												onClick={()=>handleDelete(val)}
												startIcon={
													<DeleteIcon />
												}
											>
												削除
											</Button>
										</CompanySettingsStyledTableCell>
									</ CompanySettingsStyledTableRow>
									)
								})
							}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
}