export type AttendanceManagementUser = {
  id: number;
	total_workhours: string;
	total_workdays: number;
	without_overworking: string;
	total_overworks: string;
	holiday_workhours: string;
	total_late_hours: string;
	absents: number;
	corporate_title_name: string;
	department_name: string;
	email: string;
	employee_no: string;
	first_name: string;
	full_name: string;
	group: number;
	hire_dt: string;
	last_name: string;
}

export const attendanceManagementTableHeader = [
	{label:'氏名', key:'full_name'},
	{label:'従業員番号', key:'employee_no'},
	{label:'役職', key:'corporate_title'},
	{label:'部署', key:'department'},
	{label:'労働日数', key:'total_workdays'},
	{label:'総勤務時間', key:'total_workhours'},
	{label:'法定内労働', key:'without_overworking'},
	{label:'時間外労働', key:'total_overworks'},
	{label:'休日労働', key:'holiday_workhours'},
	{label:'欠勤日数', key:'absents'},
	{label:'遅刻早退時間', key:'total_late_hours'},
]

export type AttendanceManagementTablePaginationType = {
	count: number
	results: AttendanceManagementUser[]
}