export const userDescription = [
	"ユーザー一覧",
	"ユーザー情報変更",
];

export const companyDescription = [
	"役職管理",
	"部署",
	"グループ管理",
	"承認者設定",
	"振替休日付与設定",
	"休校日設定",
];