import {
  Navigate,
  useLocation,
} from 'react-router-dom';
import React from "react";
import {useAuth} from "../hooks/useAuth";

const ProtectedRoute:React.FC<{children:JSX.Element}> = ({ children }) => {
  const { auth } = useAuth();
  const location = useLocation();
  if (!auth.access) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};

export default ProtectedRoute;