import React from 'react'
import {NewPTOCalendarModalOpenType} from '../../../store/users'
import {ModalLayout} from '../../../components'
import {Button, Stack, TextField} from '@mui/material'

interface NewPTOProps {
  handlePTOCalendarModalClose: () => void
  handlePTOCalendarModalChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handlePTOCalendarModalSave: () => void
  newPTOCalendarModal: NewPTOCalendarModalOpenType
}

export const NewPTO: React.FC<NewPTOProps> = (props) => {
  const {
    handlePTOCalendarModalClose,
    handlePTOCalendarModalChange,
    handlePTOCalendarModalSave,
    newPTOCalendarModal,
  } = props

  return (
    <ModalLayout title="追加">
        <Stack direction="row" spacing={2}>
          <TextField
            size="small"
            name="year"
            label="年"
            type="number"
            value={newPTOCalendarModal.data?.year || ''}
            onChange={handlePTOCalendarModalChange}
            error={newPTOCalendarModal.error?.year && true}
            helperText={newPTOCalendarModal.error?.year ? newPTOCalendarModal.error?.year.map((val: string) => val).join(', ') : ''}
          />
          <TextField
            size="small"
            name="hours"
            type="number"
            label="日数"
            value={newPTOCalendarModal.data?.hours || ''}
            onChange={handlePTOCalendarModalChange}
            error={newPTOCalendarModal.error?.hours && true}
            helperText={newPTOCalendarModal.error?.hours ? newPTOCalendarModal.error?.hours.map((val: string) => val).join(', ') : ''}
            inputProps={{
              step: 0.5,
            }}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Button
            type="button"
            variant="contained"
            onClick={handlePTOCalendarModalSave}
          >
            保存
          </Button>
          <Button
            type="button"
            variant="contained"
            color="inherit"
            onClick={handlePTOCalendarModalClose}
          >
            閉じる
          </Button>
        </Stack>
    </ModalLayout>
  )
}