import React from 'react'
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material'
import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import DeleteIcon from '@mui/icons-material/Delete'
import {CompanySettingsCollapseLabelsT, CompanySettingsCollapseTableDataT} from './CompanySettingsCollapseTable'
import {CompanySettingsStyledTableCell, CompanySettingsStyledTableRow} from './TableStyles'
import {CompanySettingsTableDataT} from './CompanySettingsTable'

interface CompanySettingsCollapseTableRowP<K extends CompanySettingsTableDataT, T extends CompanySettingsCollapseTableDataT<K>> {
	row: T;
	labels: CompanySettingsCollapseLabelsT
	handleEdit: (data: K) => void;
	handleDelete: (data: K) => void;
}

export const CompanySettingsCollapseTableRow = <K extends CompanySettingsTableDataT, T extends CompanySettingsCollapseTableDataT<K>> (props: CompanySettingsCollapseTableRowP<K, T>) => {
  const { row, labels, handleDelete, handleEdit } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <CompanySettingsStyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <CompanySettingsStyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </CompanySettingsStyledTableCell>
	      {labels.main.map((value, index) =>(
					<CompanySettingsStyledTableCell key={index}>
						{row[value.keyName]}
					</CompanySettingsStyledTableCell>
	      ))}
      </CompanySettingsStyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
	              {labels.collapseTitle}
              </Typography>
              <Table size="small" aria-label="purchases" sx={{width:"100%", overflow: "scroll"}}>
                <TableHead>
                  <CompanySettingsStyledTableRow>
	                  {
											labels.collapse.map((value, index) =>(
												<CompanySettingsStyledTableCell key={index}>{value.name}</CompanySettingsStyledTableCell>
											))
	                  }
										<CompanySettingsStyledTableCell />
                  </CompanySettingsStyledTableRow>
                </TableHead>
                <TableBody>
                  {row.data?.map((colRow, index) => (
                    <CompanySettingsStyledTableRow key={index}>
	                    {
												labels.collapse.map((val, index) => (
													<CompanySettingsStyledTableCell key={index}>{colRow[val.keyName]}</CompanySettingsStyledTableCell>
												))
	                    }
											<CompanySettingsStyledTableCell
											sx={{
												position: "sticky",
												right: 0,
												padding: 0,
												background: "#faf8f7",
												boxShadow: "inset 1px 0 0 #828282",
												textAlign: "right",
												width: "10%"
											}}
										>
											<Button
												type="button"
												variant="text"
												color="third"
												onClick={()=>handleEdit(colRow)}
												startIcon={
													<HistoryEduIcon />
												}
											>
												編集
											</Button>
											<Button
												type="button"
												variant="text"
												color="error"
												onClick={()=>handleDelete(colRow)}
												startIcon={
													<DeleteIcon />
												}
											>
												削除
											</Button>
										</CompanySettingsStyledTableCell>
                    </CompanySettingsStyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}