import React from 'react'
import {Box, Divider, Stack, Typography} from '@mui/material'

interface ModalLayoutProps {
	title: string;
	children: React.ReactNode;
}

const ModalLayout:React.FC<ModalLayoutProps> = ({title, children}) => {
	return (
		<Box>
			<Stack
				direction="column"
				divider={<Divider orientation="horizontal" flexItem/>}
				spacing={2}
			>
				<Typography variant="h4" component="h4">{title}</Typography>
				{children}
			</Stack>
		</Box>
	);
};

export default ModalLayout;