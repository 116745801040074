import React from "react";
import {CrudT, CRUTDataType, VacationApproverDetail, vacationApproverLabels} from '../../../store/company'
import {MenuItem, Select, SelectChangeEvent, TextField, Theme, Typography, useTheme} from '@mui/material'
import {MinimalUserDetail} from '../../../store/users'
import {DeleteForm} from './DeleteForm'
import {ModalBase, ModalLayout, ModalTable} from '../../../components'
import {EditForm} from './EditForm'
import {CompanySettingsTable} from './Table/CompanySettingsTable'

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

interface VacationApproverProps {
  states: VacationApproverDetail[];
  users: MinimalUserDetail[];
	handleSubmit: () => void;
	handleEdit: (id: number) => void;
	handleDelete: (id: number) => void;
	state?: VacationApproverDetail;
	crud: CrudT<CRUTDataType>;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangeSelect: (event: SelectChangeEvent<string[]>) => void;
  handleMultipleSelectCRUDChange: (event: SelectChangeEvent<string[]>) => void;
	errors?: {[key:string]: any};
	handleOpen: (data: VacationApproverDetail, type: "edit" | "delete") => void;
	handleClose: () => void;
	handleCRUDChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const VacationApprover: React.FC<VacationApproverProps> = (props) => {
  const {
    states,
    users,
    handleSubmit,
    state,
    handleChange,
    handleChangeSelect,
    handleMultipleSelectCRUDChange,
    errors,
    crud,
    handleEdit,
    handleDelete,
    handleOpen,
    handleClose,
    handleCRUDChange,
  } = props;

  const theme = useTheme();

  return (
    <CompanySettingsTable
			labels={vacationApproverLabels}
			data={states}
			handleSubmit={handleSubmit}
			handleEdit={(data) => {handleOpen(data, "edit")}}
			handleDelete={(data) => {handleOpen(data, "delete")}}
		>
			<Typography>承認者ルールを追加：</Typography>
			<TextField
				size="small"
				placeholder="承認者を選択"
				name="user"
				value={state?.user || ''}
				onChange={handleChange}
				error={errors?.user && true}
				helperText={errors?.user ?
          errors.user?.map((val: string) => val).join(', ') : ""}
				sx={{
						width: {xs: "100%", sm:"auto"},
						'& .MuiSelect-select .notranslate::after': "承認者を選択"
            ? {
                content: `"承認者を選択"`,
                opacity: 0.42,
              }
            : {},
					}}
				select
			>
				<MenuItem value="" sx={{opacity:0.42}}>承認者を選択</MenuItem>
				{
					users?.map((value) => (
						<MenuItem key={value.id} value={value.id}>{value.full_name}</MenuItem>
					))
				}
			</TextField>
			<Select
				size="small"
				placeholder="個人を選択"
				name="users"
				multiple
				value={state?.users || []}
				onChange={handleChangeSelect}
				error={errors?.users && true}
				sx={{
					width: {xs: "100%", sm: 200},
					'& .MuiSelect-select .notranslate::after': "個人を選択"
            ? {
                content: `"個人を選択"`,
                opacity: 0.42,
              }
            : {},
				}}
			>
				{
					users?.map((value) => (
						<MenuItem key={value.id} value={value.id} style={getStyles(value.id.toString(), state?.users || [], theme)}>{value.full_name}</MenuItem>
					))
				}
			</Select>
			<ModalBase open={crud.open && crud.crudType === 'vacationApprovers'} handleClose={handleClose}>
				<ModalLayout title={ crud.type === "delete" ? "削除" : "編集"}>
					{
						crud.type === "delete"
							?
							<DeleteForm id={crud?.data?.id || 0} handleDelete={handleDelete} handleClose={handleClose}>
								<ModalTable>
									<caption>承認者の削除</caption>
									<tbody>
									<tr>
										<th>承認者</th>
										<td>
											{crud.data?.full_name}
										</td>
									</tr>
									<tr>
										<th>個人</th>
										<td>
											{crud.data?.users_display?.map((item, index) => (
												<React.Fragment key={index}>
													{item.full_name}<br />
												</React.Fragment>
											))}
										</td>
									</tr>
									</tbody>
								</ModalTable>
							</DeleteForm>
							:
							<EditForm handleEdit={handleEdit} handleClose={handleClose} id={crud?.data?.id || 0}>
								<ModalTable>
									<caption>承認者の編集</caption>
									<tbody>
										<tr>
											<th>承認者</th>
											<td>
												<TextField
													size="small"
													placeholder="承認者を選択"
													name="user"
													value={crud?.data?.user || ''}
													onChange={handleCRUDChange}
													error={crud.error?.user && true}
													helperText={crud.error?.user ?
									          crud.error?.user?.map((val: string) => val).join(', ') : ""}
													sx={{
															width: {xs: "100%", sm:"auto"},
															'& .MuiSelect-select .notranslate::after': "承認者を選択"
									            ? {
									                content: `"承認者を選択"`,
									                opacity: 0.42,
									              }
									            : {},
														}}
													select
												>
													<MenuItem value="" sx={{opacity:0.42}}>承認者を選択</MenuItem>
													{
														users?.map((value) => (
															<MenuItem key={value.id} value={value.id}>{value.full_name}</MenuItem>
														))
													}
												</TextField>
											</td>
										</tr>
										<tr>
											<th>個人</th>
											<td>
												<Select
													size="small"
													placeholder="個人を選択"
													name="users"
													multiple
													value={crud?.data?.users || []}
													onChange={handleMultipleSelectCRUDChange}
													error={crud?.error?.users && true}
													sx={{
														width: {xs: "100%", sm: 200},
														'& .MuiSelect-select .notranslate::after': "個人を選択"
									            ? {
									                content: `"個人を選択"`,
									                opacity: 0.42,
									              }
									            : {},
													}}
												>
													{
														users?.map((value) => (
															<MenuItem key={value.id} value={value.id} style={getStyles(value.id.toString(), state?.users || [], theme)}>{value.full_name}</MenuItem>
														))
													}
												</Select>
											</td>
										</tr>
									</tbody>
								</ModalTable>
							</EditForm>
					}
				</ModalLayout>
			</ModalBase>
		</CompanySettingsTable>
  );
}

