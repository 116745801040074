import React from 'react'
import {AuthUser} from '../../../store/auth'
import {Box, Button, Grid, MenuItem, Stack, TextField, Typography} from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {PTORequestApplication} from '../../../store/pto'
import {VacationApproverT, VacationType} from '../../../store/company'
import {optionVacationDisabled} from '../../../utils/pto'

interface PTOApplicationFormProps {
	handleChange: (event:React.ChangeEvent<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement>) => void;
	handleSubmit: () => void;
	state: PTORequestApplication;
	errors: {[key:string]: any};
	vacations: VacationType[];
	users:VacationApproverT[];
	user: AuthUser;
}

export const PTOApplicationForm: React.FC<PTOApplicationFormProps> = (props) => {
	const {handleChange, errors, handleSubmit, state, vacations, users, user} = props;

	return (
		<Grid container spacing={1}>
			<Grid item xs={12} sm={8}>
				<Box>
					<Typography component="label">休暇希望日</Typography>
					<Stack divider={<>〜</>} direction="row" alignItems="center">
						<TextField
							type="date"
							size="small"
							name="start"
							value={state.start}
							onChange={handleChange}
							error={errors.start && true}
							helperText={errors?.start?.map((val:string) => val).join(", ")}
						/>
						<TextField
							type="date"
							size="small"
							name="end"
							value={state.end}
							onChange={handleChange}
							error={errors.end && true}
							helperText={errors?.end?.map((val:string) => val).join(", ")}
						/>
					</Stack>
				</Box>
			</Grid>
			<Grid item xs={12} sm={4}>
				<Box>
					<Typography component="label" display="block">申請項目</Typography>
					<TextField
						select
						size="small"
						name="vacation_type"
						value={state.vacation_type}
						onChange={handleChange}
						error={errors.vacation_type && true}
						helperText={errors?.vacation_type?.map((val:string) => val).join(", ")}
						sx={{
							width: {xs: "100%",lg:"100%", sm:"auto"},
							'& .MuiSelect-select .notranslate::after': "ーーーーーーー"
		            ? {
		                content: `"ーーーーーーー"`,
		                opacity: 0.42,
		              }
		            : {},
						}}
					>
						{
							vacations.map((obj) => (
								<MenuItem key={obj.id} value={obj.id} disabled={optionVacationDisabled(obj, user)}>{obj.name}</MenuItem>
							))
						}
					</TextField>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box>
					<Typography component="label" display="block">承認者</Typography>
					<TextField
						select
						name="approver"
						size="small"
						value={state.approver}
						onChange={handleChange}
						error={errors.approver && true}
						helperText={errors?.approver?.map((val:string) => val).join(", ")}
						sx={{
							width: {xs: "100%", sm:"50%"},
							'& .MuiSelect-select .notranslate::after': "ーーーーーーー"
		            ? {
		                content: `"ーーーーーーー"`,
		                opacity: 0.42,
		              }
		            : {},
						}}
					>
						{
							users.map((obj) => (
								<MenuItem key={obj.id} value={obj.id}>{obj.full_name}</MenuItem>
							))
						}
					</TextField>
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Box>
					<Typography component="label" display="block">申請備考</Typography>
					<TextField
						fullWidth
						size="small"
            multiline
            rows="5"
						name="note"
						autoComplete='off'
						value={state.note}
						onChange={handleChange}
						error={errors.note && true}
						helperText={errors?.note?.map((val:string) => val).join(", ")}
					/>
				</Box>
			</Grid>
			<Grid item xs={12} textAlign={{sm:"right"}}>
				<Button onClick={handleSubmit} variant="contained" sx={{color:"primary.light", width: {xs: "100%", sm: "auto"}}} type="button" endIcon={<KeyboardArrowRightIcon />}>申請する</Button>
			</Grid>
		</Grid>
	);
};