import {LoadingContext} from '../contexts/loadingContext'
import {useCallback, useContext} from 'react'
import {DECREMENT_LOADING, INCREMENT_LOADING} from '../store/constants'

export const useLoading = () => {
  if(!LoadingContext) {
    throw new Error('useLoading must be used within a LoadingProvider')
  }
  const {dispatch} = useContext(LoadingContext)

  const startLoading = useCallback(() => {
    dispatch({type: INCREMENT_LOADING})
  }, [dispatch])

  const stopLoading = useCallback(() => {
    dispatch({type: DECREMENT_LOADING})
  }, [dispatch])

  return {startLoading, stopLoading}
}