import {useLocation, useNavigate} from 'react-router-dom'
import useAuthAxios from '../../../hooks/useAuthAxios'
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {getTab} from '../../../utils/style'
import {
  defaultUserSearch, NewPTOCalendarModalOpenType,
  UserDetail,
  UserModalOpenType, UserPTOCalendarEditModalType,
  UserPTOCalendarModalOpenType,
  UserSearchType,
  UserSettingsState,
  UserTablePaginationType,
} from '../../../store/users'
import {useAlertMessage} from '../../../hooks/useAlertMessage'
import {useFileDownload} from '../../../hooks/useFileDownload'

export const useUserSettingsLayout = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const axios = useAuthAxios()
  const [states, setStates] = useState<UserSettingsState>({
    departments: [],
    corporateTitles: [],
    groups: [],
  })

  const [users, setUsers] = useState<UserTablePaginationType>({
    count: 0,
    results: [],
  })
  const [search, setSearch] = useState<UserSearchType>(defaultUserSearch)
  const [tabValue, setTabValue] = useState<number>(-1)
  const [modalOpen, setModalOpen] = useState<UserModalOpenType>({open: false})
  const [ptoCalendarModalOpen, setPtoCalendarModalOpen] = useState<UserPTOCalendarModalOpenType>({open: false, ptoCalendars: []})
  const [ptoCalendarEditModal, setPtoCalendarEditModal] = useState<UserPTOCalendarEditModalType>({open: false, data: null})
  const [newPtoCalendarModalOpen, setNewPtoCalendarModalOpen] = useState<NewPTOCalendarModalOpenType>({open: false, data: null, error: null})
  const query = useMemo(() => new URLSearchParams(location.search), [location.search])
  const page = useMemo(() => parseInt(query.get('page') || '1', 10), [query])
  const isRendered = useRef<boolean>(false)
  const {setAlertMessage} = useAlertMessage()
  const {handleDownload} = useFileDownload()

  const searchUser = useCallback((s: string, t: number) => {
    if (isRendered.current) {
      const groupQuery = t >= 0 && t < states.groups.length ? `group=${states.groups[t].id}` : 'group__isnull=True'
      axios.get(
        `/user/?${groupQuery}&page=${page}&search=${s}&departments=${search.department}&corporate_title=${search.corporateTitle}`,
      )
        .then((res) => {
          setUsers(res.data)
        })
    }
  }, [axios, search.department, search.corporateTitle, page, states.groups])


  useEffect(() => {
    if (!isRendered.current) {
      const fetchData = async () => {
        try {
          const [
            groupRes,
            departmentRes,
            corporateTitleRes,
          ] = await Promise.all([
            axios.get('/companies/group/'),
            axios.get('/companies/department/'),
            axios.get('/companies/corporate-title/'),
          ])
          setStates((prevState) => ({
            ...prevState,
            groups: groupRes.data,
            departments: departmentRes.data,
            corporateTitles: corporateTitleRes.data,
          }))
        } catch (error) {
          console.log(error)
        } finally {
          isRendered.current = true
        }
      }

      (async () => {
        await fetchData()
      })()
    }

    return () => {
      isRendered.current = false
    }

  }, [axios])

  useEffect(() => {
    if (tabValue >= 0) {
      searchUser(search.search, tabValue)
    }
  }, [searchUser, tabValue, search.search])

  useEffect(() => {
    if (location.hash.slice(1) && isRendered.current) {
      setTabValue(getTab(location.hash, states.groups))
    } else if (isRendered.current) {
      setTabValue(0)
    }
  }, [location.hash, states.groups])

  useEffect(() => {
    if (ptoCalendarEditModal.data?.prev_total !== null && ptoCalendarEditModal.data?.current_total !== null) {
      setPtoCalendarEditModal((prevState) => {
        if (!prevState.data) {
          return prevState
        }
        const rem = (ptoCalendarEditModal.data?.prev_total || 0) + (ptoCalendarEditModal.data?.current_total || 0) - prevState.data.used_days_hours_display - (prevState.data.used_2_hours_display > 0 ? 1 : 0)
        return {
          ...prevState,
          data: {
            ...prevState.data,
            remaining_pto_days: rem < 0 ? 0 : rem,
            total_usable_pto: (ptoCalendarEditModal.data?.prev_total || 0) + (ptoCalendarEditModal.data?.current_total || 0) ,
          },
        }
      })
    }
  }, [ptoCalendarEditModal.data?.prev_total, ptoCalendarEditModal.data?.current_total])

  const handleTabChange = (_event: React.SyntheticEvent, newIndex: number) => {
    if (newIndex === states.groups.length) {
      navigate(`#0`, {replace: false})
    } else {
      navigate(`#${states.groups[newIndex].id}`, {replace: false})
    }
  }

  const handleSearchSubmit = (event: React.FormEvent) => {
    event.preventDefault()
    searchUser(search.search , tabValue)
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const {name, value} = event.target
    setSearch((prevState) => ({...prevState, [name]: value}))
  }

  const handleUserChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const {name, value, type} = event.target
    const checked = type === 'checkbox' ? (event.target as HTMLInputElement).checked : false

    setModalOpen((prevState) => {
      if (!prevState.user) {
        const newData = {[name]: type === 'checkbox' ? checked : value} as Partial<UserDetail>
        return {...prevState, user: newData as UserDetail}
      }
      return {
        ...prevState,
        user: {
          ...prevState.user,
          [name]: type === 'checkbox' ? checked : value,
        },
      }
    })
  }

  const handleModalOpen = (id: number) => {
    const user = users.results.find((user) => user.id === id)
    setModalOpen({open: true, user: user})
  }

  const handleModalClose = () => {
    setModalOpen({open: false})
  }

  const handleUserSave = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    axios.put(`/user/${modalOpen.user?.id}/`, modalOpen.user)
      .then((res) => {
        setUsers((prevState) => ({
          ...prevState,
          results: prevState.results.map((user) => {
            if (user.id === modalOpen.user?.id) {
              return res.data
            }
            return user
          }).filter((user) => user.id === modalOpen.user?.id ? states.groups[tabValue]?.id === res.data.group : true),
        }))
        handleModalClose()
        setAlertMessage({status: 'success', open: true, message: 'ユーザー情報を更新しました'})
      })
  }

  const handlePTOCalendarModalOpen = () => {
    if (modalOpen.open && modalOpen.user) {
      axios.get(`/pto/calendar/${modalOpen.user.id}/`)
        .then((res) => {
          setPtoCalendarModalOpen({open: true, ptoCalendars: res.data})
        })
    }
  }

  const handlePTOCalendarModalClose = () => {
    setPtoCalendarModalOpen({open: false, ptoCalendars: []})
  }

  const handlePTOCalendarDownload = (start: string) => {
    if (modalOpen.user) {
      const year = start.split('-')[0]
      handleDownload(`pto/get/pto-detail/${year}/${modalOpen.user.id}/?download=true`, 'empty.xlsx')
    }
  }

  const handlePTOCalendarEditModalOpen = (start: string) => {
    if (modalOpen.open && modalOpen.user) {
      const year = start.split('-')[0]
      axios.get(`/pto/get/pto-detail/${year}/${modalOpen.user.id}/`)
        .then((res) => {
          setPtoCalendarEditModal({open: true, data: res.data})
        })
    }
  }

  const handlePTOCalendarEditModalClose = () => {
    setPtoCalendarEditModal({open: false, data: null})
  }

  const handlePTOCalendarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (ptoCalendarEditModal.data) {
      const {name, value} = event.target
      setPtoCalendarEditModal((prevState) => {
        if (!prevState.data) {
          return prevState
        }
        return {
          ...prevState,
          data: {
            ...prevState.data,
            [name]: Number.isNaN(parseFloat(value)) ? value : parseFloat(value),
          },
        }
      })
    }
  }

  const handlePTOCalendarEditSave = () => {
    if (modalOpen.user && ptoCalendarEditModal.data) {
      const year = ptoCalendarEditModal.data.start.split('-')[0]
      const data = {...ptoCalendarEditModal.data}
      if (!data.prev_total) {
        data.prev_total = 0
      }
      if (!data.current_total) {
        data.current_total = 0
      }
      axios.post(`/pto/get/pto-detail/${year}/${modalOpen.user.id}/`, data)
        .then(() => {
          setAlertMessage({status: 'success', open: true, message: '有給情報を更新しました'})
          handlePTOCalendarEditModalClose()
        })
        .catch(() => {
          setAlertMessage({status: 'error', open: true, message: 'エラーが発生しました'})
        })
    }
  }

  const handleNewPTOCalendarModalOpen = () => {
    setNewPtoCalendarModalOpen({open: true, data: {year: 0, hours: 0}, error: null})
  }

  const handleNewPTOCalendarModalClose = () => {
    setNewPtoCalendarModalOpen({open: false, data: null, error: null})
  }

  const handleNewPTOCalendarChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (newPtoCalendarModalOpen.open) {
      const {name, value} = event.target
      setNewPtoCalendarModalOpen((prevState) => {
        if (!prevState.data) {
          return prevState
        }
        return {
          ...prevState,
          data: {
            ...prevState.data,
            [name]: Number.isNaN(parseFloat(value)) ? value : parseFloat(value),
          },
        }
      })
    }
  }

  const handleNewPTOCalendarSave = () => {
    if (modalOpen.user && newPtoCalendarModalOpen.data) {
      const data = {...newPtoCalendarModalOpen.data}
      data.hours = data.hours * 8
      axios.post(`/pto/calendar/${modalOpen.user.id}/`, data)
        .then((res) => {
          setAlertMessage({status: 'success', open: true, message: '有給を追加しました'})
          setPtoCalendarModalOpen((prevState) => {
            if (!prevState.open) {
              return prevState
            }
            return {
              ...prevState,
              ptoCalendars: [...prevState.ptoCalendars, res.data],
            }
          })
          handleNewPTOCalendarModalClose()
        })
        .catch((err) => {
          setNewPtoCalendarModalOpen((prevState) => ({...prevState, error: err.response.data}))
          setAlertMessage({status: 'error', open: true, message: 'エラーが発生しました'})
        })
    }
  }

  return {
    users,
    states,
    tabValue,
    handleTabChange,
    search,
    handleSearchSubmit,
    handleSearchChange,
    modalOpen,
    handleModalOpen,
    handleModalClose,
    location,
    handleUserSave,
    handleUserChange,
    page,
    handlePTOCalendarModalClose,
    ptoCalendarModalOpen,
    handlePTOCalendarModalOpen,
    handlePTOCalendarDownload,
    handlePTOCalendarEditModalClose,
    handlePTOCalendarEditModalOpen,
    ptoCalendarEditModal,
    handlePTOCalendarChange,
    handlePTOCalendarEditSave,
    handleNewPTOCalendarSave,
    handleNewPTOCalendarModalClose,
    handleNewPTOCalendarModalOpen,
    newPtoCalendarModalOpen,
    handleNewPTOCalendarChange,
  }
}