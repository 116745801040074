export const webSocketURL = process.env.REACT_APP_WS_URL;
export const apiURL = process.env.REACT_APP_AXIOS_URL;

export const csvButtonStyle = {
  borderColor:'#54a2fa',
	borderWidth: 1,
	borderStyle: 'solid',
	marginBottom:'0px',
	textDecoration: 'none',
  	fontSize: 14,
	alignItems: 'center',
	display: 'grid',
	padding: '5px 15px',
	borderRadius: 5,
	color: '#0c0c0c',
}