import {useLocation, useNavigate, useParams} from 'react-router-dom'
import useAuthAxios from '../../../hooks/useAuthAxios'
import React, {useEffect, useState} from 'react'
import {CompanyGroup} from '../../../store/company'

const getTab = (hash:string, data: any[]) =>{
		const id = parseInt(hash.slice(1));
		return id ? data.findIndex(obj=> obj.id === id) : 0
}

export const useAttendanceManagementLayout = () => {
  const {year, month} = useParams();
	const navigate = useNavigate();
  const location = useLocation();
	const axios = useAuthAxios();
	const [payrollCalendar, setPayrollCalendar] = useState<any>()
	const [group, setGroup] = React.useState<CompanyGroup[]>([]);
	const [value, setValue] = React.useState(0);

	useEffect(()=>{
		axios.get("/companies/group/")
			.then((res) => {setGroup(res.data)})
		  .catch((err) => console.log(err))
	},[axios])

	useEffect(() => {
		setValue(getTab(location.hash, group));
  }, [location.hash, group]);

	useEffect(()=>{
		axios.get(`/companies/payroll/calendar/${year}/${month}/`)
			.then((res)=>{setPayrollCalendar(res.data)})
		  .catch((err) => console.log(err))
	},[axios, year, month])

	const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    navigate(`#${group[newValue].id}`, {replace: false});
  };

  return {
    payrollCalendar,
    value,
    handleChange,
    group
  }
}