import {Grid} from '@mui/material'
import React from 'react'
import PTOApplication from './components/PTOApplication'
import {usePTOApplication} from './hooks/usePTOApplication'
import {SentApplication} from '../../components'
import {BaseLayout} from '../index'

const PTOApplicationLayout: React.FC = () => {
	const {
		handleValidation,
		handleChange,
		handleSubmit,
		state,
		errors,
		vacations,
		users,
		open,
		setOpen,
		pto
	} = usePTOApplication()
  return (
    <BaseLayout>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<PTOApplication
						handleChange={handleChange}
						handleSubmit={handleSubmit}
						state={state}
						errors={errors}
						vacations={vacations}
						users={users}
						pto={pto}
						open={open}
						setOpen={setOpen}
						handleValidation={handleValidation}
					/>
				</Grid>
				<Grid item xs={12} md={5}>
					<SentApplication />
				</Grid>
			</Grid>
		</BaseLayout>
  )
}

export default PTOApplicationLayout