import React from "react";
import {BaseLayout} from '../index'
import {CalendarPagination} from '../../components'
import {Box, Paper, Tab, Tabs, Typography} from '@mui/material'
import {useAttendanceManagementLayout} from './hooks/useAttendanceManagementLayout'
import {TabPanel} from './components/TabPanel'

function a11yProps(index: number) {
  return {
    id: `attendance-group-tab-${index}`,
    'aria-controls': `attendance-group-tab-panel-${index}`,
  };
}

const AttendanceManagementLayout: React.FC = () => {
	const {
		value,
		group,
		handleChange,
		payrollCalendar
	} = useAttendanceManagementLayout()

  return (
    <BaseLayout>
      <CalendarPagination  payrollCalendar={payrollCalendar}/>
			<Typography mt={3} display={{xs:"none", sm:"block"}}>{payrollCalendar?.payroll_date}払い （{payrollCalendar?.start_date}〜{payrollCalendar?.end_date} 勤務分）</Typography>
			<Paper elevation={2}>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={value < 0 ? 0 : value} onChange={handleChange} aria-label="attendance-group-tab">
							{
								group.map((obj, index) => (
									<Tab key={obj.id} label={obj.name} {...a11yProps(index)} />
								))
							}
		        </Tabs>
					</Box>
					{
						group.map((obj, index) => (
							<TabPanel
								value={value}
								index={index}
								dataID={obj.id || 0}
								key={obj.id}
							>{obj.name}</TabPanel>
						))
					}
				</Box>
			</Paper>
    </BaseLayout>
  );
}

export default AttendanceManagementLayout;