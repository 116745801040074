import React from 'react'
import {Button, Stack, TextField} from '@mui/material'
import {useReceivedRestApplication} from './useReceivedRestApplication'
import {ModalTable, ModalLayout} from '../../index'


interface SentRestApplicationT {
  id: number;
  handleClose: () => void;
}

export const ReceivedRestApplication: React.FC<SentRestApplicationT> = ({handleClose, id}) => {
  const {
    state,
    errors,
    handleSubmit,
    handleDrop,
    handleChange,
  } = useReceivedRestApplication(id, handleClose)

  return (
    <ModalLayout title="休暇申請履歴">
      <ModalTable>
        <caption>申請日: {state.created_dt}</caption>
        <tr>
          <th>ステータス</th>
          <td>
            {state.status_display}
          </td>
        </tr>
        <tr>
          <th>申請項目</th>
          <td>
            {state.vacation_type_display}
          </td>
        </tr>
        <tr>
          <th>休暇希望日</th>
          <td>{state.start_display}{state.end_display && `〜${state.end_display}`}</td>
        </tr>
        <tr>
          <th>承認者</th>
          <td>{state.approver_display}</td>
        </tr>
        <tr>
          <th>申請備考</th>
          <td>{state.note}</td>
        </tr>
        <tr>
          <th>コメント</th>
          <td>
            <TextField
              fullWidth
              size="small"
              multiline
              rows="3"
              name="comment"
              value={state.comment}
              onChange={handleChange}
              error={errors.comment && true}
              disabled={state.status !== 'pending'}
              helperText={errors?.comment?.map((val: string) => val).join(', ')}
            />
          </td>
        </tr>
      </ModalTable>
      <Stack
        direction={{xs: 'row', ms: 'column'}} spacing={3}
      >
        <Button type="button" variant="contained" color="inherit" sx={{flex: 1}} onClick={handleClose}>戻る</Button>
        {
          state.status === 'pending'
          &&
          <>
            <Button type="button" onClick={handleDrop} variant="outlined" color="error" sx={{flex: 1}}>差し戻し</Button>
            <Button type="button" onClick={handleSubmit} variant="outlined" sx={{flex: 1}}>承認</Button>
          </>
        }
      </Stack>
    </ModalLayout>
  )
}