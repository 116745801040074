import React from "react";
import {Button, Stack} from "@mui/material";

interface EditFormProps {
	handleEdit: (id:number) => void;
	handleClose: () => void;
	children: React.ReactNode;
	id: number;
}

export const EditForm: React.FC<EditFormProps> = (props) => {
	const { handleEdit, handleClose, children, id } = props;
	return(
		<>
			<Stack direction="column" spacing={3}>
				{children}
				<Stack
					direction={{xs:"column", sm:"row"}} spacing={3}
				>
					<Button type="button" variant="contained" color="inherit" sx={{flex:1}} onClick={handleClose}>キャンセル</Button>
					<Button type="button" variant="contained" form="memo-form" sx={{flex:1}} onClick={() => handleEdit(id)}>編集</Button>
				</Stack>
			</Stack>
		</>
	)
}