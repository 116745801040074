import React from 'react'
import {Typography} from '@mui/material'

interface RenderTimeProps {
  time: string;
}
export const RenderTime: React.FC<RenderTimeProps> = ({time}) => (
	<React.Fragment>
		{parseInt(time.split(":")[0]) || 0}<Typography component="small">時間</Typography>
		{parseInt(time.split(":")[1]) ? <>{parseInt(time.split(":")[1])}<Typography component="small">分</Typography></> : ""}
	</React.Fragment>
)