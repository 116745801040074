import React from 'react'
import PunchClockIcon from '@mui/icons-material/PunchClock'
import {Box, Button, List, ListItem, Stack, Typography} from '@mui/material'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import CancelIcon from '@mui/icons-material/Cancel'
import {PendingBox} from './PendingBox'
import {TimecardChangePendingApplicationT} from '../../../store/timecard'

interface TimecardChangePendingApplicationProps {
  state: TimecardChangePendingApplicationT[]
  handleSubmit: (id: number) => void
  handleDrop: (id: number) => void
}

export const TimecardChangePendingApplication: React.FC<TimecardChangePendingApplicationProps> = ({
                                                                                                    state,
                                                                                                    handleSubmit,
                                                                                                    handleDrop,
                                                                                                  }) => {

  return (
    <PendingBox
      icon={<PunchClockIcon sx={{fontSize: 60, color: 'secondary.contrastText'}}/>}
      title="打刻時間変更申請一覧"
      count={state.length}
    >
      <List>
        {
          state.map((value, index) => (
            <ListItem key={index} divider disableGutters
                      sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
              <Box>
                <Box mb={0.5}>
                  <Box component="span">申請日:{value?.created_dt}</Box>
                  <Box component="span">申請者:{value?.requester_display}</Box>
                </Box>
                <Box p={0.5}>
                  {
                    value?.old_start_display !== value?.new_start_display
                    &&
                    <>
                      <Typography component="div" fontSize={12} color="primary" fontWeight="normal">
                        変更前 | {value?.date} {value?.old_start_display}
                      </Typography>
                      <Typography component="div" fontSize={12} color="primary" fontWeight="normal">
                        変更後 | {value?.date} {value?.new_start_display}
                      </Typography>
                    </>
                  }
                  {
                    value?.old_end_display !== value?.new_end_display
                    &&
                    <>
                      <Typography component="div" fontSize={12} color="primary" fontWeight="normal">
                        変更前 | {value?.date} {value?.old_end_display}
                      </Typography>
                      <Typography component="div" fontSize={12} color="primary" fontWeight="normal">
                        変更後 | {value?.date} {value?.new_end_display}
                      </Typography>
                    </>
                  }
                </Box>
                <Typography component="span">【変更理由】</Typography>
                {
                  value?.reasons?.start &&
                  <Typography paragraph>{value?.reasons?.start}</Typography>
                }
                {
                  value?.reasons?.end &&
                  <Typography paragraph>{value?.reasons?.end}</Typography>
                }
              </Box>
              <Stack spacing={2}>
                <Button
                  variant="contained"
                  sx={{color: 'primary.light'}}
                  endIcon={<AssignmentTurnedInIcon/>}
                  onClick={() => handleSubmit(value.id)}
                >
                  承認
                </Button>
                <Button
                  variant="outlined"
                  endIcon={<CancelIcon/>}
                  onClick={() => handleDrop(value.id)}
                >
                  差戻
                </Button>
              </Stack>
            </ListItem>
          ))
        }
      </List>
    </PendingBox>
  )
}