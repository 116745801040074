import React from "react";
import {AppBar, Box, Container, Divider, IconButton, Stack, Toolbar, Typography} from "@mui/material";
import logo from '../../assets/cmb-logo.png';
import {Navigation} from "../index";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Profile from "./Profile";
import { Link, Outlet } from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import {ModalBase} from '../index'

const Header:React.FC = () => {
	const { auth } = useAuth();
	const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

	return (
		<>
			<AppBar position="static" sx={{backgroundColor: "primary.light"}}>
				<Container maxWidth="xl" sx={{p:{xs:0}}}>
					<Toolbar variant="dense" disableGutters sx={{display: "flex", justifyContent: "space-between"}}>
						<Box sx={{
							flexGrow: 1,
							display: {sm: "flex", xs: "block"},
							borderRight: "1px solid",
							borderColor: "secondary.contrastText",
							alignItems: "center",
						}}>
							<Link to="/">
								<Box
									component="img"
									sx={{height: 40}}
									alt="ロゴ"
									src={logo}
								/>
							</Link>
							<Typography
		            sx={{
									fontSize: 12,
			            fontWeight: "light"
		            }}
		            color="secondary.contrastText"
		          >
		            勤怠管理システム
		          </Typography>
						</Box>
						<Box
							sx={{
								padding: 1,
								display: "flex",
								justifyContent: "space-between"
							}}
						>
							<Box
								sx={{
									textAlign: "left"
								}}
							>
								<Stack
									direction="row"
									divider={<Divider orientation="vertical" flexItem/>}
									spacing={1}
								>
									<Typography
										color="secondary.contrastText"
									>
										{auth.user.department}
									</Typography>
									<Typography
										color="secondary.contrastText"
									>
										{auth.user.corporate_title}
									</Typography>
								</Stack>
								<Typography
										color="secondary.contrastText"
									>
									{auth.user.full_name}
								</Typography>
							</Box>
							<IconButton onClick={handleOpen}> <MoreVertIcon /></IconButton>
	          </Box>
					</Toolbar>
				</Container>
			</AppBar>
			<Navigation />
			<ModalBase open={open} handleClose={handleClose}>
				<Profile handleClose={handleClose} />
			</ModalBase>
			<Outlet />
		</>
	)
}

export default Header;