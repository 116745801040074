import React from "react";
import {Box, List, ListItem, Typography} from "@mui/material";
import {useNotification} from '../../hooks/useNotification'
import {NotificationBox} from './NotificationBox'

const ErrorNotification: React.FC = () => {
	const {errorNotifications} = useNotification()
	return (
		<NotificationBox title="エラー通知" count={errorNotifications.length}>
			<List>
				{
					errorNotifications.map((val, index) => (
						<ListItem key={index} divider disableGutters sx={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
							<Box>
								<Box mb={0.5}>
									<Box fontSize={12} component="span" color="primary.contrastText">{val.verb}</Box>
								</Box>
								<Box>
									<Typography paragraph fontSize={12} color="error" fontWeight="normal">
										{val.description}
									</Typography>
								</Box>
							</Box>
						</ListItem>
					))
				}
			</List>
		</NotificationBox>
	);
};

export default ErrorNotification;