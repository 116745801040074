import React, {useEffect, useState} from 'react'
import useAuthAxios from '../../hooks/useAuthAxios'
import {TimecardChangeApplicationT} from '../../store/timecard'
import {useTimecard} from '../../hooks/useTimecard'

export const useTimecardChangeApplication = (
  id: number,
  handleClose: () => void
) => {
  const [state, setState] = useState<TimecardChangeApplicationT>(null!);
	const [errors, setErrors] = useState<any>({});
  const {handleTimecardChange} = useTimecard<TimecardChangeApplicationT>()
	const axios = useAuthAxios();

	useEffect(()=>{
		axios.get(`/timecard-change/${id}/`)
			.then((res) => { setState(res.data)})
		  .catch((err) => console.log(err))
	},[id, axios]);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleTimecardChange(event, setState);
	}

	const handleSubmit = () => {
		let cp_data = state;
		cp_data.status = "pending";
		axios.put(`/timecard/change_request/${state.timecard}/`, cp_data)
			.then(() => {
				handleClose();
			})
			.catch((res)=>{
				setErrors(res.response.data);
			})
	}
	const handleDrop  = () => {
		axios.delete(`/timecard-change/${id}/`)
			.then(() => {
				handleClose();
			})
		  .catch((err) => console.log(err))
	};

  return {
    state,
    errors,
    handleChange,
    handleSubmit,
    handleDrop
  }
}