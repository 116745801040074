import React from 'react'
import {Box, FormControl, IconButton, InputAdornment, MenuItem, Stack, TextField} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import {Search} from '@mui/icons-material'
import {CSVLink} from 'react-csv'
import {userCSVHeaders, UserDetail, UserSearchType} from '../../../store/users'
import {CorporateTitleT, DepartmentT} from '../../../store/company'
import {csvButtonStyle} from '../../../store/store'

interface UserSearchBoxProps {
  search: UserSearchType
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleSearchSubmit: (event: React.FormEvent<HTMLFormElement>) => void
  departments: DepartmentT[]
	corporateTitles: CorporateTitleT[]
	users: UserDetail[]
}

export const UserSearchBox: React.FC<UserSearchBoxProps> = (props) => {
  const {
    search,
    handleSearchChange,
		handleSearchSubmit,
		departments,
		corporateTitles,
		users
  } = props
  return (
    <Box sx={{display: "flex", justifyContent: "space-between", alignItems: {xs:"end", sm: "normal"}}}>
				<Stack direction={{xs: "column", xsm: "row",}} spacing={2}>
					<FormControl onSubmit={handleSearchSubmit} component="form" variant="outlined" size="small">
						<TextField
							name="search"
							size="small"
							value={search.search}
							onChange={handleSearchChange}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<SearchIcon />
									</InputAdornment>
								),
								endAdornment:(
			            <InputAdornment position="end">
			              <IconButton
			                type="submit"
			                aria-label="toggle password visibility"
			                edge="end"
			              >
			                <Search />
			              </IconButton>
			            </InputAdornment>
			          )
							}}
						/>
					</FormControl>
					<TextField
						size="small"
						value={search?.department || ""}
						onChange={handleSearchChange}
						name="department"
						select
						sx={{
							width: {xs: "100%", sm:"auto"},
							'& .MuiSelect-select .notranslate::after': "部署を選択"
	            ? {
	                content: `"部署を選択"`,
	                opacity: 0.42,
	              }
	            : {},
						}}
					>
						<MenuItem value="" sx={{opacity:0.42}}>部署を選択</MenuItem>
						{
							departments.map((value)=>(
								<MenuItem value={value.id} key={value.id}>{value.name}</MenuItem>
								)
							)
						}
					</TextField>
					<TextField
						value={search?.corporateTitle || ""}
						name="corporateTitle"
						size="small"
						onChange={handleSearchChange}
						select
						sx={{
							width: {xs: "100%", sm:"auto"},
							'& .MuiSelect-select .notranslate::after': "役職を選択"
	            ? {
	                content: `"役職を選択"`,
	                opacity: 0.42,
	              }
	            : {},
						}}
					>
						<MenuItem value="" sx={{opacity:0.42}}>役職を選択</MenuItem>
						{
							corporateTitles.map((value)=>(
								<MenuItem value={value.id} key={value.id}>{value.title}</MenuItem>
								)
							)
						}
					</TextField>
				</Stack>
				<CSVLink data={users} headers={userCSVHeaders} style={csvButtonStyle} filename='user_list.csv'>データの出力</CSVLink>
			</Box>
  )
}