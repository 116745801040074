import React from "react";
import {Box, Button, IconButton, Stack, Typography} from "@mui/material";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {PayrollCalendar} from '../store/monthlyData'

const MONTHS = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];

interface CalendarPaginationProps {
	payrollCalendar?: PayrollCalendar;
}

const CalendarPagination: React.FC<CalendarPaginationProps> = ({payrollCalendar={}}) => {
	const params = useParams();
	const {pathname, hash, search} = useLocation();
	const navigate = useNavigate();
	return(
		<Box width="100%">
			<Stack direction="row" width="100%" sx={{justifyContent: {xs:"center", sm:"space-between"}}}>
				<IconButton
					onClick={() => {
						let monthIndex = MONTHS.findIndex(value => value === params.month);
						let currentYear = parseInt(params.year || '2022');
						let year = monthIndex > 0 ? params.year : currentYear - 1;
						let month = monthIndex > 0 ? MONTHS[monthIndex - 1] : '12';
						let path = pathname.replace(new RegExp("\\b"+params.year+"\\b"), `${year}`)
						path = path.replace(new RegExp("\\b"+params.month+"\\b"), month)
						navigate(`${path}${search}${hash}`);
					}}
				>
					<ArrowBackIosIcon />
				</IconButton>
				<Stack direction="row" spacing={{xs: 0, md:1}} display={{xs: "none", sm: "flex"}}>
					<Button type="button" disabled sx={{fontSize: {sm: 10, md: 12, lg:20}, p: 0, minWidth: 0}}>
						{params.year}年
					</Button>
					{
						MONTHS.map((val, index) => (
							<IconButton
								key={index}
								className={params.month === val ? "active-link-bg" : ""}
								sx={{fontSize: {sm: 10, md: 12, lg:20}}}
								onClick={() => {
									params.month && navigate(`${pathname.replace(new RegExp("\\b"+params.month+"\\b"), val)}${search}${hash}`)
								}}
							>
								{val}月
							</IconButton>
						))
					}
				</Stack>
				<Box display={{xs: "block", sm: "none"}} textAlign="center">
					<Typography variant="h5">{payrollCalendar?.payroll_date}</Typography>
					<Typography variant="inherit" color="secondary.contrastText">({payrollCalendar?.start_date}~{payrollCalendar?.end_date})</Typography>
				</Box>
				<IconButton
					onClick={() => {
						let monthIndex = MONTHS.findIndex(value => value === params.month);
						let currentYear = parseInt(params.year || '2022')
						let year = monthIndex < 11 ? params.year : currentYear + 1
						let month = monthIndex < 11 ? MONTHS[monthIndex + 1] : '1' ;
						let path = pathname.replace(new RegExp("\\b"+params.year+"\\b"), `${year}`)
						path = path.replace(new RegExp("\\b"+params.month+"\\b"), month)
						navigate(`${path}${search}${hash}`);
					}}
				>
					<ArrowForwardIosIcon />
				</IconButton>
			</Stack>
		</Box>
	);
};

export default CalendarPagination;