import {PTORequest} from './pto'
import {MonthlyTimecard, TimecardMemoT} from './timecard'

export type MonthlyDataBannerT = {
  month: number
  year: number
  recruitment_end: string
}

export type MonthlyDataDays = {
  day: number
  year: number
  month: number
  weekday: string
  holiday: boolean
}

export type PayrollCalendar = {
  start_date: string
  end_date: string
  payroll_date: string
}

export type MonthlyDataT = {
  absents: number
  days: MonthlyDataDays[]
  end_dt: string
  full_name: string
  holiday_workhours: string
  id?: number
  max_requests_remaining: number
  month: number
  pending_list: number[]
  pto: PTORequest[]
  recruitment_end: string | null
  recruitment_start: string | null
  remaining_days_pto: number
  used_pto_days: number
  remaining_hours_pto: number
  used_pto_2_hours: number
  special_pto: number
  start_dt: string
  status: "pending" | "recruitment" | "completed" | "blank"
  total_workdays: number
  total_workhours: string
  transfer_remaining: number
  user: number
  workdays: MonthlyTimecard[]
  year: number
  insufficient?: string
  payroll_calendar?: PayrollCalendar
  memos: TimecardMemoT[]
}

export const defaultMonthlyData: MonthlyDataT = {
  total_workdays: 0,
  total_workhours: "0",
  absents: 0,
  holiday_workhours: "",
  used_pto_2_hours: 0,
  special_pto: 0,
  insufficient: "",
  remaining_days_pto: 0,
  remaining_hours_pto: 0,
  transfer_remaining: 0,
  used_pto_days: 0,
  days: [],
  end_dt: "",
  full_name: "",
  max_requests_remaining: 0,
  month: 0,
  pending_list: [],
  pto: [],
  recruitment_end: "",
  recruitment_start: "",
  start_dt: "",
  status: "blank",
  user: 0,
  workdays: [],
  year: 0,
  memos: []
}