import React from 'react'
import {TimecardChangeApplicationT} from '../../store/timecard'
import {ModalTable} from '../index'
import {TextField} from '@mui/material'

interface EditSentApplicationProps{
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	state: TimecardChangeApplicationT;
	errors: any
}

export const EditTimecardChangeSentApplication:React.FC<EditSentApplicationProps> = ({state, handleChange, errors}) => {
	return (
		<ModalTable>
			<caption>申請日: {state.created_dt}</caption>
			<tr>
				<th>ステータス</th>
				<td>
					{state.status_display}
				</td>
			</tr>
			<tr>
				<th>日付</th>
				<td>
					{state.date || "-"}
				</td>
			</tr>
			<tr>
				<th>修正前打刻(出勤)</th>
				<td>
					{state.old_start_display || "-"}
				</td>
			</tr>
			<tr>
				<th>修正後打刻(出勤)</th>
				<td>
					<TextField
							size="small"
							name="new_start"
							value={state.new_start}
							onChange={handleChange}
							error={errors.new_start && true}
							helperText={errors?.new_start?.map((val:string) => val).join(", ")}
						/>
				</td>
			</tr>
			<tr>
				<th>修正理由(出勤)</th>
				<td>
					<TextField
						size="small"
						value={state.reasons.start}
						name="start"
						onChange={handleChange}
						error={errors.reasons?.start && true}
	          helperText={errors.reasons?.start ?
	            errors.reasons?.start.map((val: string) => val).join(', ') : "例：打刻忘れ"}
						inputProps={{
							"data-name": "reasons"
						}}
					/>
				</td>
			</tr>
			<tr>
				<th>修正前打刻(退勤)</th>
				<td>
					{state.old_end_display || "-"}
				</td>
			</tr>
			<tr>
				<th>修正後打刻(退勤)</th>
				<td>
					<TextField
							size="small"
							name="new_end"
							value={state.new_end}
							onChange={handleChange}
							error={errors.new_end && true}
							helperText={errors?.new_end?.map((val:string) => val).join(", ")}
						/>
				</td>
			</tr>
			<tr>
				<th>修正理由(退勤)</th>
				<td>
					<TextField
						size="small"
						value={state.reasons.end}
						name="end"
						onChange={handleChange}
						error={errors.reasons?.end && true}
	          helperText={errors.reasons?.end ?
	            errors.reasons?.end.map((val: string) => val).join(', ') : "例：打刻忘れ"}
						inputProps={{
							"data-name": "reasons"
						}}
					/>
				</td>
			</tr>
		</ModalTable>
	)
}