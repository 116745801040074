import React from 'react'
import {Header} from './components'
import {Route, Routes} from 'react-router-dom'
import AuthProvider from './contexts/auth'
import ProtectedRoute from './hoc/protectedRoute'
import Login from './components/Login'
import {NotificationProvider} from './contexts/notification'
import {
  AttendanceManagementView,
  DailyAttendanceView,
  HomeView,
  PendingApplicationView,
  PTOApplicationView,
  SettingsView,
  UserAttendanceView,
  CompanyView,
  UsersView
} from './pages'
import {AlertMessageProvider} from './contexts/alertMessage'
import {LoadingProvider} from './contexts/loading'

function App() {
  return (
    <AuthProvider>
      <LoadingProvider>
        <Routes>
          <Route path="/login" element={<Login/>}/>
          <Route
            path="*"
            element={
              <ProtectedRoute>
                <ProtectedView/>
              </ProtectedRoute>
            }
          />
        </Routes>
      </LoadingProvider>
    </AuthProvider>
  )
}

const ProtectedView: React.FC = () => {
  return (
    <AlertMessageProvider>
      <NotificationProvider>
        <Routes>
          <Route element={<Header/>}>
            <Route path="/" element={<HomeView/>}/>
            <Route path="/daily-attendance">
              <Route path=":year/:month" element={<DailyAttendanceView/>}/>
            </Route>
            <Route path="/pto" element={<PTOApplicationView/>}/>
            <Route path="/applications-list" element={<PendingApplicationView/>}/>
            <Route path="/attendance-management">
              <Route path=":year/:month" element={<AttendanceManagementView/>}/>
              <Route path=":year/:month/:user_id" element={<UserAttendanceView/>}/>
            </Route>
            <Route path="/settings">
              <Route index element={<SettingsView/>}/>
              <Route path="users" element={<UsersView/>}/>
              <Route path="company" element={<CompanyView/>}/>
            </Route>
            <Route path="*" element={<>Not Found</>}/>
          </Route>
        </Routes>
      </NotificationProvider>
    </AlertMessageProvider>
  )
}

export default App
