import {NotificationContext} from '../contexts/notificationContext'
import {useContext} from 'react'

export const useNotification = () => {
  if (!NotificationContext) {
    throw new Error('useNotification must be used within a NotificationProvider')
  }

  const {state} = useContext(NotificationContext)
  const {actorNotifications, recipientNotifications, errorNotifications} = state

  return {actorNotifications, recipientNotifications, errorNotifications}
}