import React from "react";
import styled from "styled-components";

const Table = styled.table`
	border-collapse: collapse;
	width: 100%;
	& caption {
		text-align: left;
		font-weight: 600;
		font-size: 1.3em;
	}
	& th {
		background: #f2f2f2;
		font-weight: 500;
	}
	& th, & td {
		border: 1px solid #ddd;
	  padding: 8px;
	}
`;

interface ModalTableProps {
	children: React.ReactNode;
	className?: string;
}

const ModalTable:React.FC<ModalTableProps> = (props) => {
	const {children, className} = props;
	return(
		<Table className={className || ""}>
			{children}
		</Table>
	);
};

export default ModalTable;