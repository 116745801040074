import {useLocation, useNavigate, useParams} from 'react-router-dom'
import React, {useCallback, useEffect, useMemo, useRef} from 'react'
import {useFileDownload} from '../../../hooks/useFileDownload'
import useAuthAxios from '../../../hooks/useAuthAxios'
import {AttendanceManagementTablePaginationType} from '../../../store/attndanceManagement'
import {CSVLink} from 'react-csv'

export const useAttendanceManagementTable = (id: number) => {
  const {year, month} = useParams()
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null!)
  const {handleDownload} = useFileDownload()
  const [users, setUsers] = React.useState<AttendanceManagementTablePaginationType>({count: 0, results: []})
  const search = useRef<string>('')
  const axios = useAuthAxios()
  const location = useLocation()
  const navigate = useNavigate()
  const query = useMemo(() => new URLSearchParams(location.search), [location.search])
  const page = useMemo(() => parseInt(query.get('page') || '1', 10), [query])

  const fetchData = useCallback(() => {
    axios.get(
      `/user/monthly/${year}/${month}/?group=${id}&page=${page}&search=${search.current}`,
    )
      .then((res) => {
        setUsers(res.data)
      })
  }, [axios, id, page, year, month])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const handleCSVSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = event.target
    if (value === '1') {
      csvLinkRef.current.link.click()
    } else {
      handleDownload(`/timecard/monthly-csv-folder/${year}/${month}/${id}/`, 'empty.zip')
    }
  }

  return {
    users,
    search,
    handleCSVSelect,
    csvLinkRef,
    fetchData,
    navigate,
    location,
    page
  }
}