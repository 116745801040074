import React from "react";
import {BottomNavigation, BottomNavigationAction, Paper, Box, Button} from "@mui/material";
import {pages} from "./pages";
import {Link, useLocation, NavLink as NavLinkBase, useParams} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";

const activeClassName: string = "active-link";

const NavLink = React.forwardRef((props:any, ref: React.Ref<HTMLAnchorElement>) => (
  <NavLinkBase
    ref={ref}
    {...props}
    className={({ isActive }) => `${props.className} ${isActive ? activeClassName : ''}`}
  />
));

const Navigation: React.FC = () => {
	const {pathname} = useLocation();
	const {auth} = useAuth();
	const params = useParams();
	const [value, setValue] = React.useState(pathname);
  const handleChange = (_event: React.SyntheticEvent, newValue:string) => {
    setValue(newValue);
	};

	const initialParams:{[key:string]: number} = {
		month: auth.user.date_dict.month,
		year: auth.user.date_dict.year
	}
	return (
		<>
			<Paper
				sx={{
					display: {md: "none"},
					position: "fixed",
					left: 0,
					right: 0,
					bottom: 0,
					zIndex: 1
				}}
				elevation={3}
			>
				<BottomNavigation value={value} onChange={handleChange} showLabels>
					{
						pages.filter((obj) => obj.mobile).map((value, index) => (
								<BottomNavigationAction
									component={Link}
									to={
										value.params
											?
												 value.location + "/" + value.params.map((path:string) => params.hasOwnProperty(path) ? params[path] : initialParams[path]).join("/")
											:
												value.location
									}
									value={
										value.params
										?
											 value.location + "/" + value.params.map((path:string) => params.hasOwnProperty(path) ? params[path] : initialParams[path]).join("/")
										:
											value.location
									}
									key={index}
                  label={value.label} icon={value.icon}/>
							)
						)
					}
				</BottomNavigation>
			</Paper>
			<Paper
				sx={{
					display: {xs: "none", md: "block"},
					mt: 0.1
				}}
				elevation={1}
				square
			>
				<Box flexGrow={1} sx={{display: 'flex'}}>
					{
						pages.filter(obj => obj.pc).map((value, index) => (
							<React.Fragment key={index}>{
								(value.forbidden.length === 0 || auth.user.is_admin || (auth.user.sub_admin && value.forbidden.includes('sub_admin'))) &&
								<Button
									component={NavLink}
									to={
									value.params
									?
									value.location + "/" + value.params.map((path:string) =>params.hasOwnProperty(path) ? params[path]  : initialParams[path]).join("/")
									:
									value.location
								}
									sx={{
									display: 'flex',
									color: "primary.contrastText",
									alignItems: "center",
									fontSize: 16,
									mx: 1,
								}}
									startIcon={value.icon}
									>
								{value.label}
									</Button>
							}</React.Fragment>
						))
					}
				</Box>
			</Paper>
		</>
	);
};

export default Navigation;