import React, {useEffect, useState} from 'react'
import {Box, Typography} from '@mui/material'
import {todayDay} from '../../../utils/date'

export const DisplayTodayDate = () => {
    const [todayDate, setTodayDate] = useState(todayDay())

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTodayDate(todayDay())
        }, 1000)
        return () => clearInterval(intervalId);
    }, [])

    return (
			<Box textAlign="center">
				<Typography component="p" variant="h5">{todayDate.date}</Typography>
				<Typography component="p" variant="h1" sx={{fontWeight: 500, fontSize: {xs: 40, md: 50}}} >{todayDate.hm}</Typography>
			</Box>
    )
}