import React, {useEffect, useRef} from 'react'
import useAuthAxios from '../../../hooks/useAuthAxios'
import {
  alertMessage,
  CompanySettingsState,
  CompanySettingsStates,
  crudInitial,
  CrudT, CRUTDataType, getUrl, HolidayDataT,
} from '../../../store/company'
import {useAlertMessage} from '../../../hooks/useAlertMessage'
import {useAuth} from '../../../hooks/useAuth'
import {SelectChangeEvent} from '@mui/material'

export const useCompanySettingsLayout = () => {
  const axios = useAuthAxios()
  const itemsRef = useRef<Array<HTMLDivElement | null>>([])
  const [states, setStates] = React.useState<CompanySettingsStates>({
    departments: [],
    corporateTitles: [],
    groups: [],
    vacationApprovers: [],
    users: [],
    noCompensatoryHolidays: [],
    holidays: [],
  })
  const [state, setState] = React.useState<CompanySettingsState>({})

  const [crud, setCrud] = React.useState<CrudT<CRUTDataType>>(crudInitial)
  const [errors, setErrors] = React.useState<{ [K in keyof CompanySettingsState]?: { [key: string]: any } }>({})
  const {auth} = useAuth()
  const {setAlertMessage} = useAlertMessage()

  useEffect(() => {
    itemsRef.current.slice(0, 6)

    const fetchData = async () => {
      try {
        const [
          departmentsRes,
          corporateTitlesRes,
          groupsRes,
          vacationApproversRes,
          noCompensatoryHolidaysRes,
          holidaysRes,
          usersRes,
        ] = await Promise.all([
          axios.get('companies/department/'),
          axios.get('/companies/corporate-title/'),
          axios.get('/companies/group/'),
          axios.get('/companies/vacation-approver/'),
          axios.get('/companies/no-compensatory-holidays/'),
          axios.get('/companies/grouped-holiday/'),
          axios.get('/user/?page_size=1000'),
        ])

        setStates((prevState) => ({
          ...prevState,
          departments: departmentsRes.data,
          corporateTitles: corporateTitlesRes.data,
          groups: groupsRes.data,
          vacationApprovers: vacationApproversRes.data,
          noCompensatoryHolidays: noCompensatoryHolidaysRes.data,
          holidays: holidaysRes.data,
          users: usersRes.data.results,
        }))
      } catch (error) {
        // Handle errors if needed
        console.error(error)
      }
    }

    (async () => {
      await fetchData()
    })()

  }, [axios])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, stateName: keyof CompanySettingsState) => {
    const {name, value} = event.target
    setState((prevState) => ({...prevState, [stateName]: {...prevState[stateName], [name]: value}}))
  }

  const handleMultipleSelectChange = (event: SelectChangeEvent<string[]>, stateName: keyof CompanySettingsState) => {
    const {
      target: {value, name},
    } = event
    setState((prevState) => ({
      ...prevState, [stateName]: {
        ...prevState[stateName],
        [name]: typeof value === 'string' ? value.split(',') : value,
      },
    }))
  }

  const handleSubmit = (stateName: keyof CompanySettingsState) => {

    let cp_state = state[stateName]
    if (cp_state) {
      cp_state['company'] = auth.user.company
      axios.post(getUrl(stateName, 'post'), cp_state)
        .then((res) => {
          setStates((prevState) => ({...prevState, [stateName]: [...prevState[stateName], res.data]}))
          setState((prevState) => ({...prevState, [stateName]: null}))
          setErrors((prevState) => ({...prevState, [stateName]: {}}))
          setAlertMessage({status: 'success', open: true, message: alertMessage[stateName].add.success})
        })
        .catch((res) => {
          setErrors((prevState) => ({...prevState, [stateName]: res.response.data}))
          setAlertMessage({status: 'error', open: true, message: alertMessage[stateName].add.error})
        })
    }
  }
  const handleEdit = (id: number, stateName: keyof CompanySettingsState) => {
    if (crud.data) {
      axios.patch(getUrl(stateName, 'patch', id), crud.data)
        .then((res) => {
          setStates((prevState) => {
            if (stateName !== 'holidays') {
              let cp_data = prevState[stateName]
              let index = cp_data.findIndex((obj) => crud.data && obj.id === crud.data?.id)
              if (index > -1 && cp_data[index]) {
                cp_data[index] = res.data
              }
              return {...prevState, [stateName]: cp_data}
            } else {
              let cp_data = prevState[stateName]
              const d = cp_data.reduce((previousValue, currentValue) => {
                previousValue.push({
                  ...currentValue,
                  data: currentValue.data.map((obj) => obj.id === res.data.id ? res.data : obj),
                })
                return previousValue
              }, [] as HolidayDataT[])
              return {...prevState, [stateName]: d}
            }
          })
          setCrud(crudInitial)
          setAlertMessage({status: 'success', open: true, message: alertMessage[stateName].edit.success})
        })
        .catch((errors) => {
          setCrud((prevState) => ({...prevState, error: errors.response.data}))
          setAlertMessage({status: 'error', open: true, message: alertMessage[stateName].edit.error})
        })
    }
  }
  const handleDelete = (id: number, stateName: keyof CompanySettingsState) => {
    axios.delete(getUrl(stateName, 'delete', id))
      .then(() => {
        setStates((prevState) => {
          if (!prevState[stateName]) {
            return {...prevState, [stateName]: []}
          }
          if (stateName !== 'holidays') {
            let cp_data = prevState[stateName]
            let index = cp_data.findIndex((obj) => obj.id === id)
            if (index > -1) {
              cp_data.splice(index, 1)
            }
            return {...prevState, [stateName]: cp_data}
          } else {
            let cp_data = prevState[stateName]
            const d = cp_data.reduce((previousValue, currentValue) => {
              const holidayData = {...currentValue, data: currentValue.data.filter((obj) => obj.id !== id)}
              if (holidayData.data.length > 0) {
                previousValue.push(holidayData)
              }
              return previousValue
            }, [] as HolidayDataT[])
            return {...prevState, [stateName]: d}
          }
        })
        setCrud(crudInitial)

        setAlertMessage({status: 'success', open: true, message: alertMessage[stateName].delete.success})
      })
      .catch(() => {
        setAlertMessage({status: 'error', open: true, message: alertMessage[stateName].delete.error})
      })
  }
  const handleBoxFocus = (el: HTMLDivElement | null) => {
    if (el) {
      el.focus()
    }
  }

  const handleClose = () => {
    setCrud(crudInitial)
  }

  const handleOpen = (data: CRUTDataType, type: 'edit' | 'delete', stateName: keyof CompanySettingsState) => {
    setCrud({type, data, open: true, crudType: stateName})
  }

  const handleCRUDChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target
    setCrud((prevState) => {
      if (!prevState.data) {
        const newData = {[name]: value} as Partial<CRUTDataType>
        return {...prevState, data: newData as CRUTDataType}
      }
      return {...prevState, data: {...prevState.data, [name]: value}}
    })
  }

  const handleMultipleSelectCRUDChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: {value, name},
    } = event
    setCrud((prevState) => {
      if (!prevState.data) {
        const newData = {[name]: typeof value === 'string' ? value.split(',') : value} as Partial<CRUTDataType>
        return {...prevState, data: newData as CRUTDataType}
      }
      return {...prevState, data: {...prevState.data, [name]: typeof value === 'string' ? value.split(',') : value}}
    })
  }

  return {
    states,
    state,
    crud,
    errors,
    itemsRef,
    handleBoxFocus,
    handleChange,
    handleSubmit,
    handleEdit,
    handleDelete,
    handleOpen,
    handleClose,
    handleCRUDChange,
    handleMultipleSelectCRUDChange,
    handleMultipleSelectChange,
  }
}