import React from 'react'
import {Box, Grid} from '@mui/material'
import {useAuth} from '../../hooks/useAuth'
import {DisplayTodayDate} from './components/DisplayTodayDate'
import {TimeCardAdditionalButtons} from './components/TimeCardAdditionalButtons'
import {TimeCardActionButtons} from './components/TimeCardActionButtons'
import {useHome} from './hooks/useHome'
import TimeCard from './components/TimeCard'
import MonthlyDataBanner from './components/MonthlyDataBanner'
import {SentApplication, ReceivedApplication, ErrorNotification} from '../../components'
import {BaseLayout} from '../index'


const HomeLayout: React.FC = () => {
	const {auth} = useAuth()
	const {status, handleStart, handleEnd} = useHome()

	return (
		<BaseLayout>
			<Grid container sx={{display: {xs:"none", md: "flex"}}} spacing={2}>
				<Grid item xs={12}  p={2}>
					<TimeCard status={status} handleStart={handleStart} handleEnd={handleEnd} />
				</Grid>
				<Grid item xs={12}  p={2}>
					<MonthlyDataBanner state={status?.banner || null} />
				</Grid>
				<Grid item xs={4} p={2}>
					<SentApplication />
				</Grid>
				{
					(auth.user.is_admin || auth.user.sub_admin) &&
					<Grid item xs={4} p={2}>
						<ReceivedApplication />
					</Grid>
				}
				<Grid item xs={4} p={2}>
					<ErrorNotification />
				</Grid>
			</Grid>
			<Box sx={{display: {md: "none"}}}>
				<DisplayTodayDate />
				<Box sx={{bgcolor:"primary.light", px:2, py: 3}}>
					<Box sx={{width: "100%"}} mb={2}>
						<TimeCardAdditionalButtons status={status} />
					</Box>
					<TimeCardActionButtons status={status} handleStart={handleStart} handleEnd={handleEnd}/>
					<Box sx={{width: "100%"}}>
						<MonthlyDataBanner state={status?.banner || null}/>
					</Box>
					<Box m={{xs: 1, sm: 3}}>
						<SentApplication />
					</Box>
					{
						(auth.user.is_admin || auth.user.sub_admin) &&
						<Box m={{xs: 1, sm: 3}}>
							<ReceivedApplication />
						</Box>
					}
					<Box m={{xs: 1, sm: 3}}>
						<ErrorNotification />
					</Box>
				</Box>
			</Box>
		</BaseLayout>
	);
}

export default HomeLayout