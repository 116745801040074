import {useEffect, useState} from 'react'
import useAuthAxios from '../../../hooks/useAuthAxios'
import {TimecardStatus} from '../../../store/timecard'

export const useHome = () => {
  const [status, setStatus] = useState<TimecardStatus | null>(null);
	const axios = useAuthAxios();

	useEffect(() => {
		axios.get("/timecard/status/")
			.then(
				(res) => { setStatus(res.data);}
			)
		  .catch((err) => console.log(err))
	},[axios])

	const handleStart = () => {
		axios.post("/timecard/start/")
			.then((res) => {
				setStatus(res.data)
			})
			.catch((error) => {
				setStatus(error.response.data.status)
			})
	}

	const handleEnd = () => {
		axios.post("/timecard/end/")
			.then((res) => {
				setStatus(res.data)
			})
			.catch((error) => {
				setStatus(error.response.data.status)
			})
	}

  return {
    status,
    handleStart,
    handleEnd
  }
}