import React from 'react'
import {loadingReducer} from '../store/loading'
import {LoadingContext} from './loadingContext'
import {CircularProgress} from '@mui/material'
import Backdrop from '@mui/material/Backdrop'

interface LoadingProviderProps {
  children: React.ReactNode
}

export const LoadingProvider: React.FC<LoadingProviderProps> = ({children}) => {
  const [loading, dispatch] = React.useReducer(loadingReducer, 0)
  return (
    <LoadingContext.Provider value={{loading, dispatch}}>
      <Backdrop
				open={loading > 0}
				sx={{ zIndex: (theme) => theme.zIndex.drawer + 1000 }}
			>
					<CircularProgress />
			</Backdrop>
      {children}
    </LoadingContext.Provider>
  )
}