import useAuthAxios from './useAuthAxios'
import {useCallback} from 'react'

export const useFileDownload = () => {
  const axios = useAuthAxios();

  const handleDownload = useCallback((url: string, _filename: string) => {
    axios.get(url, {
      responseType: "blob"
    })
      .then((res) => {
        const contentDisposition = res.headers['content-disposition']
        let filename = _filename
        if (contentDisposition){
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(contentDisposition) || []
          filename = decodeURIComponent(encodeURI(window.atob(matches[1].replace(/['"]/g, ''))))
        }
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename); //or any other extension
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((err) => console.log(err))

  }, [axios])

  return {handleDownload}
}