import {useEffect, useState} from 'react'
import {TimecardChangePendingApplicationT} from '../../../store/timecard'
import useAuthAxios from '../../../hooks/useAuthAxios'
import {useFileDownload} from '../../../hooks/useFileDownload'
import {PTORequest} from '../../../store/pto'

export const usePendingApplicationLayout = () => {
  const [timecardChanges, setTimecardChanges] = useState<TimecardChangePendingApplicationT[]>([]);
	const [ptoRequests, setPtoRequests] = useState<PTORequest[]>([]);
	const {handleDownload} = useFileDownload()
	const axios = useAuthAxios();

	useEffect(()=>{
		axios.get('/timecard-change/approve_pending/')
			.then((res) => {setTimecardChanges(res.data)})
			.catch((err) => console.log(err))
		axios.get('/pto/approve_pending/')
			.then((res) => {setPtoRequests(res.data)})
			.catch((err) => console.log(err))
	},[axios])

	const handleTimecardChangeSubmit = (id: number) => {
		axios.patch(`/timecard-change/${id}/admin_update/`, {status: "completed"})
			.then(() => {
				setTimecardChanges((prevState) => prevState.filter((item => item.id !== id)))
			})
			.catch((err) => console.log(err))
	}
	const handleTimecardChangeDrop  = (id: number) => {
		axios.patch(`/timecard-change/${id}/admin_update/`, {status: "send_back"})
			.then(()=> {
				setTimecardChanges((prevState) => prevState.filter((item => item.id !== id)))
			})
			.catch((err) => console.log(err))
	};

	const handlePTORequestSubmit = (id: number) => {
		axios.patch(`/pto/${id}/`, {status: "completed"})
			.then(() => {
				setPtoRequests((prevState) => prevState.filter((item => item.id !== id)))
			})
			.catch((err) => console.log(err))
	}
	const handlePTORequestDrop  = (id: number) => {
		axios.patch(`/pto/${id}/`, {status: "send_back"})
			.then(()=> {
				setPtoRequests((prevState) => prevState.filter((item => item.id !== id)))
			})
			.catch((err) => console.log(err))
	};

	const handleExport = () => {
		handleDownload('/pto/get/approved-data/', 'empty.xlsx')
	}

  return {
    timecardChanges,
    handleTimecardChangeSubmit,
    handleTimecardChangeDrop,
		ptoRequests,
		handlePTORequestSubmit,
		handlePTORequestDrop,
		handleExport
  }
}