export const dateToJp = (d:Date) => {
  const WEEKDAYS = ["日","月","火","水","木","金","土"]
  const curr_date = d.getDate();
  const curr_month = d.getMonth() + 1;
  const curr_year = d.getFullYear();
  return {
		date:`${curr_year}年${curr_month}月${curr_date}日(${WEEKDAYS[d.getDay()]})`,
    hm:`${d.getHours() < 10 ? '0' + d.getHours() : d.getHours()}:${d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes()}`,
	};
}

export const todayDay = () => {
  const d = new Date();
  return dateToJp(d)
}

export const dateStringToJpString = (dateString:string) => {
  const d = new Date(dateString);
  return dateToJp(d).date
}