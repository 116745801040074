import {CompanyGroup} from '../store/company'

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export const getTab = (hash:string, data: CompanyGroup[]) =>{
		const id = parseInt(hash.slice(1));
		return id ? data.findIndex(obj=> obj.id === id) : data.length
	}