import React from "react";
import {Box, CircularProgress} from "@mui/material";

const LoadingSpinner:React.FC = () => {
	return(
		<Box
	    sx={{
	      position: "absolute",
	      float: "left",
	      left: "50%",
	      top: "50%",
	      transform: "translate(-50%, -50%)"
	    }}
	  >
	    <CircularProgress />
	  </Box>
	)
}

export default LoadingSpinner;