import React from 'react'
import {UserTablePaginationType} from '../../../store/users'
import {
  Box,
  Pagination,
  PaginationItem,
  Paper,
  Table,
  TableBody, TableCell, tableCellClasses,
  TableContainer,
  TableHead, TableRow,
  Typography,
} from '@mui/material'
import {Link, Location} from 'react-router-dom'
import {styled} from '@mui/material/styles'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
	  whiteSpace: "nowrap",
	  padding: 6
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
	  padding: 5,
	  whiteSpace: "nowrap"
  },
}));

const StyledTableRow = styled(TableRow)(() => ({
	'& th, & td': {
		textAlign: "center"
	},
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

interface UserTableProps {
  users: UserTablePaginationType
  page: number
  handleModalOpen: (id: number) => void
  location: Location
}

export const UsersTable: React.FC<UserTableProps> = (props) => {
  const {
    users,
    page,
    handleModalOpen,
    location,
  } = props

  return (
    <React.Fragment>
      <TableContainer component={Paper} sx={{width: '100%', my: 1}}>
        <Table sx={{width: '100%', overflow: 'scroll'}}>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>氏名</StyledTableCell>
              <StyledTableCell>メールアドレス</StyledTableCell>
              <StyledTableCell>従業員番号</StyledTableCell>
              <StyledTableCell>役職</StyledTableCell>
              <StyledTableCell>部署</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {
              users?.results?.map((obj) => (
                <StyledTableRow key={obj.id}>
                  <StyledTableCell><Typography>{obj.full_name}</Typography></StyledTableCell>
                  <StyledTableCell><Typography color="primary.main" sx={{cursor: 'pointer'}} onClick={() => {handleModalOpen(obj.id)}}>{obj.email}</Typography></StyledTableCell>
                  <StyledTableCell>{obj.employee_no}</StyledTableCell>
                  <StyledTableCell>{obj.corporate_title_name}</StyledTableCell>
                  <StyledTableCell>{obj.department_name}</StyledTableCell>
                </StyledTableRow>
              ))
            }
          </TableBody>
        </Table>
      </TableContainer>
      <Box width="100%" display="flex" justifyContent="center">
        <Pagination
          count={Math.ceil(users.count / 100)}
          page={page}
          variant="outlined"
          shape="rounded"
          size="small"
          renderItem={(item) => (
            <PaginationItem
              component={Link}
              to={`${location.pathname}?page=${item.page}${location.hash}`}
              {...item}
            />
          )}
        />
      </Box>
    </React.Fragment>
  )
}