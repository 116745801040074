import React from "react";
import {Button, Stack} from "@mui/material";

interface DeleteFormProps {
	handleDelete: (id:number) => void;
	handleClose: () => void;
	children: React.ReactNode;
	id: number;
}

export const DeleteForm: React.FC<DeleteFormProps> = (props) => {
	const {handleDelete, handleClose, children, id} = props;
	return (
		<>
			<Stack direction="column" spacing={3}>
				{children}
				<Stack
					direction={{xs: "column", sm: "row"}} spacing={3}
				>
					<Button type="button" variant="contained" color="inherit" sx={{flex: 1}}
									onClick={handleClose}>キャンセル</Button>
					<Button type="button" variant="contained" form="memo-form" sx={{flex: 1}}
									onClick={() => handleDelete(id)}>削除</Button>
				</Stack>
			</Stack>
		</>
	)
}