import React from 'react'
import {Button, Grid, Stack} from '@mui/material'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import BusinessIcon from '@mui/icons-material/Business'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import EventBusyIcon from '@mui/icons-material/EventBusy'
import ApartmentIcon from '@mui/icons-material/Apartment'
import {useCompanySettingsLayout} from './hooks/useCompanySettingsLayout'
import {CompanySettingsBox} from './components/CompanySettingsBox'
import CorporateTitle from './components/CorporateTitle'
import {companyColumnData, CRUTDataType} from '../../store/company'
import {BaseLayout} from '../index'
import {Department} from './components/Department'
import {Group} from './components/Group'
import {VacationApprover} from './components/VacationApprover'
import {NoCompensatoryHolidays} from './components/NoCompensatoryHolidays'
import Holiday from './components/Holiday'

const CompanySettingsLayout: React.FC = () => {
  const {
    itemsRef,
    state,
    states,
    handleClose,
    handleDelete,
    handleEdit,
    handleCRUDChange,
    handleChange,
    handleSubmit,
    crud,
    errors,
    handleBoxFocus,
    handleOpen,
    handleMultipleSelectChange,
    handleMultipleSelectCRUDChange
  } = useCompanySettingsLayout()

  return (
    <BaseLayout>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={1}>
            {
              companyColumnData.map((value, index) => (
                <Button
                  key={index}
                  onClick={() => {
                    handleBoxFocus(itemsRef.current[index])
                  }}
                  sx={{width: '20%', bgcolor: 'third.light'}}
                  variant="outlined"
                  color="third"
                >
                  {value}
                </Button>
              ))
            }
          </Stack>
        </Grid>
        <Grid tabIndex={0} ref={el => itemsRef.current[0] = el} item xs={12}>
          <CompanySettingsBox icon={<AssignmentIndIcon sx={{fontSize: 50}}/>} label="役職管理">
            <CorporateTitle
              states={states.corporateTitles}
              state={state.corporateTitles}
              handleSubmit={() => {
                handleSubmit('corporateTitles')
              }}
              handleEdit={(id) => {
                handleEdit(id, 'corporateTitles')
              }}
              handleDelete={(id) => {
                handleDelete(id, 'corporateTitles')
              }}
              crud={crud}
              errors={errors.corporateTitles}
              handleChange={(e) => {
                handleChange(e, 'corporateTitles')
              }}
              handleOpen={(data, type) => {
                handleOpen(data as CRUTDataType, type, 'corporateTitles')
              }}
              handleClose={handleClose}
              handleCRUDChange={handleCRUDChange}
            />
          </CompanySettingsBox>
        </Grid>
        <Grid tabIndex={0} ref={el => itemsRef.current[1] = el} item xs={12}>
          <CompanySettingsBox icon={<BusinessIcon sx={{fontSize: 50}}/>} label="部署管理">
            <Department
              states={states.departments}
              state={state.departments}
              handleSubmit={() => {
                handleSubmit('departments')
              }}
              handleEdit={(id) => {
                handleEdit(id, 'departments')
              }}
              handleDelete={(id) => {
                handleDelete(id, 'departments')
              }}
              crud={crud}
              errors={errors.departments}
              handleChange={(e) => {
                handleChange(e, 'departments')
              }}
              handleOpen={(data, type) => {
                handleOpen(data as CRUTDataType, type, 'departments')
              }}
              handleClose={handleClose}
              handleCRUDChange={handleCRUDChange}
              />
          </CompanySettingsBox>
        </Grid>
        <Grid tabIndex={0} ref={el => itemsRef.current[2] = el} item xs={12}>
          <CompanySettingsBox icon={<ManageAccountsIcon sx={{fontSize: 50}}/>} label="グループ管理">
            <Group
              states={states.groups}
              state={state.groups}
              handleSubmit={() => {
                handleSubmit('groups')
              }}
              handleEdit={(id) => {
                handleEdit(id, 'groups')
              }}
              handleDelete={(id) => {
                handleDelete(id, 'groups')
              }}
              crud={crud}
              errors={errors.groups}
              handleChange={(e) => {
                handleChange(e, 'groups')
              }}
              handleOpen={(data, type) => {
                handleOpen(data as CRUTDataType, type, 'groups')
              }}
              handleClose={handleClose}
              handleCRUDChange={handleCRUDChange}
            />
          </CompanySettingsBox>
        </Grid>
        <Grid tabIndex={0} ref={el => itemsRef.current[3] = el} item xs={12}>
          <CompanySettingsBox icon={<EventBusyIcon sx={{fontSize: 50}}/>} label="承認者設定">
            <VacationApprover
              states={states.vacationApprovers}
              state={state.vacationApprovers}
              handleSubmit={() => {
                handleSubmit('vacationApprovers')
              }}
              handleEdit={(id) => {
                handleEdit(id, 'vacationApprovers')
              }}
              handleDelete={(id) => {
                handleDelete(id, 'vacationApprovers')
              }}
              crud={crud}
              errors={errors.vacationApprovers}
              handleChange={(e) => {handleChange(e, 'vacationApprovers')}}
              handleOpen={(data, type) => {handleOpen(data as CRUTDataType, type, 'vacationApprovers')}}
              handleClose={handleClose}
              handleCRUDChange={handleCRUDChange}
              handleMultipleSelectCRUDChange={handleMultipleSelectCRUDChange}
              handleChangeSelect={(e) => {handleMultipleSelectChange(e, 'vacationApprovers')}}
              users={states.users}
            />
          </CompanySettingsBox>
        </Grid>
        <Grid tabIndex={0} ref={el => itemsRef.current[4] = el} item xs={12}>
          <CompanySettingsBox icon={<EventBusyIcon sx={{fontSize: 50}}/>} label="振替休日付与設定">
            <NoCompensatoryHolidays
              states={states.noCompensatoryHolidays}
              state={state.noCompensatoryHolidays}
              handleSubmit={() => {
                handleSubmit('noCompensatoryHolidays')
              }}
              handleEdit={(id) => {
                handleEdit(id, 'noCompensatoryHolidays')
              }}
              handleDelete={(id) => {
                handleDelete(id, 'noCompensatoryHolidays')
              }}
              crud={crud}
              errors={errors.noCompensatoryHolidays}
              handleChange={(e) => {handleChange(e, 'noCompensatoryHolidays')}}
              handleOpen={(data, type) => {handleOpen(data as CRUTDataType, type, 'noCompensatoryHolidays')}}
              handleClose={handleClose}
              handleCRUDChange={handleCRUDChange}
              users={states.users}
              />
          </CompanySettingsBox>
        </Grid>
        <Grid tabIndex={0} ref={el => itemsRef.current[5] = el} item xs={12}>
          <CompanySettingsBox icon={<ApartmentIcon sx={{fontSize: 50}}/>} label="休校日設定">
            <Holiday
              states={states.holidays}
              state={state.holidays}
              handleSubmit={() => {
                handleSubmit('holidays')
              }}
              handleEdit={(id) => {
                handleEdit(id, 'holidays')
              }}
              handleDelete={(id) => {
                handleDelete(id, 'holidays')
              }}
              crud={crud}
              errors={errors.holidays}
              handleChange={(e) => {handleChange(e, 'holidays')}}
              handleOpen={(data, type) => {handleOpen(data as CRUTDataType, type, 'holidays')}}
              handleClose={handleClose}
              handleCRUDChange={handleCRUDChange}
              />
          </CompanySettingsBox>
        </Grid>
      </Grid>
    </BaseLayout>
  )
}

export default CompanySettingsLayout