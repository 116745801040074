import React, {useEffect} from 'react'
import {notificationReducer} from '../store/notification'
import {NotificationContext} from './notificationContext'
import useWebSocket from 'react-use-websocket'
import {webSocketURL} from '../store/store'
import {useAuth} from '../hooks/useAuth'
import {SET_ERROR_NOTIFICATIONS, SET_ACTOR_NOTIFICATIONS, SET_RECIPIENT_NOTIFICATIONS} from '../store/constants'
import useAuthAxios from '../hooks/useAuthAxios'

interface NotificationProviderProps {
  children: React.ReactNode
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({children}) => {
  const [state, dispatch] = React.useReducer(
    notificationReducer, {actorNotifications: [], errorNotifications: [], recipientNotifications: []})
  const { auth } = useAuth();
  const authAxios = useAuthAxios();

  useWebSocket(!auth.access ? null: `${webSocketURL}/notifications/`, {
		onMessage: (e) => {
      const data = JSON.parse(e.data);
      if (data.type === 'actor') {
        dispatch({type: SET_ACTOR_NOTIFICATIONS, payload: data.message})
      } else if (data.type === 'recipient') {
        dispatch({type: SET_RECIPIENT_NOTIFICATIONS, payload: data.message})
      } else if (data.type === 'error') {
        dispatch({type: SET_ERROR_NOTIFICATIONS, payload: data.message})
      }
    }
  });

  useEffect(() => {
    authAxios.get('/notifications/recipient_queryset/')
      .then((response) => {
        dispatch({type: SET_RECIPIENT_NOTIFICATIONS, payload: response.data})
      })

    authAxios.get('/notifications/actor_queryset/')
      .then((response) => {
        dispatch({type: SET_ACTOR_NOTIFICATIONS, payload: response.data})
      })

    authAxios.get('/notifications/error_queryset/')
      .then((response) => {
        dispatch({type: SET_ERROR_NOTIFICATIONS, payload: response.data})
      })
  }, [])

  return (
    <NotificationContext.Provider value={{state: state, dispatch: dispatch}}>
      {children}
    </NotificationContext.Provider>
  )
}
