export type PTORequestBase = {
  requester: number
  start: string
  end: string
  note: string
  vacation_type: number
  approver: number
}

export type PTORequestApplication = PTORequestBase & {
  start_display?: string
  end_display?: string
  vacation_type_display?: string
  approver_display?: string
}

export type PTORequestDetail = PTORequestApplication & {
	status: "pending" | "completed" | "send_back" | "rejected"| "";
	status_display: string;
	created_dt: string;
	comment?:string;
}

export const defaultPTORequestDetail: PTORequestDetail = {
  start: "",
  end: "",
  vacation_type: 0,
  approver: 0,
  note: "",
  requester: 0,
  status: "",
  status_display: "",
  created_dt: ""
}

export const defaultPTORequestApplication: PTORequestApplication = {
	start: "",
	end: "",
	vacation_type: 0,
	approver: 0,
	note: "",
  requester: 0
}

export type PTODateList = {
  year: number
  month: number
  day: number
}

export type PTORequest = PTORequestBase & {
  id: number
  status: string
  created_dt: string
  start_display: string
  end_display: string
  vacation_type_display: string
  approver_display: string
  date_list: PTODateList[]
  comment: string
  requester_display: string
  status_display: string
}

export type PTOInfo = {
  current_total: number
  prev_total: number
  remaining_max_requests: number
}

export type PTORequestDataT = {
  id: number
  date: string
  date_display: string
  day: number
  hours: number
  is_hourly: boolean
  created_dt: string
  note: string
}


export type CreatePTO = {
  year: number
  hours: number
}