import React from 'react'
import {ModalTable} from '../../index'
import {PTORequestDetail} from '../../../store/pto'

interface ReadSentApplicationProps {
	state: PTORequestDetail;
}

export const ReadSentRestApplication: React.FC<ReadSentApplicationProps> = ({state}) => {
	return (
		<ModalTable>
			<caption>申請日: {state.created_dt}</caption>
			<tr>
				<th>ステータス</th>
				<td>
					{state.status_display}
				</td>
			</tr>
			<tr>
				<th>申請項目</th>
				<td>
					{state.vacation_type_display}
				</td>
			</tr>
			<tr>
				<th>休暇希望日</th>
				<td>{state.start_display}{state.end_display && `〜${state.end_display}`}</td>
			</tr>
			<tr>
				<th>承認者</th>
				<td>{state.approver_display}</td>
			</tr>
			<tr>
				<th>申請備考</th>
				<td>{state.note}</td>
			</tr>
		</ModalTable>
	);
};