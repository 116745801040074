import {styled} from '@mui/material/styles'
import {TableCell, tableCellClasses, TableRow, tableRowClasses} from '@mui/material'

export const CompanySettingsStyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
	  whiteSpace: "nowrap",
	  padding: 5,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
	  whiteSpace: "nowrap",
	  padding: 5,
  },
}));

export const CompanySettingsStyledTableRow = styled(TableRow)(() => ({
  [`&.${tableRowClasses.head}`]: {
    borderTop: "2px solid #ccc",
	  borderBottom: "2px solid #ccc",
  },
	'&': {
    backgroundColor: "#fff",
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));