import React from "react"
import {Button, Stack} from "@mui/material"
import {useTimecardChangeReceiveApplication} from './useTimecardChangeReceiveApplication'
import {ReadTimeCardChangeSentApplication} from './ReadTimeCardChangeSentApplication'
import {ModalLayout} from '../index'

interface TimeCardChangeReceiveApplicationProps {
	id: number;
	handleClose: () => void;
}

const TimeCardChangeReceiveApplication: React.FC<TimeCardChangeReceiveApplicationProps> = ({handleClose, id}) => {
	const {state, errors, handleChange, handleSubmit, handleDrop} = useTimecardChangeReceiveApplication(id, handleClose);

	if (!state){
		return <></>
	}

	return (
		<ModalLayout title="打刻時間変更申請">
			<ReadTimeCardChangeSentApplication handleChange={handleChange} errors={errors} state={state} />
			<Stack
					direction={{xs:"row", ms:"column"}} spacing={3}
				>
					<Button type="button" variant="contained" color="inherit" sx={{flex:1}} onClick={handleClose}>戻る</Button>
					{
						state.status === "pending"
						&&
						<>
								<Button type="button" onClick={handleDrop} variant="outlined" color="error" sx={{flex:1}}>差し戻し</Button>
								<Button type="button" onClick={handleSubmit} variant="outlined" sx={{flex:1}}>承認</Button>
						</>
					}
				</Stack>
		</ModalLayout>
	)
}

export default TimeCardChangeReceiveApplication