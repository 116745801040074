import React from 'react'
import {Box, Divider, Paper, Stack, Typography} from '@mui/material'

interface CompanySettingsBoxProps {
	icon: React.ReactNode;
	label: string;
	children: React.ReactNode;
}
export const CompanySettingsBox:React.FC<CompanySettingsBoxProps> = ({icon, children, label}) => {
	return(
		<Paper elevation={2} sx={{p:2, height: {xs: 530, sm:350}}}>
			<Stack direction={{xs: "column", sm: "row"}} divider={<Divider orientation="vertical" flexItem />} sx={{alignItems: "center"}} justifyContent="space-between" spacing={1}>
				<Box display="flex" justifyContent="center" alignItems="center" width="30%">
					<Typography variant="h5" sx={{verticalAlign: 'middle', display: 'inline-flex', flexDirection: "column", alignItems: "center"}}>
						{icon} {label}
					</Typography>
				</Box>
				<Box width={{xs: "100%", sm:"70%"}}>
					{children}
				</Box>
			</Stack>
		</Paper>
	);
};