import React from 'react'
import {useTheme} from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {Box, Button, Typography} from '@mui/material'
import {TimecardStatus} from '../../../store/timecard'

interface TimeCardButtonsProps {
  status: TimecardStatus | null;
  handleStart: () => void;
  handleEnd: () => void;
}

const buttonStyle = {
  bgcolor: 'primary.dark',
  color: '#fff',
  px: 7,
  fontSize: 25,
}

export const TimeCardActionButtons: React.FC<TimeCardButtonsProps> = (props) => {
  const {status, handleStart, handleEnd} = props
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.only('xs'))

  return (
    <>
      <Box sx={{display: 'flex', justifyContent: 'center'}} mb={2}>
        {
          !matches
            ?
            <>
              <Button
                onClick={handleStart}
                color="secondary"
                variant="contained"
                sx={{...buttonStyle, bgcolor: '#f19ec6', mr: 1}}
                disabled={status?.status === 'pending' || status?.status === 'completed'}
              >
                出勤
              </Button>
              <Button
                onClick={handleEnd}
                color="secondary"
                variant="contained"
                sx={buttonStyle}
                disabled={status?.status === 'not_found' || status?.status === 'completed'}
              >
                退勤
              </Button>
            </>
            :
            <>
              {
                status?.status === 'not_found' &&
                <Button
                  onClick={handleStart}
                  color="secondary"
                  variant="contained"
                  sx={{...buttonStyle, bgcolor: '#f19ec6', mr: 1}}
                >
                  出勤
                </Button>
              }
              {status?.status === 'pending' &&
                <Button
                  onClick={handleEnd}
                  color="secondary"
                  variant="contained"
                  sx={buttonStyle}
                >
                  退勤
                </Button>
              }
            </>
        }
      </Box>
      <Typography sx={{width: '100%', textAlign: {xs: 'center', md: 'left'}}}>
        {status?.status === 'not_found' ? 'おはようございます。' : 'お疲れ様です。'}
      </Typography>
    </>
  )
}