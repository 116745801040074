import axios from "axios";
import {apiURL} from '../store/store'

const BASE_URL = apiURL;

export default axios.create({ baseURL: BASE_URL });

export const authAxios = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});