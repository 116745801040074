import React from 'react'
import {CompanySettingsTable} from './Table/CompanySettingsTable'
import {MenuItem, TextField, Typography} from '@mui/material'
import {ModalBase, ModalLayout, ModalTable} from '../../../components'
import {DeleteForm} from './DeleteForm'
import {EditForm} from './EditForm'
import {CrudT, CRUTDataType, noCompensatoryHolidayLabels, NoCompensatoryHolidayT} from '../../../store/company'
import {MinimalUserDetail} from '../../../store/users'


interface NoCompensatoryHolidaysProps {
  states: NoCompensatoryHolidayT[];
  users: MinimalUserDetail[];
  handleSubmit: () => void;
  handleEdit: (id: number) => void;
  handleDelete: (id: number) => void;
  state?: NoCompensatoryHolidayT;
  crud: CrudT<CRUTDataType>;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errors?: { [key: string]: any };
  handleOpen: (data: NoCompensatoryHolidayT, type: 'edit' | 'delete') => void;
  handleClose: () => void;
  handleCRUDChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}


export const NoCompensatoryHolidays: React.FC<NoCompensatoryHolidaysProps> = (props) => {
  const {
    states,
    users,
    handleSubmit,
    state,
    handleChange,
    errors,
    crud,
    handleEdit,
    handleDelete,
    handleOpen,
    handleClose,
    handleCRUDChange,
  } = props
  return (
    <CompanySettingsTable
      labels={noCompensatoryHolidayLabels}
      data={states}
      handleSubmit={handleSubmit}
      handleEdit={(data) => {
        handleOpen(data, 'edit')
      }}
      handleDelete={(data) => {
        handleOpen(data, 'delete')
      }}
    >
      <Typography>職員を追加：</Typography>
      <TextField
        size="small"
        placeholder="職員を選択"
        name="user"
        value={state?.user || ''}
        onChange={handleChange}
        error={errors?.user && true}
        helperText={errors?.user ?
          errors.user?.map((val: string) => val).join(', ') : ''}
        sx={{
          width: {xs: '100%', sm: 'auto'},
          '& .MuiSelect-select .notranslate::after': '職員を選択'
            ? {
              content: `"職員を選択"`,
              opacity: 0.42,
            }
            : {},
        }}
        select
      >
        <MenuItem value="" sx={{opacity: 0.42}}>職員を選択</MenuItem>
        {
          users?.map((value) => (
            <MenuItem key={value.id} value={value.id}>{value.full_name}</MenuItem>
          ))
        }
      </TextField>
      <ModalBase open={crud.open && crud.crudType === 'noCompensatoryHolidays'} handleClose={handleClose}>
        <ModalLayout title={crud.type === 'delete' ? '削除' : '編集'}>
          {
            crud.type === 'delete'
              ?
              <DeleteForm id={crud?.data?.id || 0} handleDelete={handleDelete} handleClose={handleClose}>
                <ModalTable>
                  <caption>職員の削除</caption>
                  <tbody>
                  <tr>
                    <th>職員名</th>
                    <td>
                      {crud.data?.full_name}
                    </td>
                  </tr>
                  </tbody>
                </ModalTable>
              </DeleteForm>
              :
              <EditForm handleEdit={handleEdit} handleClose={handleClose} id={crud?.data?.id || 0}>
                <ModalTable>
                  <caption>職員の編集</caption>
                  <tbody>
                  <tr>
                    <th>職員名</th>
                    <td>
                      <TextField
                        size="small"
                        placeholder="職員を選択"
                        name="user"
                        value={crud.data?.user}
                        onChange={handleCRUDChange}
                        error={crud?.error?.user && true}
                        helperText={
                          crud?.error?.user.map((val: string) => (val)).join(', ')
                        }
                        sx={{
                          width: {xs: '100%', sm: 'auto'},
                          '& .MuiSelect-select .notranslate::after': '職員を選択'
                            ? {
                              content: `"職員を選択"`,
                              opacity: 0.42,
                            }
                            : {},
                        }}
                        select
                      >
                        <MenuItem value="" sx={{opacity: 0.42}}>職員を選択</MenuItem>
                        {
                          users?.map((value) => (
                            <MenuItem key={value.id} value={value.id}>{value.full_name}</MenuItem>
                          ))
                        }
                      </TextField>
                    </td>
                  </tr>
                  </tbody>
                </ModalTable>
              </EditForm>
          }
        </ModalLayout>
      </ModalBase>
    </CompanySettingsTable>
  )
}