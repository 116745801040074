import React, {useEffect, useState} from 'react'
import useAuthAxios from '../../hooks/useAuthAxios'
import {TimecardChangeT, defaultTimecardChange} from '../../store/timecard'
import {useTimecard} from '../../hooks/useTimecard'
import {useAlertMessage} from '../../hooks/useAlertMessage'

export const useTimeCardChange = (
  handleClose: () => void,
  id?: number,
  date?: string,
  user_id?: string | null

) => {
  const [state, setState] = useState<TimecardChangeT>(defaultTimecardChange)
	const [errors, setErrors] = useState<{[key:string]: any}>({})
	const {handleTimecardChange} = useTimecard<TimecardChangeT>()
	const {setAlertMessage} = useAlertMessage()
	const axios = useAuthAxios()

	useEffect(()=>{
		if (id){
			axios.get(`/timecard/change_request/${id}/`)
			.then((res)=>{
				setState({...res.data, user_id: user_id});
			})
				.catch()
		}
		else{
			setState(prevState => ({...prevState, date: date, user_id: user_id}));
		}
	},[id, axios, date, user_id])

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		handleTimecardChange(event, setState);
	}

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		let cp_data = state;
		if (cp_data["new_start"] === "" && cp_data["new_end"] === ""){
			handleClose();
		}
		else {
			if (cp_data["new_start"] === ""){
				delete cp_data.new_start
			}
			if (cp_data["new_end"] === ""){
				delete cp_data.new_end
			}
			axios.put(`/timecard/change_request/${id || 0}/`,state)
				.then((res)=>{
					setState(res.data);
					setAlertMessage({message: "打刻時間変更を申請しました", open: true, status: "success"});
					handleClose();
				})
				.catch((res) => {
					setErrors(res.response.data);
					setAlertMessage({message: "打刻時間変更申請できませんでした", open: true, status: "error"});
				})
		}
	}

  return {
    state,
    errors,
    handleChange,
    handleSubmit
  }
}