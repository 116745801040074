import React from "react";
import {Box, Button, Typography} from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {Link} from "react-router-dom";
import {MonthlyDataBannerT} from '../../../store/monthlyData'

interface MonthlyDataBannerProps{
	state: MonthlyDataBannerT | null;
}
const MonthlyDataBanner: React.FC<MonthlyDataBannerProps> = ({state}) => {

	if (state === null) {
		return <></>
	}
	return(
		<Box
			sx={{
				textAlign: {xs: "center", md: "left"},
				display:"flex",
				bgcolor: "secondary.light",
				justifyContent: {xs:"center", md:"space-between"},
				flexWrap: {xs:"wrap", md:"nowrap"},
				flexFlow: {xs:"column", lg:"row"},
				alignItems: "center",
				p: 2,
				borderRadius: 1,
				mb: {md: 2}
			}}
		>
			<Box display="block">
				<Typography noWrap gutterBottom variant="h4" sx={{
					fontSize: {xs: "1.5rem", sm: "1.75rem", lg: "2.125rem"}
				}}>
					<b>{state?.month}月の月締申請をしてください。</b>
				</Typography>
				<Typography
					variant="h4"
					color="primary.main"
					sx={{
						mb: {xs: 1, md: 0},
						fontSize: {xs: "1.2rem", sm: "1.5rem"}
					}}
				>
					<b>※申請期日:{state?.recruitment_end}まで</b>
				</Typography>
			</Box>
			<Button
				size="large"
				component={Link}
				to={`/daily-attendance/${state?.year}/${state?.month}/`}
				variant="contained"
				color="primary"
				sx={{
					color: "primary.light",
					mt:{sm:2, lg:0}
				}}
				endIcon={<KeyboardArrowRightIcon />}>
					<Typography color="primary.light">
						確認して申請する
					</Typography>
			</Button>
		</Box>
	);
}

export default MonthlyDataBanner;