import React from 'react'
import {ModalLayout} from '../../../components'
import {
  Box,
  Button,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper, TextField,
} from '@mui/material'
import {styled} from '@mui/material/styles'
import {DetailedUserPTOCalendarT, UserDetail} from '../../../store/users'
import {dateStringToJpString} from '../../../utils/date'

const StyledTableHeaderCell = styled(TableCell)(() => ({
  textAlign: 'center',
  backgroundColor: '#f2f2f2',
}))

const StyledTableValueCell = styled(TableCell)(() => ({
    textAlign: 'center'
}))

interface UserPTOCalendarEditProps {
  handlePTOCalendarEditModalClose: () => void
  ptoData?: DetailedUserPTOCalendarT | null
  user?: UserDetail | null
  handlePTOCalendarChange: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  handlePTOCalendarEditSave: () => void
}

export const UserPTOCalendarEdit: React.FC<UserPTOCalendarEditProps> = (props) => {
  const {
    handlePTOCalendarEditModalClose,
    ptoData,
    user,
    handlePTOCalendarChange,
    handlePTOCalendarEditSave
  } = props
  return (
    <ModalLayout title="編集">
      <Box p={2}>
        <Typography variant="h6">対象日 {dateStringToJpString(ptoData?.start || '')} 〜 {dateStringToJpString(ptoData?.end || '')}</Typography>
        <Typography variant="h6">氏名 {user?.full_name}</Typography>
        <TableContainer component={Paper}>
          <Table border={1} sx={{borderColor: 'inherit', textAlign: 'center'}}>
            <TableBody>
              <TableRow>
                <StyledTableHeaderCell>部署</StyledTableHeaderCell>
                <StyledTableValueCell>{user?.department_name}</StyledTableValueCell>
                <StyledTableHeaderCell rowSpan={3}>時間年休</StyledTableHeaderCell>
                <StyledTableHeaderCell>取得MAX</StyledTableHeaderCell>
                <StyledTableValueCell colSpan={2}>40時間</StyledTableValueCell>
              </TableRow>
              <TableRow>
                <StyledTableHeaderCell>入社年月日</StyledTableHeaderCell>
                <StyledTableValueCell>{user?.hire_dt ? dateStringToJpString(user.hire_dt) : '-'}</StyledTableValueCell>
                <StyledTableHeaderCell>取得時間</StyledTableHeaderCell>
                <StyledTableValueCell colSpan={2}>{ptoData?.used_2_hours}時間</StyledTableValueCell>
              </TableRow>
              <TableRow>
                <StyledTableHeaderCell>前年繰越日数</StyledTableHeaderCell>
                <StyledTableValueCell>
                  <TextField
                    name="prev_total"
                    value={ptoData?.prev_total}
                    onChange={handlePTOCalendarChange}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: 0.5
                      },
                      endAdornment: <Typography>日</Typography>
                    }}

                  />
                </StyledTableValueCell>
                <StyledTableHeaderCell>残り時間</StyledTableHeaderCell>
                <StyledTableValueCell colSpan={2}>{ptoData?.remaining_used_2_hours}時間</StyledTableValueCell>
              </TableRow>
              <TableRow>
                <StyledTableHeaderCell>今年度付与日数</StyledTableHeaderCell>
                <StyledTableValueCell>
                  <TextField
                    name="current_total"
                    value={ptoData?.current_total}
                    onChange={handlePTOCalendarChange}
                    type="number"
                    InputProps={{
                      inputProps: {
                        min: 0,
                        step: 0.5
                      },
                      endAdornment: <Typography>日</Typography>
                    }}
                  />
                </StyledTableValueCell>
                <StyledTableHeaderCell rowSpan={2}>年休</StyledTableHeaderCell>
                <StyledTableHeaderCell>取得日数</StyledTableHeaderCell>
                <StyledTableValueCell>{ptoData?.used_days_hours_display}日</StyledTableValueCell>
                <StyledTableValueCell>{ptoData?.used_2_hours_display}時間</StyledTableValueCell>
              </TableRow>
              <TableRow>
                <StyledTableHeaderCell>今年度利用可能日数</StyledTableHeaderCell>
                <StyledTableValueCell>{ptoData?.total_usable_pto}日</StyledTableValueCell>
                <StyledTableHeaderCell>残り日数</StyledTableHeaderCell>
                <StyledTableValueCell>{ptoData?.remaining_pto_days}日</StyledTableValueCell>
                <StyledTableValueCell>{ptoData?.remaining_pto_hours}時間</StyledTableValueCell>
              </TableRow>
              <TableRow><TableCell colSpan={6}/></TableRow>
              <TableRow>
                <StyledTableHeaderCell>申請日</StyledTableHeaderCell>
                <StyledTableHeaderCell>取得日</StyledTableHeaderCell>
                <StyledTableHeaderCell>全日取得日数</StyledTableHeaderCell>
                <StyledTableHeaderCell>時間取得時間</StyledTableHeaderCell>
                <StyledTableHeaderCell colSpan={2}>備考</StyledTableHeaderCell>
              </TableRow>
              {
                ptoData?.pto_requests?.map((ptoRequest, index) => (
                  <TableRow key={index}>
                    <StyledTableValueCell>{ptoRequest.created_dt}</StyledTableValueCell>
                    <StyledTableValueCell>{ptoRequest.date_display}</StyledTableValueCell>
                    <StyledTableValueCell>{ptoRequest.day || ''}</StyledTableValueCell>
                    <StyledTableValueCell>{ptoRequest.hours || ''}</StyledTableValueCell>
                    <StyledTableValueCell colSpan={2} sx={{whiteSpace: 'break-spaces'}}>{ptoRequest.note}</StyledTableValueCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Stack position='sticky' bottom={0} direction={{xs:"row", ms:"column"}} spacing={3}>
        <Button
          type="button"
          variant="contained"
          color="inherit"
          onClick={handlePTOCalendarEditModalClose}
        >
          キャンセル
        </Button>
        <Button
          type="button"
          variant="contained"
          color="primary"
          onClick={handlePTOCalendarEditSave}
        >
          保存
        </Button>
      </Stack>
    </ModalLayout>
  )
}