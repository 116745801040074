import React from "react";
import {TextField, Typography} from "@mui/material";
import {CompanyGroup, CrudT, CRUTDataType, groupLabels} from '../../../store/company'
import {CompanySettingsTable} from './Table/CompanySettingsTable'
import {DeleteForm} from './DeleteForm'
import {EditForm} from './EditForm'
import {ModalBase, ModalLayout, ModalTable} from '../../../components'


interface GroupProps {
	states: CompanyGroup[];
	handleSubmit: () => void;
	handleEdit: (id: number) => void;
	handleDelete: (id: number) => void;
	state?: CompanyGroup;
	crud: CrudT<CRUTDataType>;
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	errors?: {[key:string]: any};
	handleOpen: (data: CompanyGroup, type: "edit" | "delete") => void;
	handleClose: () => void;
	handleCRUDChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Group: React.FC<GroupProps> = (props) => {
	const {
		states,
		handleSubmit,
		state,
		handleChange,
		errors,
		crud,
		handleEdit,
		handleDelete,
		handleOpen,
		handleClose,
		handleCRUDChange
	} = props;

	return(
		<CompanySettingsTable
			labels={groupLabels}
			data={states}
			handleSubmit={handleSubmit}
			handleEdit={(data) => {handleOpen(data, "edit")}}
			handleDelete={(data) => {handleOpen(data, "delete")}}
		>
			<Typography>グループを追加：</Typography>
			<TextField
				size="small"
				placeholder="グループ名を入力"
				name="name"
				value={state?.name}
				onChange={handleChange}
				error={errors?.name && true}
				helperText={errors?.name ?
          errors.name?.map((val: string) => val).join(', ') : ""}
				sx={{
						width: {xs: "100%", sm:"auto"}
					}}
			/>
			<ModalBase open={crud.open && crud.crudType === 'groups'} handleClose={handleClose}>
				<ModalLayout title={ crud.type === "delete" ? "削除" : "編集"}>
					{
						crud.type === "delete"
						?
						<DeleteForm id={crud?.data?.id || 0} handleDelete={handleDelete} handleClose={handleClose}>
							<ModalTable>
								<caption>グループの削除</caption>
								<tbody>
								<tr>
									<th>グループ名</th>
									<td>
										{crud.data?.name}
									</td>
								</tr>
								</tbody>
							</ModalTable>
						</DeleteForm>
						:
						<EditForm handleEdit={handleEdit} handleClose={handleClose} id={crud?.data?.id || 0}>
							<ModalTable>
								<caption>グループの編集</caption>
								<tbody>
									<tr>
										<th>グループ名</th>
										<td>
											<TextField
												size="small"
												name="name"
												value={crud.data?.name}
												onChange={handleCRUDChange}
												error={crud?.error?.name && true}
												helperText={
													crud?.error?.name.map((val:string) => (val)).join(', ')
												}
											/>
										</td>
									</tr>
								</tbody>
							</ModalTable>
						</EditForm>
					}
				</ModalLayout>
			</ModalBase>
		</CompanySettingsTable>
	);
};