import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {useNotification} from '../../hooks/useNotification'
import {Box, List, ListItemButton, Typography} from '@mui/material'
import {isNotificationMonthlyData} from '../../store/notification'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import {NotificationBox} from './NotificationBox'
import {SentRestApplication} from './RestApplication/SentRestApplication'
import {ModalBase, TimeCardChangeSentApplication} from '../index'

const SentApplication: React.FC = () => {
	const [open, setOpen] = useState({isOpen: false, name: "", id: 0});
	const navigate = useNavigate();
	const {actorNotifications} = useNotification()

	const handleOpen = (name="", id: number) => {
		setOpen({isOpen: true, name: name, id: id})
	}
	const handleClose = () => {
		setOpen({isOpen: false, name: "", id: 0})
	}

	return (
		<NotificationBox title="申請履歴" count={actorNotifications.length}>
			<List>
				{
					actorNotifications.map((val, index) => (
						<ListItemButton key={index} onClick={()=>
							{
								isNotificationMonthlyData(val.action_object) ?
									navigate(`/daily-attendance/${val?.action_object?.year}/${val?.action_object?.month}`) :
									handleOpen(val?.action_object?.action_type, val?.action_object?.id)
							}
						} divider disableGutters sx={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
							<Box>
								<Box mb={0.5}>
									<Box fontSize={12} px={1} component="span" color={val.action_object?.status === "completed" ? "gray" : ""}  className="ct-tag" bgcolor={val.action_object?.status === "send_back" ? "#f19ec6" :"secondary.dark"}>{val?.action_object?.status_display}</Box>
									<Box fontSize={12} component="span" color="primary.contrastText">申請日:{isNotificationMonthlyData(val.action_object) ? val?.timestamp : val.action_object.created_dt}</Box>
								</Box>
								<Box>
									<Typography component="span" fontSize={12} color="primary" fontWeight="normal">
										{val?.action_object?.type} | {isNotificationMonthlyData(val.action_object) ? val.action_object.notification_text : val.action_object?.date}
									</Typography>
								</Box>
							</Box>
							<KeyboardArrowRightIcon color="primary" fontSize="large" />
						</ListItemButton>
					))
				}
			</List>
			<ModalBase open={open.isOpen} handleClose={handleClose}>
				{
					open.name === "rest" && <SentRestApplication id={open.id} handleClose={handleClose} />
				}
				{
					open.name === "timecard_change" && <TimeCardChangeSentApplication id={open.id} handleClose={handleClose} />
				}
			</ModalBase>
		</NotificationBox>
	);
};

export default SentApplication;