import React from 'react'
import {Box, Button, Paper, Stack, Table, TableBody, TableContainer, TableHead} from '@mui/material'
import {CompanySettingsStyledTableCell, CompanySettingsStyledTableRow} from './TableStyles'
import {CompanySettingsCollapseTableRow} from './CompanySettingsCollapseTableRow'
import {CompanySettingsTableDataT} from './CompanySettingsTable'

export type CompanySettingsCollapseLabelsT = {
	main:{name: string, keyName: string}[],
	collapse:{name: string, keyName: string}[],
	collapseTitle: string;
}

export type CompanySettingsCollapseTableDataT<K extends CompanySettingsTableDataT> = CompanySettingsTableDataT & {
	data: K[]
}

export interface CompanySettingsCollapseTableProps<K extends CompanySettingsTableDataT, T extends CompanySettingsCollapseTableDataT<K>> {
	children: React.ReactNode,
	labels: CompanySettingsCollapseLabelsT
	data: T[];
	handleSubmit: () => void;
	handleEdit: (data: K) => void;
	handleDelete: (data: K) => void;
}

export const CompanySettingsCollapseTable = <K extends CompanySettingsTableDataT, T extends CompanySettingsCollapseTableDataT<K>>(props: CompanySettingsCollapseTableProps<K, T>) => {
	const {children, handleSubmit, labels, data, handleDelete, handleEdit} = props;
	return (
		<Box>
			<Stack
				spacing={1}
				direction={{xs: "column", sm:"row"}}
				sx={{
					justifyContent: {sm: "end"},
					alignItems: "center",
					mb: 1
				}}
			>
				{children}
				<Button
					size="small"
					type="button"
					variant="contained"
					onClick={handleSubmit}
					sx={{
						width: {xs: "100%", sm:"auto"}
					}}
				>
					追加
				</Button>
			</Stack>
			<TableContainer component={Paper} sx={{width:"100%", overflow: "scroll", maxHeight: 300, minHeight: 300}}>
		    <Table aria-label="collapsible table" stickyHeader>
		      <TableHead>
		          <CompanySettingsStyledTableRow>
			          <CompanySettingsStyledTableCell />
								{
									labels.main?.map((value, index) => (
										<CompanySettingsStyledTableCell align="left" key={index}>{value.name}</CompanySettingsStyledTableCell>
									))
								}
							</CompanySettingsStyledTableRow>
		      </TableHead>
		      <TableBody>
		        {data.map((row, index) => (
		          <CompanySettingsCollapseTableRow handleDelete={handleDelete} handleEdit={handleEdit} key={index} row={row} labels={labels} />
		        ))}
		      </TableBody>
		    </Table>
		  </TableContainer>
		</Box>
	)
}