import React from 'react'
import {Alert, Snackbar} from '@mui/material'
import {AlertMessageContext} from './alertMessageContext'
import {alertMessageReducer, defaultAlertMessageState} from '../store/alertMessage'
import {CLEAR_ALERT_MESSAGE} from '../store/constants'

interface AlertMessageProviderProps {
  children: React.ReactNode
}

export const AlertMessageProvider: React.FC<AlertMessageProviderProps> = ({children}) => {
  const [state, dispatch] = React.useReducer(alertMessageReducer, defaultAlertMessageState)

  return (
		<AlertMessageContext.Provider value={{state, dispatch}} >
			<Snackbar
				open={state.open}
				autoHideDuration={1500}
				sx={{width: "80%"}}
				anchorOrigin={{
		      vertical: "top",
		      horizontal: "center"
				}}
				onClose={() => {
          dispatch({type: CLEAR_ALERT_MESSAGE})
        }}>
            <Alert severity={state.status} sx={{ width: '100%' }}>
                {state.message}
            </Alert>
        </Snackbar>
			{children}
		</AlertMessageContext.Provider>
	)
}