import React from 'react'
import {Paper, Stack, Typography} from '@mui/material'
import {RenderLabel} from './RenderLabel'
import {RenderTime} from './RenderTime'
import {MonthlyDataT} from '../../store/monthlyData'

interface MonthlyInfoProps {
  data: MonthlyDataT;
}

export const MonthlyInfo: React.FC<MonthlyInfoProps> = ({data}) => {
	return (
		<Paper elevation={2} sx={{m:2}}>
			<Stack direction="row" sx={{flexWrap: "wrap", justifyContent: "flex-start"}} py={2} px={{xs: 2, sm: 4, lg: 8}}>
				<RenderLabel label="出勤日数">
					{data?.total_workdays}<Typography component="small">日</Typography>
				</RenderLabel>
				<RenderLabel label="出勤時間">
					<RenderTime time={data?.total_workhours || ""} />
				</RenderLabel>
				<RenderLabel label="欠勤日数">
					{data?.absents}<Typography component="small">日</Typography>
				</RenderLabel>
				<RenderLabel label="休日労働">
					<RenderTime time={data?.holiday_workhours || ""} />
				</RenderLabel>
				<RenderLabel label="有給取得数">
					{data?.used_pto_days}<Typography component="small">日</Typography>{data?.used_pto_2_hours}<Typography component="small">時間</Typography>
				</RenderLabel>
				<RenderLabel label="特別休暇日数">
					{data?.special_pto}<Typography component="small">日</Typography>
				</RenderLabel>
				<RenderLabel label="遅刻早退時間">
					<RenderTime time={data?.insufficient || ""} />
				</RenderLabel>
				<RenderLabel label="有給残日数">
					{data?.remaining_days_pto}<Typography component="small">日</Typography>{data?.remaining_hours_pto}<Typography component="small">時間（2時間休取得残数：{data?.max_requests_remaining}回）</Typography>
				</RenderLabel>
				<RenderLabel label="振替休日残日数">
					{data?.transfer_remaining}<Typography component="small">日</Typography>
				</RenderLabel>
			</Stack>
		</Paper>
	);
};