import React from 'react'
import {Button, FormControl, Stack, TextField} from '@mui/material'
import {useTimeCardChange} from './useTimeCardChange'
import {ModalLayout, ModalTable} from '../index'

const styles = {
  th: {
    borderLeft: 0,
    borderRight: 0,
  },
  td: {
    borderLeft: 0,
    borderRight: 0,
  },
}
const Th: React.FC<{ children: React.ReactNode }> = ({children}) => {
  return (
    <th style={styles.th}>{children}</th>
  )
}

const Td: React.FC<{ children: React.ReactNode, label?: string }> = ({children, label}) => {
  return (
    <td style={styles.td} data-content={label}>{children}</td>
  )
}

interface TimeCardChangeProps {
  id?: number;
  date?: string;
  handleClose: () => void;
  user_id?: string | null;
}

const TimeCardChange: React.FC<TimeCardChangeProps> = ({id, handleClose, date, user_id}) => {
  const {
    state,
    errors,
    handleChange,
    handleSubmit,
  } = useTimeCardChange(handleClose, id, date, user_id)
  return (
    <ModalLayout title="打刻時間の変更">
      <FormControl component="form" method="post" fullWidth id="change-request-form" onSubmit={handleSubmit}>
        <ModalTable className="mb-table">
          <caption>{state.date}</caption>
          <thead>
          <tr>
            <Th>打刻内容</Th>
            <Th>修正前打刻</Th>
            <Th>修正後打刻</Th>
            <Th>修正理由</Th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <Td label="打刻内容"><b style={{fontSize: '1.3em'}}>出勤</b></Td>
            <Td label="修正前打刻">{state.old_start_display || '-'}</Td>
            <Td label="修正後打刻">
              <TextField
                size="small"
                value={state.new_start}
                name="new_start"
                onChange={handleChange}
                error={errors.new_start && true}
                helperText={errors.new_start ?
                  errors.new_start.map((val: string) => val).join(', ') : '例：08:15'}
              />
            </Td>
            <Td label="修正理由">
              <TextField
                size="small"
                value={state.reasons.start}
                name="start"
                onChange={handleChange}
                error={errors.reasons?.start && true}
                helperText={errors.reasons?.start ?
                  errors.reasons?.start.map((val: string) => val).join(', ') : '例：打刻忘れ'}
                inputProps={{
                  'data-name': 'reasons',
                }}
              />
            </Td>
          </tr>
          {
            (state.old_end || !id || state.new_end)
            &&
            <tr>
              <Td label="打刻内容"><b style={{fontSize: '1.3em'}}>退勤</b></Td>
              <Td label="修正前打刻">{state.old_end_display || '-'}</Td>
              <Td label="修正後打刻">
                <TextField
                  size="small"
                  value={state.new_end}
                  name="new_end"
                  onChange={handleChange}
                  error={errors.new_end && true}
                  helperText={errors.new_end ?
                    errors.new_end.map((val: string) => val).join(', ') : '例：17:00'}
                />
              </Td>
              <Td label="修正理由">
                <TextField
                  size="small"
                  value={state.reasons.end}
                  name="end"
                  onChange={handleChange}
                  error={errors.reasons?.end && true}
                  helperText={errors.reasons?.end ?
                    errors.reasons?.end.map((val: string) => val).join(', ') : '例：打刻忘れ'}
                  inputProps={{
                    'data-name': 'reasons',
                  }}
                />
              </Td>
            </tr>
          }
          </tbody>
        </ModalTable>
      </FormControl>
      <Stack
        direction={{xs: 'row', ms: 'column'}} spacing={3}
      >
        <Button type="button" variant="contained" color="inherit" sx={{flex: 1}}
                onClick={handleClose}>キャンセル</Button>
        <Button type="submit" variant="contained" form="change-request-form" sx={{flex: 1}}>変更</Button>
      </Stack>
    </ModalLayout>
  )
}

export default TimeCardChange