import React from 'react'
import {Divider, Paper, Typography} from '@mui/material'

interface NotificationBoxProps {
	title: string;
	count: number;
	children: React.ReactNode;
}

const dividerStyle = {
  borderTop:"1px solid #cccccc",
  marginTop:"5px",
}

export const NotificationBox:React.FC<NotificationBoxProps> = ({title, count, children}) => {
	return(
		<Paper variant="outlined" sx={{p:2, height: 225, overflow: "scroll"}}>
			<Typography variant="h5">{title}<small>({count}件)</small></Typography>
			<Divider sx={dividerStyle} />
			{children}
		</Paper>
	);
};