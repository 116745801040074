import React from 'react'
import {Box, Paper, Tab, Tabs} from '@mui/material'
import {a11yProps} from '../../utils/style'
import {useUserSettingsLayout} from './hooks/useUserSettingsLayout'
import {UserEdit} from './components/UserEdit'
import {ModalBase} from '../../components'
import {UsersTable} from './components/UsersTable'
import {UserSearchBox} from './components/UserSearchBox'
import {BaseLayout} from '../index'
import {UserPTOCalendar} from './components/UserPTOCalendar'
import {UserPTOCalendarEdit} from './components/UserPTOCalendarEdit'
import {NewPTO} from './components/NewPTO'


const UserSettingsLayout: React.FC = () => {
  const {
    modalOpen,
    states,
    search,
    handleModalOpen,
    handleModalClose,
    handleSearchChange,
    handleSearchSubmit,
    handleTabChange,
    tabValue,
		handleUserSave,
		handleUserChange,
		location,
		users,
		page,
		handlePTOCalendarModalOpen,
		ptoCalendarModalOpen,
		handlePTOCalendarModalClose,
		handlePTOCalendarDownload,
		handlePTOCalendarEditModalClose,
		handlePTOCalendarEditModalOpen,
		ptoCalendarEditModal,
		handlePTOCalendarChange,
		handlePTOCalendarEditSave,
		handleNewPTOCalendarChange,
		handleNewPTOCalendarModalClose,
		handleNewPTOCalendarModalOpen,
		handleNewPTOCalendarSave,
		newPtoCalendarModalOpen
  } = useUserSettingsLayout()
  return (
    <BaseLayout>
			<Paper elevation={2}>
				<Box sx={{ width: '100%' }}>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs value={tabValue < 0 ? 0 : tabValue} onChange={handleTabChange} aria-label="group tabs">
							{
								states.groups.map((obj, index) => (
									<Tab key={obj.id} label={obj.name} {...a11yProps(index)} />
								))
							}
							<Tab label="その他" {...a11yProps(states.groups.length)} />
		        </Tabs>
					</Box>
					<Box sx={{ px: {xs:0.5, sm: 2}, py:2 }}>
						<UserSearchBox
							search={search}
							handleSearchChange={handleSearchChange}
							handleSearchSubmit={handleSearchSubmit}
							departments={states.departments}
							corporateTitles={states.corporateTitles}
							users={users.results}
						/>
						<UsersTable
							users={users}
							page={page}
							handleModalOpen={handleModalOpen}
							location={location}
							/>
					</Box>
				</Box>
				<ModalBase open={modalOpen.open} handleClose={handleModalClose}>
        	<UserEdit
						handleSave={handleUserSave}
						user={modalOpen.user}
						errors={modalOpen.errors}
						states={states}
						handleChange={handleUserChange}
						handleCancel={handleModalClose}
						handlePTOCalendarModalOpen={handlePTOCalendarModalOpen}
					/>
      	</ModalBase>
				<ModalBase open={ptoCalendarModalOpen.open} handleClose={handlePTOCalendarModalClose}>
					<UserPTOCalendar
						ptoCalendars={ptoCalendarModalOpen.ptoCalendars}
						handlePTOModalClose={handlePTOCalendarModalClose}
						handlePTOCalendarDownload={handlePTOCalendarDownload}
						handlePTOCalendarEdit={handlePTOCalendarEditModalOpen}
						handleNewPTOCalendarModalOpen={handleNewPTOCalendarModalOpen}
					/>
				</ModalBase>
				<ModalBase open={ptoCalendarEditModal.open} handleClose={handlePTOCalendarEditModalClose} fullWidth={true}>
					<UserPTOCalendarEdit handlePTOCalendarEditSave={handlePTOCalendarEditSave} handlePTOCalendarChange={handlePTOCalendarChange} handlePTOCalendarEditModalClose={handlePTOCalendarEditModalClose} user={modalOpen.user} ptoData={ptoCalendarEditModal.data} />
				</ModalBase>
				<ModalBase open={newPtoCalendarModalOpen.open} handleClose={handleNewPTOCalendarModalClose}>
					<NewPTO
						handlePTOCalendarModalClose={handleNewPTOCalendarModalClose}
						handlePTOCalendarModalChange={handleNewPTOCalendarChange}
						handlePTOCalendarModalSave={handleNewPTOCalendarSave}
						newPTOCalendarModal={newPtoCalendarModalOpen}
						/>
				</ModalBase>
			</Paper>
		</BaseLayout>
  )
}

export default UserSettingsLayout

