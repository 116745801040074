import React from 'react'
import {styled} from '@mui/material/styles'
import {
  Box, Button,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, TextField,
} from '@mui/material'
import {useAuth} from '../../hooks/useAuth'
import {MonthlyInfo} from './MonthlyInfo'
import {DaysTable} from './DaysTable'
import {useMonthlyWorkTime} from './useMonthlyWorkTime'
import {ModalBase, ModalLayout, TimeCardChange, CalendarPagination} from '../index'
import {TimecardMemoT} from '../../store/timecard'

const FormTableRow = styled(TableRow)(() => ({
  '& th': {
    fontSize: 14,
    padding: 5,
    borderTop: '1px solid',
    borderColor: '#828282',
  },
}))

const MonthlyWorkTime: React.FC = () => {
  const {auth} = useAuth()
  const {
    data,
    csvDownload,
    handleCompleteClick,
    handleOpenClick,
    handleSavePto,
    handleDeletePto,
    handleMemo,
    openChange,
    setOpenChange,
    vacationTypes,
    user_id,
    open,
    setOpen,
  } = useMonthlyWorkTime()

  return (
    <Box width="100%">
      <CalendarPagination payrollCalendar={data.payroll_calendar}/>
      <MonthlyInfo data={data}/>
      <Box m={2}>
        <DaysTable
          csvDownload={csvDownload}
          handleCompleteClick={handleCompleteClick}
          state={{is_admin: auth.user.is_admin, user_id: user_id || null, sub_admin: auth.user.sub_admin}}
          data={data}
          onClick={(data, memo) => handleOpenClick(data, memo)}
        />
      </Box>
      <ModalBase open={open.open} handleClose={() => setOpen({open: false, data: null, memo: null})}>
        <ModalLayout title={`${open.data?.date || open.memo?.date}の勤怠`}>
          <TableContainer sx={{width: '100%'}}>
            <Table>
              {
                open.data && (
                  <React.Fragment>
                    <caption style={{captionSide: 'top', padding: 0, color: '#000', fontWeight: 'bold'}}>勤怠時間
                    </caption>
                    <TableHead sx={{bgcolor: 'secondary.main'}}>
                      <FormTableRow>
                        <TableCell>打刻内容</TableCell>
                        <TableCell>打刻時間(出勤)</TableCell>
                        <TableCell>打刻内容(退勤)</TableCell>
                        <TableCell>実績時間</TableCell>
                      </FormTableRow>
                    </TableHead>
                    <TableBody>
                      <FormTableRow>
                        <TableCell>出勤</TableCell>
                        <TableCell>
                          {open.data?.start || '-'}
                        </TableCell>
                        <TableCell>
                          {open.data?.end || '-'}
                        </TableCell>
                        <TableCell>{(open.data && 'total' in open.data && open.data.total) || '-'}時間</TableCell>
                      </FormTableRow>
                      <TableRow>
                        <TableCell colSpan={4} sx={{textAlign: 'right'}}>
                          <Button onClick={() => {
                            if (open.data && 'id' in open.data && open.data.id) {
                              setOpenChange({open: true, date: open.data?.date, id: open.data.id})
                            } else {
                              setOpenChange({open: true, date: open.data?.date})
                            }
                          }} type="button" variant="contained" sx={{flex: 1, color: '#fff'}}>打刻時間の変更</Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </React.Fragment>
                )
              }
              {
                auth.user.is_admin && open.data?.pto?.filter((pto) => pto.id).map((obj, j) => (
                  <React.Fragment key={j}>
                    <TableHead sx={{bgcolor: 'secondary.main'}}>
                      <FormTableRow>
                        <TableCell>休暇種類</TableCell>
                        <TableCell>日付</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                      </FormTableRow>
                    </TableHead>
                    <TableBody>
                      <FormTableRow>
                        <TableCell>
                          <select
                            value={obj?.vacation_type}
                            onChange={(e) => {
                              const cpData = {...open}
                              if (cpData.data) {
                                cpData.data.pto[j].vacation_type = parseInt(e.target.value)
                                setOpen(cpData)
                              }
                            }}
                          >
                            <option disabled>休暇種類を選択</option>
                            {
                              vacationTypes.map((val) => (
                                <option key={val.id} value={val.id}>{val.name}</option>
                              ))
                            }
                          </select>
                        </TableCell>
                        <TableCell>
                          <input
                            type="date"
                            value={obj?.new_date || open.data?.date}
                            onChange={(e) => {
                              const cpData = {...open}
                              if (cpData.data) {
                                cpData.data.pto[j].new_date = e.target.value
                                setOpen(cpData)
                              }
                            }}
                          />
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => {
                            handleSavePto(obj.id, j)
                          }} fullWidth={true} type="button" variant="contained">
                            保存
                          </Button>
                        </TableCell>
                        <TableCell>
                          <Button onClick={() => {
                            handleDeletePto(obj.id, j)
                          }} fullWidth={true} color="error" type="button" variant="contained">
                            削除
                          </Button>
                        </TableCell>
                      </FormTableRow>
                    </TableBody>
                  </React.Fragment>
                ))
              }
            </Table>
          </TableContainer>
          {
            open.memo &&
            <TextField
              fullWidth
              size="small"
              multiline
              rows="5"
              name="memo"
              value={(open.memo.memo) || ''}
              onChange={(event) => {
                setOpen((prevState) => {
                  if (!prevState.memo) {
                    return prevState
                  }
                  let cpData = {...prevState.memo}
                  if ('memo' in cpData) {
                    cpData.memo = event.target.value
                  } else {
                    cpData = {...(cpData as TimecardMemoT), memo: event.target.value}
                  }
                  return {...prevState, memo: cpData}
                })
              }}
            />
          }
          <Stack
            direction={{xs: 'row', ms: 'column'}} spacing={3}
          >
            <Button type="button" variant="contained" color="inherit" sx={{flex: 1}}
                    onClick={() => setOpen({open: false, data: null, memo: null})}>キャンセル</Button>
            <Button type="submit" variant="contained" form="profile-form" sx={{flex: 1, color: '#fff'}}
                    onClick={handleMemo}>メモを保存</Button>
          </Stack>
        </ModalLayout>
      </ModalBase>
      <ModalBase open={openChange.open} handleClose={() => {
        setOpenChange({open: false})
      }}>
        <TimeCardChange user_id={user_id} handleClose={() => {
          setOpenChange({open: false})
          setOpen({open: false, data: null, memo: null})
        }} id={openChange.id} date={openChange.date}></TimeCardChange>
      </ModalBase>
    </Box>
  )
}

export default MonthlyWorkTime