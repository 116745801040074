import {authAxios} from '../api/axios'
import {useEffect, useRef} from 'react'
import {useAuth} from './useAuth'
import {useLoading} from './useLoading'
import {AxiosRequestConfig, AxiosResponse} from 'axios'

let isRefreshing = false;
let refreshSubscribers: ((token: string) => void)[] = [];

const subscribeTokenRefresh = (cb: (token: string) => void): void => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (token: string): void => {
  refreshSubscribers.map((cb) => cb(token));
};

const useAuthAxios = () => {
  const { auth, refresh } = useAuth()
  const {startLoading, stopLoading} = useLoading()

  const requestInterceptRef = useRef<number>();
  const responseInterceptRef = useRef<number>();

  useEffect(() => {
    // リクエスト前に実行。headerに認証情報を付与する
    requestInterceptRef.current = authAxios.interceptors.request.use(
      (config) => {
        startLoading()
        if (!config.headers?.authorization) {
          config.headers.authorization = `Bearer ${auth?.access}`
        }
        return config;
      },
      (error) => {
        stopLoading()
        return Promise.reject(error)
      }
    );

    // レスポンスを受け取った直後に実行。もし認証エラーだった場合、再度リクエストする。
    responseInterceptRef.current = authAxios.interceptors.response.use(
      (response) => {
        stopLoading()
        return response
      },
      async (error) => {
        const prevRequest = error.config as AxiosRequestConfig & { sent?: boolean };
        stopLoading()
        // 401認証エラー(headerにaccess_tokenがない。もしくはaccess_tokenが無効)
        if (error?.response?.status === 401 && !prevRequest.sent) {
          if (!isRefreshing) {
            isRefreshing = true;
            try {
              const newAccessToken = await refresh();
              isRefreshing = false;
              onRefreshed(newAccessToken);
              refreshSubscribers = [];
              if (prevRequest.headers) {
                prevRequest.headers.authorization = `Bearer ${newAccessToken}`;
              }
              prevRequest.sent = true;
              return authAxios(prevRequest);
            } catch (err) {
              isRefreshing = false;
              refreshSubscribers = [];
              return Promise.reject(err);
            }
          }

          return new Promise<AxiosResponse>((resolve) => {
            subscribeTokenRefresh((token: string) => {
              if (prevRequest.headers) {
                prevRequest.headers.authorization = `Bearer ${token}`;
              }
              prevRequest.sent = true;
              resolve(authAxios(prevRequest));
            });
          });
        }
        return Promise.reject(error);
      }
    );

    return () => {
      // 離脱するときにejectする
      if (requestInterceptRef.current) {
        authAxios.interceptors.request.eject(requestInterceptRef.current);
      }
      if (responseInterceptRef.current) {
        authAxios.interceptors.response.eject(responseInterceptRef.current);
      }
    };
  }, [auth?.access, refresh, startLoading, stopLoading]);

  return authAxios;
};

export default useAuthAxios;